import "../../CSS/navbar.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ProfilePhoto } from "../../Media/PlaceHolders";
import { useAuth } from "../../Auth/AuthProvider";
import { useWindowWidth } from "../Utility/useWindowWidth";
import { HamBurger } from "../../Media/Icons";

export const Navigation = () => {
  const [isAuth, setIsAuth] = useState(false);
  const { user, pfp, openSideBar } = useAuth();
  const width = useWindowWidth();
  const isMobile = width <= 500;

  useEffect(() => {
    try {
      if (user !== null) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    } catch (e) {
      window.alert("Error occurred loading: " + e);
    }
  }, [user]);

  return (
    <div>
      {isAuth && user ? (
        <div className="navbarCont">
          {isMobile && (
            <div onClick={openSideBar} style={{ color: "#5656B9" }}>
              <HamBurger />
            </div>
          )}
          {user.groups.some((group) =>
            ["Safety", "Guard"].includes(group.name)
          ) ? (
            <h2 className="global-h2 light-primary-lilac">
              {user.location.loc_name}
            </h2>
          ) : (
            <div></div>
          )}
          <div className="profile-content">
            <Link
              onClick={() => {
              }}
              to="/settings"
            >
              {pfp && pfp !== "null" ? (
                <img src={pfp} alt="profile-pic" className="profile_pic" />
              ) : (
                <ProfilePhoto />
              )}
              <div className="profile-id">
                <h4 className="global-h4 light-black">
                  {user.first_name} {user.last_name}
                </h4>
                <h5 className="global-h5 gray">{user.email}</h5>
              </div>
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};
