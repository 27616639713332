import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../Interceptor/api_instance";
import { BarGraph } from "../../Media/BarGraph";

const TodayCapacityGraphic = () => {
  const { t } = useTranslation();
  const [trailers, setTrailers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await instance.get("assets/view-trailers");
            if (response.status === 200) {
                setTrailers(response.data);
            }
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };
    fetchData();
  }, []);

  const currentDate = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${
    months[currentDate.getMonth()]
  } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
  return (
    <div className="graphics-card">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h4 className="global-h4 gray">{t("graphics-cont.today-capacity")}</h4>

        <h4 style={{ textAlign: "right" }} className="global-h5 gray">
          {formattedDate}
        </h4>
      </div>
      {isLoading ? (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          {error ? (
            <p className="error">{error}</p>
          ) : (
            <BarGraph trailers={trailers} />
          )}
        </>
      )}
    </div>
  );
};

export default TodayCapacityGraphic;
