import { useEffect, useRef, useState } from "react";
import { Carrot } from "../../../Media/Icons";
import { useAuth } from "../../../Auth/AuthProvider";
import SearchBar from "../../Inputs/SearchBar";
import { useTranslation } from "react-i18next";

export const Filter = ({ filterMap, setFilters, updateFilter }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Handle clicks outside of the component
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setToggleDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={dropdownRef}>
      <div onClick={() => setToggleDropdown(!toggleDropdown)} className="fancy">
        <span>{filterMap.name}</span>
        <span>
          {filterMap.selectedItems.length
            ? `(${filterMap.selectedItems.length})`
            : ""}
        </span>
        <Carrot isOpen={toggleDropdown} />
      </div>
      {toggleDropdown && (
        <div className="dropdown-list">
          {filterMap.items.map((item, index) => (
            <div className="dropdown-checkbox-cont" key={index}>
              <div
                style={{ display: "flex", gap: "12px" }}
                onClick={() => updateFilter(filterMap, item, setFilters)}
              >
                <input
                  type="checkbox"
                  value={item}
                  checked={filterMap.selectedItems.includes(item)}
                />
                <span>{item}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const VehicleFilters = (type, data, user, t) => {
  // Render filters based on type of table with unique items and
  const filters = 
    user.groups.some((group) => group.name === "Mechanic")
      ? []
      : type === "trailers" // Remove filters for mechanics
      ? [
          user.groups.some((group) =>
            ["Admin", "Dispatch", "Safety"].includes(group.name)
          )
            ? {
                id: 1,
                name: t("yard-tables.search-filters.dropdowns.location"),
                items: [
                  ...new Set(data.map((item) => item.location.loc_name)),
                ].filter(Boolean), // Filter out null values
                selectedItems: [],
              }
            : null,
          {
            id: 2,
            name: t("yard-tables.search-filters.dropdowns.customer"),
            items: [
              ...new Set(data.map((item) => item.trailer.customer)),
            ].filter(Boolean), // Filter out null values
            selectedItems: [],
          },
          {
            id: 3,
            name: t("yard-tables.search-filters.dropdowns.contents"),
            items: [t("forms.type.vehicle.placeholders.loaded"), t("forms.type.vehicle.placeholders.empty")],
            selectedItems: [],
          },
          {
            id: 4,
            name: t("yard-tables.search-filters.dropdowns.reservation"),
            items: [
              ...new Set(
                data.map((item) =>
                  item.trailer.reservation
                    ? item.trailer.reservation.userName
                    : null
                )
              ),
            ].filter(Boolean), // Filter out null values
            selectedItems: [],
          },
        ].filter(Boolean)
      : type === "trucks"
      ? [
          user.groups.some((group) =>
            ["Admin", "Dispatch", "Safety"].includes(group.name)
          )
            ? {
                id: 1,
                name: t("yard-tables.search-filters.dropdowns.location"),
                items: [
                  ...new Set(data.map((item) => item.location.loc_name)),
                ].filter(Boolean), // Filter out null values
                selectedItems: [],
              }
            : null,
          {
            id: 2,
            name: t("yard-tables.search-filters.dropdowns.carrier"),
            items: [
              ...new Set(data.map((item) => item.truck.carrier_name)),
            ].filter(Boolean), // Filter out null values
            selectedItems: [],
          },
        ].filter(Boolean)
      : []
  return filters;
};

export const updateFilter = (filterMap, newItem, setFilters) => {
  setFilters(
    (
      prevData // Handle checkbox for specific filter
    ) =>
      prevData.map((item) =>
        item.id === filterMap.id
          ? {
              ...item,
              selectedItems: item.selectedItems.includes(newItem)
                ? item.selectedItems.filter((item) => item !== newItem)
                : [...item.selectedItems, newItem],
            }
          : item
      )
  );
};

export const filterVehicleData = (filters, data, type ) => {
  let newData = data;
  if (filters === null) {
    return;
  }
  if (type === "trailers") {
    newData = data.filter((item) => {
      if (filters.length === 4) {
        // Length === 4 if location filter is included
        return (
          (!filters[0].selectedItems.length ||
            filters[0].selectedItems.includes(item.location.loc_name)) &&
          (!filters[1].selectedItems.length ||
            filters[1].selectedItems.includes(item.trailer.customer)) &&
          (!filters[2].selectedItems.length ||
            filters[2].selectedItems.includes(item.trailer.loaded)) &&
          (!filters[3].selectedItems.length ||
            filters[3].selectedItems.includes(
              item.trailer.reservation
                ? item.trailer.reservation.userName
                : null
            ))
        );
      } else {
        return (
          (!filters[0].selectedItems.length ||
            filters[0].selectedItems.includes(item.trailer.customer)) &&
          (!filters[1].selectedItems.length ||
            filters[1].selectedItems.includes(item.trailer.is_loaded)) &&
          (!filters[2].selectedItems.length ||
            filters[2].selectedItems.includes(
              item.trailer.reservation
                ? item.trailer.reservation.userName
                : null
            ))
        );
      }
    });
  } else if (type === "trucks") {
    newData = data.filter((item) => {
      if (filters.length === 2) {
        // Length === 2 if location filter is included
        return (
          (!filters[0].selectedItems.length ||
            filters[0].selectedItems.includes(item.location.loc_name)) &&
          (!filters[1].selectedItems.length ||
            filters[1].selectedItems.includes(item.truck.carrier_name))
        );
      } else {
        return (
          !filters[0].selectedItems.length ||
          filters[0].selectedItems.includes(item.truck.carrier_name)
        );
      }
    });
  }
  return newData;
};

export const DisplayFilters = ({filters, setFilters, dispatch, advancedSearch}) => {
  const {user} = useAuth();
  const {t} = useTranslation();
  return (
    <>
      {user.groups.some((group) => group.name !== "Mechanic") && ( // Do not render filters container for mechanics
        <div className="search-filters-cont">
          <div id="filter-form">
            <div className="search-filters">
              {filters && filters.map((filter, index) => (
                <Filter
                  filterMap={filter}
                  setFilters={setFilters}
                  updateFilter={updateFilter}
                />
              ))}
              <SearchBar />
              {advancedSearch && (
                <div>
                  <button
                    className="all-btn other-btn"
                    type="button"
                    onClick={() => dispatch({ type: "OPEN_ADVANCED_SEARCH" })}
                  >
                    {t("forms.type.advanced-search.header")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};