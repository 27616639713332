import { useEffect, useReducer, useState } from "react";
import instance from "../../../Interceptor/api_instance";
import { CheckInOutForm } from "./CheckInOutRefactor";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../Auth/AuthProvider";
import { CheckInTruckF15 } from "./CheckInTruckF15";
import { CheckInTrailerF15 } from "./CheckInTrailerF15";
import { CheckInPhotos } from "./CheckInPhotos";
import CheckoutConfirm from "../../Modals/CheckoutConfirm";
import { ResponseModal } from "../../Modals/EditCondition";

const reducer = (state, action) => {
  switch (action.type) {
    case "IS_CARRYING":
      return {
        ...state,
        isCarrying: action.payload,
      };
    case "IS_TRAILER_ATI":
      return {
        ...state,
        isTrailerAti: action.payload,
      };
    case "SELECT_CARRIER":
      return {
        ...state,
        selectedCarrier: action.payload,
      };
    case "IS_TRAILER_SCANNING":
      return {
        ...state,
        trailerCodeScanner: action.payload,
      };
    case "IS_TRUCK_SCANNING":
      return {
        ...state,
        truckCodeScanner: action.payload,
      };
    case "ERROR_MESSAGE":
      return {
        ...state,
        [action.key]: action.payload,
      };
    case "STORE_TRUCKS":
      return {
        ...state,
        jfgTruckList: action.jfgList,
        atiTruckList: action.atiList,
      };
    case "STORE_DRIVERS":
      return {
        ...state,
        driverList: action.payload,
      };
    case "SET_TRAILER_DIMENSION":
      return {
        ...state,
        trailerDimension: action.payload,
      };
    case "STORE_TRAILERS":
      return {
        ...state,
        trailerList: action.payload,
      };
    case "STORE_CUSTOMERS":
      return {
        ...state,
        customerList: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        error: action.error,
      };
    case "PAGE_NUM":
      return {
        ...state,
        pageNum: action.payload,
      };
    case "CONFIRM":
      return {
        ...state,
        confirm: action.payload,
      };
    case "SUBMIT_REQUEST":
      return {
        ...state,
        submitLoading: true,
      };
    case "SUBMIT_COMPLETE":
      return {
        ...state,
        submitLoading: false,
        message: action.message,
        status: action.status,
      };
    default:
      break;
  }
};

const initialState = {
  isCarrying: null,
  isTrailerAti: null,
  isCarrierOther: false,
  isLoaded: null,
  isSealed: null,
  jfgTruckList: [],
  atiTruckList: [],
  trailerList: [],
  pageNum: 1,
  selectedCarrier: "",
  trailerCodeScanner: false,
  trailerErrorMessage: null,
  truckCodeScanner: false,
  truckErrorMessage: null,
  error: null,
  trailerDimension: null,
  customerList: [],
  submitLoading: false,
  confirm: false,
  status: null,
  message: null,
  driverList: [],
};

export const CheckInVehicle = ({ onClose }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [formState, dispatchForm] = useReducer(reducer, initialState);
  const [trailerBody, setTrailerBody] = useState({});
  const [truckBody, setTruckBody] = useState({});
  const [capturedPhotos, setCapturedPhotos] = useState([]);
  const [pinNum, setPinNum] = useState("");
  const [f15TruckBody, setF15TruckBody] = useState(() => {
    const defaultStructure = {
      notes: "",
    };

    const keys = [
      "chassis",
      "bumper",
      "engine",
      "tires",
      "cabFloor",
      "ceiling",
      "fuelTank",
      "compartments",
      "airTank",
      "driveShafts",
      "fifthWheel",
      "exhaust",
      "pests",
      "garbage",
      "cleanliness",
    ];

    const form = {
      truck_inspection: {},
      anomalies: null,
    };

    // Create the truckForm object with the same structure for all keys
    keys.forEach((key) => {
      form.truck_inspection[key] = { ...defaultStructure };
    });
    return form;
  });
  const [f15TrailerBody, setF15TrailerBody] = useState(() => {
    const defaultStructure = {
      notes: "",
    };

    const keys = [
      "chassis",
      "inOutDoors",
      "trailerFloor",
      "tires",
      "sideWalls",
      "frontWall",
      "ceiling",
      "pests",
      "garbage",
      "cleanliness",
      "filter",
      "humidity",
    ];

    const form = {
      trailer_inspection: {},
      seal_tests: {
        view_seal: null,
        verify_seal: null,
        tug_on_seal: null,
        twistTurn_seal: null,
      },
      anomalies: null,
    };

    // Assign default structure to keys with value/notes structure
    keys.forEach((key) => {
      form.trailer_inspection[key] = { ...defaultStructure };
    });

    form.trailer_inspection["sealCondition"] = { value: "", notes: "" };
    form.trailer_inspection["refrigerator"] = { value: "", notes: "" };

    return form;
  });

  // Get all trucks on initial render
  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        const [response, driversResponse] = await Promise.all([
          instance.get("assets/get-trucks-out"),
          instance.get("safety/get-all-drivers/"),
        ]);

        if (response.status === 200) {
          const data = response.data;
          dispatchForm({
            type: "STORE_TRUCKS",
            jfgList: data.jfg,
            atiList: data.ati,
          });
        }
        if (driversResponse.status === 200) {
          const data = driversResponse.data;
          dispatchForm({
            type: "STORE_DRIVERS",
            payload: data,
          });
        }
      } catch (error) {
        dispatchForm({ type: "ERROR", error: error });
      }
    };
    fetchTrucks();
  }, []);

  const nextForm = () => {
    if (user.location.loc_country === "Mexico") {
      // This handles the F15 navigation
      if (formState.isCarrying && formState.pageNum === 2) {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum + 1 });
      } else if (!formState.isCarrying && formState.pageNum === 2) {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum + 2 });
      } else {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum + 1 });
      }
    } else {
      // Skips over the F15 forms
      dispatchForm({ type: "PAGE_NUM", payload: 4 });
    }
  };

  const backForm = () => {
    if (user.location.loc_country === "Mexico") {
      // This handles the F15 navigation
      if (!formState.isCarrying && formState.pageNum === 4) {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum - 2 });
      } else {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum - 1 });
      }
    } else {
      dispatchForm({ type: "PAGE_NUM", payload: 1 }); // Skips over the F15 forms
    }
  };

  // Get all trailers on initial render
  useEffect(() => {
    const fetchTrailers = async () => {
      try {
        const response = await instance.get("assets/out-trailers");
        if (response.status === 200) {
          dispatchForm({
            type: "STORE_TRAILERS",
            payload: response.data,
          });
        }
      } catch (error) {
        console.error(error);
        dispatchForm({ type: "ERROR", error: error });
      }
    };

    if (formState.trailerList.length === 0 && formState.isTrailerAti) {
      fetchTrailers();
    }
  }, [formState.isTrailerAti]);

  const submit = async (e) => {
    e.preventDefault();
    dispatchForm({ type: "SUBMIT_REQUEST" });
    const formData = new FormData();
    capturedPhotos.forEach((photo, index) => {
      formData.append("photo", photo);
    });
    try {
      if (formState.isCarrying) {
        let dimensions = {};
        if (formState.trailerDimension === 1) {
          dimensions = {
            length: parseFloat(53),
            width: parseFloat(8.5),
            height: parseFloat(13.5),
            standard: 53,
          };
        } else if (formState.trailerDimension === 2) {
          dimensions = {
            length: parseFloat(48),
            width: parseFloat(8.5),
            height: parseFloat(13.5),
            standard: 48,
          };
        } else if (formState.trailerDimension === 3) {
          dimensions = {
            length: parseFloat(40),
            width: parseFloat(8.5),
            height: parseFloat(13.5),
            standard: 40,
          };
        } else {
          dimensions = {
            length: trailerBody.length,
            width: trailerBody.width,
            height: trailerBody.height,
          };
        }

        const data = {
          trailerBody: {
            ...trailerBody,
            is_loaded: trailerBody.is_loaded === "true",
            trailer_type: formState.isTrailerAti,
            trailer_dimensions: dimensions,
          },
          truckBody: {
            ...truckBody,
            reason: "Checking in a trailer",
          },
          f15Trailer: { ...f15TrailerBody },
          f15Truck: { ...f15TruckBody },
          employee_pin: pinNum,
        };
        formData.append("data", JSON.stringify(data));
        const checkInTrailer = await instance.post(
          "assets/check-in-trailer/",
          formData,
          { headers: { "Content-Type": "multipart/data-form" } }
        );
        if (checkInTrailer.status === 200) {
          dispatchForm({
            type: "SUBMIT_COMPLETE",
            message: t("forms.responses.success"),
            status: t("forms.type.success"),
          });
        }
      } else {
        const data = {
          truckBody: {
            ...truckBody,
            reason: "Checking in a truck",
          },
          f15Truck: { ...f15TruckBody },
          employee_pin: pinNum,
        };

        formData.append("data", JSON.stringify(data));
        const checkInTruck = await instance.post(
          "assets/check-in-truck/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/data-form",
            },
          }
        );
        if (checkInTruck.status === 200) {
          dispatchForm({
            type: "SUBMIT_COMPLETE",
            message: t("forms.responses.success"),
            status: t("forms.type.success"),
          });
        }
      }
    } catch (error) {
      console.error(error);
      dispatchForm({
        type: "SUBMIT_COMPLETE",
        message: error,
        status: "Error",
      });
    }
  };

  return (
    <>
      {formState.pageNum === 1 && (
        <CheckInOutForm
          formState={formState}
          dispatchForm={dispatchForm}
          truckBody={truckBody}
          setTruckBody={setTruckBody}
          trailerBody={trailerBody}
          setTrailerBody={setTrailerBody}
          close={onClose}
          nextForm={nextForm}
        />
      )}
      {formState.pageNum === 2 && (
        <CheckInTruckF15
          nextForm={nextForm}
          backForm={backForm}
          truckForm={f15TruckBody}
          setTruckForm={setF15TruckBody}
          inOrOut={true}
        />
      )}
      {formState.pageNum === 3 && (
        <CheckInTrailerF15
          nextForm={nextForm}
          backForm={backForm}
          trailerForm={f15TrailerBody}
          setTrailerForm={setF15TrailerBody}
          sealStatus={trailerBody.seal_number}
          inOrOut={true}
        />
      )}
      {formState.pageNum === 4 && (
        <CheckInPhotos
          backForm={backForm}
          capturedPhotos={capturedPhotos}
          setCapturedPhotos={setCapturedPhotos}
          confirm={() => dispatchForm({ type: "CONFIRM", payload: true })}
          pinNum={pinNum}
          setPinNum={setPinNum}
          requestLoading={formState.requestLoading}
          inOrOut={true}
        />
      )}
      {formState.confirm && (
        <CheckoutConfirm
          type="check in"
          requestLoading={formState.submitLoading}
          truck={
            formState.selectedCarrier === "Other"
              ? `${truckBody.carrier}-${truckBody.truck_number}`
              : `${truckBody.carrier}-${truckBody.label}`
          }
          trailer={
            formState.isTrailerAti
              ? formState.trailerList.find(
                  (trailer) => trailer.id === trailerBody.trailer_number
                )?.unit
              : trailerBody.trailer_number
          }
          close={() => dispatchForm({ type: "CONFIRM", payload: false })}
          submit={submit}
        />
      )}
      {formState.message && (
        <ResponseModal
          message={formState.message}
          status={formState.status}
          close={
            formState.status === t("forms.type.success")
              ? () => window.location.reload()
              : () =>
                  dispatchForm({
                    type: "SUBMIT_COMPLETE",
                    message: null,
                    status: null,
                  })
          }
        />
      )}
    </>
  );
};
