export function calculateHoursDifference(timestampString) {
    const timestamp = new Date(timestampString);
    const currentTime = new Date();
  
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentTime - timestamp;
  
    // Convert milliseconds to hours
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
  
    return differenceInHours;
  }