import { useEffect, useState } from "react";
import instance from "../../Interceptor/api_instance";
import EditReservation from "../Modals/EditReservation";
import { useTranslation } from "react-i18next";

const ReservationsGraphic = () => {
  const { t } = useTranslation();
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState(null);
  const [reservationConfirm, setReservationConfirm] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const handleButtonClick = (e) => {
    setSelected(e);
    setReservationConfirm(true);
  };

  const handleEditButtonClick = (e) => {
    setSelected(e);
    setEditModal(true);
  };

  const HandleUnreserve = () => {
    const handleYes = () => {
      unreserve(selected.trailer.id);
      setIsLoading(true);
      setReservationConfirm(false);
    };
    return (
      <>
        <div className="page-mask page-mask-animation">
          <div className="blank-card active-confirm">
            <h4 className="">{`${t(
              "graphics-cont.reservations.unreserve-prompt"
            )} ${selected.trailer.trailer_number}?`}</h4>

            <div className="d-flex gap-2 justify-content-center">
              <button className="profile-pic-btn" onClick={() => handleYes()}>
                {t("general.yes")}
              </button>
              <button
                className="profile-pic-btn"
                onClick={() => setReservationConfirm(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const unreserve = async (e) => {
    try {
      const key = {
        trailer_id: e,
      };
      await instance.post("assets/unreserve-trailer/", key);
      window.location.reload();
    } catch (e) {
      window.alert(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("assets/user-reservations/");
        if (response.status === 200) {
          setReservations(response.data);
        }
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="graphics-card">
        <h4 className="global-h4 gray">
          {t("graphics-cont.reservations.header")}
        </h4>
        {error && <p className="error">{error}</p>}
        <div className="recent-activity">
          {isLoading ? (
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : reservations !== "" ? (
            reservations.map((reservation, index) => (
              <div key={index} className="activity-item">
                <div className="reservation-item-cont">
                  <div>
                    <span className="">Trailer: </span>
                    <span className="global-h5 gray">
                      {reservation.trailer.trailer_number}
                    </span>
                  </div>
                  {reservation.truck_asset && (
                    <div>
                      <span className="">{t("yard-tables.tabs.truck")}: </span>
                      <span className="global-h5 gray">
                        {reservation.truck_asset?.company}-
                        {reservation.truck_asset?.name}
                      </span>
                    </div>
                  )}
                  <div>
                    <span className="">{t("driversTable.columns.name")}: </span>
                    <span className="global-h5 gray">
                      {reservation.user.first_name} {reservation.user.last_name}
                    </span>
                  </div>
                </div>

                <button
                  className="all-btn error-btn"
                  onClick={() => handleButtonClick(reservation)}
                >
                  {t("graphics-cont.reservations.unreserve")}
                </button>
                <button
                  className="all-btn other-btn"
                  onClick={() => handleEditButtonClick(reservation)}
                >
                  {t("buttons.edit")}
                </button>
              </div>
            ))
          ) : null}
        </div>
      </div>
      {reservationConfirm && <HandleUnreserve />}
      {editModal && (
        <EditReservation
          reservation={selected}
          setEditModal={setEditModal}
          english={t}
        />
      )}
    </>
  );
};

export default ReservationsGraphic;
