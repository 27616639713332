import { useReducer, useState, useEffect } from "react";
import { useAuth } from "../../../Auth/AuthProvider";
import instance from "../../../Interceptor/api_instance";
import { CheckOutForm } from "./CheckOutForm";
import { CheckInTruckF15 } from "../CheckIn/CheckInTruckF15";
import { CheckInTrailerF15 } from "../CheckIn/CheckInTrailerF15";
import { CheckInPhotos } from "../CheckIn/CheckInPhotos";
import CheckoutConfirm from "../../Modals/CheckoutConfirm";
import { useTranslation } from "react-i18next";
import { ResponseModal } from "../../Modals/EditCondition";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TRAILER_LIST":
      return {
        ...state,
        trailerList: action.payload,
      };
    case "SET_TRUCK_LIST":
      return {
        ...state,
        truckList: action.payload,
      };
    case "SET_VISITOR_LIST":
      return {
        ...state,
        visitorList: action.payload,
      };
    case "PAGE_NUM":
      return {
        ...state,
        pageNum: action.payload,
      };
    case "IS_CARRYING":
      return {
        ...state,
        isCarrying: action.payload,
      };
    case "IS_TRAILER_SCANNING":
      return {
        ...state,
        trailerCodeScanner: action.payload,
      };
    case "IS_TRUCK_SCANNING":
      return {
        ...state,
        truckCodeScanner: action.payload,
      };
    case "ERROR_MESSAGE":
      return {
        ...state,
        [action.key]: action.payload,
      };
    case "SET_TRAILER_DIMENSION":
      return {
        ...state,
        trailerDimension: action.payload,
      };
    case "STORE_CUSTOMERS":
      return {
        ...state,
        customerList: action.payload,
      };
    case "CONFIRM":
      return {
        ...state,
        confirm: action.payload,
      };
    case "SUBMIT_REQUEST":
      return {
        ...state,
        submitLoading: true,
      };
    case "SUBMIT_COMPLETE":
      return {
        ...state,
        submitLoading: false,
        message: action.message,
        status: action.status,
      };
    case "LOCK_TRUCK_INPUT":
      return {
        ...state,
        lockTruck: action.value,
        trailerErrorMessage: action.message,
      };
    default:
      break;
  }
};

const initialState = {
  truckList: [],
  trailerList: [],
  visitorList: [],
  customerList: [],
  pageNum: 1,
  dimensionOption: null,
  isCarrying: null,
  lockTruck: false,
  confirm: false,
  truckCodeScanner: false,
  trailerCodeScanner: false,
  truckErrorMessage: null,
  trailerErrorMessage: null,
  submitLoading: false,
};

export const CheckOutVehicle = ({ onClose }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [formState, dispatchForm] = useReducer(reducer, initialState);
  const [trailerBody, setTrailerBody] = useState({});
  const [truckBody, setTruckBody] = useState({});
  const [visitor, setVisitor] = useState({});
  const [capturedPhotos, setCapturedPhotos] = useState([]);
  const [pinNum, setPinNum] = useState("");

  const [f15TrailerBody, setF15TrailerBody] = useState(() => {
    const defaultStructure = {
      notes: "",
    };

    const keys = [
      "chassis",
      "inOutDoors",
      "trailerFloor",
      "tires",
      "sideWalls",
      "frontWall",
      "ceiling",
      "pests",
      "garbage",
      "cleanliness",
      "filter",
      "humidity",
    ];

    const form = {
      trailer_inspection: {},
      seal_tests: {
        view_seal: null,
        verify_seal: null,
        tug_on_seal: null,
        twistTurn_seal: null,
      },
      anomalies: null,
    };

    // Assign default structure to keys with value/notes structure
    keys.forEach((key) => {
      form.trailer_inspection[key] = { ...defaultStructure };
    });

    form.trailer_inspection["sealCondition"] = "";
    form.trailer_inspection["refrigerator"] = "";

    return form;
  });
  const [f15TruckBody, setF15TruckBody] = useState(() => {
    const defaultStructure = {
      notes: "",
    };

    const keys = [
      "chassis",
      "bumper",
      "engine",
      "tires",
      "cabFloor",
      "ceiling",
      "fuelTank",
      "compartments",
      "airTank",
      "driveShafts",
      "fifthWheel",
      "exhaust",
      "pests",
      "garbage",
      "cleanliness",
    ];

    const form = {
      truck_inspection: {},
      anomalies: null,
    };

    // Create the truckForm object with the same structure for all keys
    keys.forEach((key) => {
      form.truck_inspection[key] = { ...defaultStructure };
    });

    return form;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve trailers only if user selects yes and list is empty
        if (formState.isCarrying && formState.trailerList.length === 0) {
          const data = await instance.get("assets/view-trailers");
          if (data.status === 200) {
            dispatchForm({ type: "SET_TRAILER_LIST", payload: data.data });
          }
        }
        if (formState.visitorList.length === 0) {
          // Retrieve visitors only if list is empty
          const data = await instance.get("assets/get-drivers/");
          if (data.status === 200) {
            dispatchForm({ type: "SET_VISITOR_LIST", payload: data.data });
          }
        }
        if (formState.truckList.length === 0) {
          // Retrieve trucks only if list is empty
          const data = await instance.get("assets/view-trucks");
          if (data.status === 200) {
            dispatchForm({ type: "SET_TRUCK_LIST", payload: data.data });
          }
        }
      } catch (error) {
        window.alert("Error:", error);
      }
    };
    fetchData();
  }, [formState.isCarrying]);

  const nextForm = () => {
    if (user.location.loc_country === "Mexico") {
      // This handles the F15 navigation
      if (formState.isCarrying && formState.pageNum === 2) {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum + 1 });
      } else if (!formState.isCarrying && formState.pageNum === 2) {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum + 2 });
      } else {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum + 1 });
      }
    } else {
      // Skips over the F15 forms
      dispatchForm({ type: "PAGE_NUM", payload: 4 });
    }
  };

  const backForm = () => {
    if (user.location.loc_country === "Mexico") {
      // This handles the F15 navigation
      if (!formState.isCarrying && formState.pageNum === 4) {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum - 2 });
      } else {
        dispatchForm({ type: "PAGE_NUM", payload: formState.pageNum - 1 });
      }
    } else {
      dispatchForm({ type: "PAGE_NUM", payload: 1 }); // Skips over the F15 forms
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      if (
        truckBody.truck_condition !== "Good" &&
        truckBody.truck_condition !== "Okay" &&
        truckBody.truck_condition !== "External repair"
      ) {
        throw new Error(t("forms.type.vehicle.warnings.truck_condition"));
      }
    dispatchForm({ type: "SUBMIT_REQUEST" });
    const formData = new FormData();
    capturedPhotos.forEach((photo, index) => {
      formData.append("photo", photo);
    });

    
      const data = {
        trailerBody: {
          ...trailerBody,
          is_loaded: trailerBody.is_loaded === "true",
        },
        truckBody: truckBody,
        f15Trailer: f15TrailerBody,
        f15Truck: f15TruckBody,
        visitorBody: visitor,
        employee_pin: pinNum,
      };
      formData.append("data", JSON.stringify(data));
      if (formState.isCarrying) {
        if (
          trailerBody.trailer_condition !== "Good" &&
          trailerBody.trailer_condition !== "Okay" &&
          trailerBody.trailer_condition !== "External repair"
        )
          throw new Error(t("forms.type.vehicle.warnings.trailer_condition"));
        const response = await instance.post(
          "assets/check-out-trailer/",
          formData,
          { headers: { "Content-Type": "multipart/data-form" } }
        );
        if (response.status === 200) {
          dispatchForm({
            type: "SUBMIT_COMPLETE",
            message: t("forms.responses.success"),
            status: t("forms.type.success"),
          });
        }
      } else {
        const response = await instance.post(
          "assets/check-out-truck/",
          formData,
          { headers: { "Content-Type": "multipart/data-form" } }
        );
        if (response.status === 200) {
          dispatchForm({
            type: "SUBMIT_COMPLETE",
            message: t("forms.responses.success"),
            status: t("forms.type.success"),
          });
        }
      }
    } catch (err) {
      const error = err instanceof Error ? err.message : String(err);
      console.error(error);
      dispatchForm({
        type: "SUBMIT_COMPLETE",
        message: error,
        status: "Error",
      });
    }
  };
  return (
    <>
      {formState.pageNum === 1 && (
        <CheckOutForm
          formState={formState}
          dispatchForm={dispatchForm}
          truckBody={truckBody}
          setTruckBody={setTruckBody}
          trailerBody={trailerBody}
          setTrailerBody={setTrailerBody}
          visitorBody={visitor}
          setVisitorBody={setVisitor}
          close={onClose}
          nextForm={nextForm}
        />
      )}
      {formState.pageNum === 2 && (
        <CheckInTruckF15
          nextForm={nextForm}
          backForm={backForm}
          truckForm={f15TruckBody}
          setTruckForm={setF15TruckBody}
          inOrOut={false}
        />
      )}
      {formState.pageNum === 3 && (
        <CheckInTrailerF15
          backForm={backForm}
          nextForm={nextForm}
          trailerForm={f15TrailerBody}
          setTrailerForm={setF15TrailerBody}
          sealStatus={trailerBody.seal_number ? true : false}
          inOrOut={false}
        />
      )}
      {formState.pageNum === 4 && (
        <CheckInPhotos
          backForm={backForm}
          capturedPhotos={capturedPhotos}
          setCapturedPhotos={setCapturedPhotos}
          confirm={() => dispatchForm({ type: "CONFIRM", payload: true })}
          pinNum={pinNum}
          setPinNum={setPinNum}
          requestLoading={formState.requestLoading}
          inOrOut={false}
        />
      )}
      {formState.confirm && (
        <CheckoutConfirm
          type="check out"
          truck={`${truckBody.carrier_name}-${truckBody.truck_number}`}
          trailer={trailerBody.trailer_number}
          close={() => dispatchForm({ type: "CONFIRM", payload: false })}
          submit={submit}
          requestLoading={formState.submitLoading}
        />
      )}
      {formState.message && (
        <ResponseModal
          message={formState.message}
          status={formState.status}
          close={
            formState.status === t("forms.type.success")
              ? () => window.location.reload()
              : () =>
                  dispatchForm({
                    type: "SUBMIT_COMPLETE",
                    message: null,
                    status: null,
                  })
          }
        />
      )}
    </>
  );
};
