export const getTrailerHeaders = (t, user) => {
  return user.groups.some((group) => group.name === "Mechanic")
    ? [
        t("yard-tables.table-columns.trailer"),
        t("yard-tables.table-columns.condition"),
        t("yard-tables.table-columns.arrival-time"),
        t("yard-tables.table-columns.duration"),
        t("forms.type.vehicle.input-headers.notes"),
      ]
    : [
        user.groups.some((group) => ["Admin", "Dispatch", "Safety"].includes(group.name))
          ? t("yard-tables.table-columns.location")
          : null,
        t("yard-tables.table-columns.trailer"),
        t("yard-tables.table-columns.guard"),
        t("yard-tables.table-columns.reservation"),
        t("yard-tables.table-columns.content"),
        t("yard-tables.table-columns.customer"),
        t("yard-tables.table-columns.condition"),
        t("yard-tables.table-columns.seal"),
        t("yard-tables.table-columns.arrival-time"),
        t("yard-tables.table-columns.duration"),
      ].filter(Boolean);
};

export const getTruckHeaders = (t, user) => {
  return user.groups.some((group) => group.name === "Mechanic")
    ? [
        t("yard-tables.table-columns.truck"),
        t("yard-tables.table-columns.condition"),
        t("yard-tables.table-columns.arrival-time"),
        t("yard-tables.table-columns.duration"),
        t("forms.type.vehicle.input-headers.notes"),
      ]
    : [
        user.groups.some((group) => ["Admin", "Dispatch", "Safety"].includes(group.name))
          ? t("yard-tables.table-columns.location")
          : null,
        t("yard-tables.table-columns.truck"),
        t("yard-tables.table-columns.driver"),
        t("yard-tables.table-columns.guard"),
        t("yard-tables.table-columns.condition"),
        t("yard-tables.table-columns.arrival-time"),
        t("yard-tables.table-columns.duration"),
      ].filter(Boolean);
};

export const backlogTrailerHeaders = (t) => {
  return [
    t("yard-tables.table-columns.location"),
    t("yard-tables.table-columns.trailer"),
    t("yard-tables.table-columns.guard"),
    t("yard-tables.table-columns.truck"),
    t("yard-tables.table-columns.driver"),
    t("yard-tables.table-columns.reservation"),
    t("yard-tables.table-columns.content"),
    t("yard-tables.table-columns.customer"),
    t("yard-tables.table-columns.condition"),
    t("yard-tables.table-columns.seal"),
    t("yard-tables.table-columns.arrival-time"),
    t("buttons.check-out"),
  ];
};

export const backlogTruckHeaders = (t) => {
  return [
    t("yard-tables.table-columns.location"),
    t("yard-tables.table-columns.guard"),
    t("yard-tables.table-columns.truck"),
    t("yard-tables.table-columns.driver"),
    t("yard-tables.table-columns.guard"),
    t("yard-tables.table-columns.arrival-time"),
    t("buttons.check-out"),
    t("yard-tables.table-columns.condition")
  ];
}

export const backlogVisitorHeaders = (t) => {
  return [
    t("yard-tables.table-columns.location"),
    t("buttons.visitor"),
    t("yard-tables.table-columns.guard"),
    t("yard-tables.table-columns.arrival-time"),
    t("yard-tables.table-columns.check-out"),
  ]
}

export const trailerAssetHeaders = (t) => {
  return [
    t("yard-tables.table-columns.unit"),
    t("yard-tables.table-columns.license_plate"),
    t("yard-tables.table-columns.location")
  ]
}

export const truckAssetHeaders = (t) => {
  return [
    t("yard-tables.table-columns.unit"),
    t("yard-tables.table-columns.license_plate"),
    t("yard-tables.table-columns.location")
  ]
}