import { useTranslation } from "react-i18next";
import { useEffect, useReducer, useState } from "react";
import { DisplayFilters, filterVehicleData } from "./Filters";
import { RowClickModal } from "../../Modals/RowClickModal";
import { EditTrailerAsset, EditTruckAsset } from "../../Modals/EditModals";
import { AssetQrCode } from "../../Modals/Documents/AssetQrCode";
import { AssetsTable } from "./AssetsTable";

const reducer = (state, action) => {
  switch (action.type) {
    case "EDIT_ROW":
      return {
        ...state,
        editRow: true,
      };
    case "CLOSE_EDIT_ROW":
      return {
        ...state,
        editRow: false,
      };
    case "OPEN_DOCS":
      return {
        ...state,
        docsModal: true,
      };
    case "CLOSE_DOCS":
      return {
        ...state,
        docsModal: false,
      };
    default:
      break;
  }
};

const initialState = {
  editRow: false,
  docsModal: false,
};

export const AssetsTableConst = ({
  state,
  dispatch,
  headers,
  type,
  filters,
}) => {
  const { t } = useTranslation();
  const [modalState, modalDispatch] = useReducer(reducer, initialState);
  const [allFilters, setFilters] = useState(filters);
  const [newData, setData] = useState(state.data);

  useEffect(() => {
    setData(filterVehicleData(allFilters, state.data, type));
  }, [allFilters]);

  const closeModal = () => dispatch({ type: "CLOSE_SELECTED_ROW" });
  return (
    <>
      {state.selectedRow && (
        <RowClickModal
          setEditRow={() => modalDispatch({ type: "EDIT_ROW" })}
          setDocuments={() => modalDispatch({ type: "OPEN_DOCS" })}
          row={state.selectedRow}
          type={type}
          close={closeModal}
          english={t}
        />
      )}
      {modalState.editRow && type === "trailer-asset" && (
        <EditTrailerAsset
          trailer={state.selectedRow}
          close={() => modalDispatch({ type: "CLOSE_EDIT_ROW" })}
        />
      )}
      {modalState.editRow && type === "truck-asset" && (
        <EditTruckAsset
          truck={state.selectedRow}
          close={() => modalDispatch({ type: "CLOSE_EDIT_ROW" })}
        />
      )}
      {modalState.docsModal && (
        <AssetQrCode
          entity={state.selectedRow}
          type={type}
          close={() => modalDispatch({ type: "CLOSE_DOCS" })}
        />
      )}
      <div style={{display: "flex"}}>
        <DisplayFilters
          filters={allFilters}
          setFilters={setFilters}
          dispatch={dispatch}
        />
      </div>
      <AssetsTable headers={headers} data={newData} dispatch={dispatch} type={type}/>
    </>
  );
};
