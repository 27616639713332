import axios from "axios";
import { getCookie } from "./csrftoken";
import i18next from "i18next";

const checkInternet = async () => {
  try {
    const response = await fetch("https://www.google.com", {mode: "no-cors"});
    return i18next.t("errors.no_server_connection");
  } catch (error) {
    return i18next.t("errors.no_internet_connection");
  }
}
const cookieValue = getCookie("csrftoken");
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "X-CSRFToken": cookieValue,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

// Request interceptor to dynamically update the CSRF token
instance.interceptors.request.use(
  (config) => {
    const cookieValue = getCookie("csrftoken");
    if (cookieValue) {
      config.headers["X-CSRFToken"] = cookieValue;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

function extractDetailSection(text) {
  const detailMatch = text.match(/DETAIL:\s+(.*)/);
  const errorMatch = text.match(/HINT:\s+(.*)/);
  if (detailMatch && detailMatch[1]) {
    return detailMatch[1].trim();
  }
  if (errorMatch && errorMatch[1]) {
    return errorMatch[1].trim();
  }
  return null;
}

// Function to get error message from an Axios error object
export const getErrorMessage = async (error) => {
  console.log(error.response ? error.response.data : error);

  if (!error.response) {
    var internetCheck = await checkInternet();
    console.log(internetCheck);
    return internetCheck;
  }

  let errorMessage = error.response.data.error || "An unknown error occurred.";

  switch (error.response.status) {
    case 400:
      return `Error: ${
        error.response.data.error
          ? error.response.data.error
          : "Please check the data you've submitted."
      } `;
    case 401:
      return "Error 401: Unauthorized access. Redirecting to login.";
    case 403:
      localStorage.clear();
      window.location.href = "/login";
      return "Error 403: Forbidden access to this resource.";
    case 404:
      return "Error 404: Requested data not found. Please try again later.";
    case 406:
      return "Error 406: User already exists or has the same username with this company.";
    case 429:
      return "Error 429: Too many requests. Please try again later.";
    case 500:
      return "Error 500: Internal server error. Please try again later.";
    case 502:
      return "Error 502: Bad gateway. Please try again later.";
    case 503:
      return "Error 503: Service unavailable. Please try again later.";
    case 504:
      return "Error 504: Gateway timeout. Please try again later.";
    default:
      return `Error: ${extractDetailSection(errorMessage)}`;
  }
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Get error message using getErrorMessage function
    const errorMessage = await getErrorMessage(error);
    // Optionally, you might want to handle UI notifications here
    // For example, you could trigger a toast notification to inform the user

    // Return the error message so that it can be accessed in the component
    return Promise.reject(errorMessage);
  }
);

export default instance;
