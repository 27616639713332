import { useState } from "react";
import Samsara from "./Modals/Samsara";
import Truck34Hour from "./Graphics/Truck34Hour";

const DispatchGraphics = ({ user, english}) => {
  const [samsaraBtn, setSamsaraBtn] = useState(false);

  return (
    <>
      <div className="overview-header">
        <h1 className="global-h1 black">{english("page-headers.overview")}</h1>
        <h4 className="global-h4 gray">
          {english("page-subheaders.welcome")}, {user.first_name}
        </h4>
        <div className="the-btn-cont">
          <button
            onClick={() => setSamsaraBtn(true)}
            className="all-btn other-btn"
          >
            Generate Tracking
          </button>
        </div>
      </div>
      <div className="graphics-cont">

        <Truck34Hour/>
      </div>

      {samsaraBtn && <Samsara close={() => setSamsaraBtn(false)} />}
    </>
  );
};

export default DispatchGraphics;