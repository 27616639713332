import { useEffect, useState } from "react";
import instance from "../../Interceptor/api_instance";
import { useTranslation } from "react-i18next";
import { Dots } from "../LoadingAnimations/LoadingAnimations";

const RecentActivityGraphic = () => {
  const { t } = useTranslation();
  const [recentActivity, setActivity] = useState([]);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [activityError, setActivityError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingActivity(true);

        // Initialize both requests without waiting for them to complete.
        const response = await instance.get("assets/recent-activity/");
        // Handle the recent activity request.
        if (response.status === 200) {
          setActivity(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        setActivityError(error);
      } finally {
        setLoadingActivity(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="graphics-card">
      <h4 className="global-h4 gray">
        {t("graphics-cont.recent-activity.header")}
      </h4>
      <div className="recent-activity-cont">
        <div className="recent-activity">
          {loadingActivity ? (
            <Dots/>
          ) : recentActivity.length > 0 ? (
            recentActivity.map((activity, index) => (
              <div key={index} className="activity-item">
                <span className="global-h5 gray">
                  {activity.trailer.trailer_number}
                </span>
                {activity.check_in_dt !== null ? (
                  <span className="success">
                    {t("graphics-cont.recent-activity.check-in")}
                  </span>
                ) : (
                  <span className="critical">
                    {t("graphics-cont.recent-activity.check-out")}
                  </span>
                )}
              </div>
            ))
          ) : null}
          {activityError && <p className="error">{activityError}</p>}
        </div>
      </div>
    </div>
  );
};

export default RecentActivityGraphic;
