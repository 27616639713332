import { useTranslation } from "react-i18next";
import { DownArrow, UpArrow, WarningIcon } from "../../../Media/Icons";
import { formatReadableDateTime } from "../../Modals/FormatFunctions";
import { getDuration } from "../../../Interceptor/csrftoken";
import { GetVehicleCondition } from "./GetVehicleCondition";
import React, { useEffect, useState } from "react";
import { sortData } from "../Sorting/functions";
import { calculateHoursDifference } from "../../Utility/CalculateHoursDifference";
import { useWindowWidth } from "../../Utility/useWindowWidth";

export const VehicleTable = ({
  headers,
  data,
  dispatch,
}) => {
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState(data);
  const [order, setOrder] = useState(true);
  const [expandedRow, setExpandedRow] = useState(null);

  // Check if user is on mobile (phone)
  const width = useWindowWidth();
  const isMobile = width <= 500;

  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  
  const getCellValue = (header, row) => {
    switch (
      header // Render cell value based on header name
    ) {
      case t("yard-tables.table-columns.location"):
        return row.location.loc_name;
      case t("yard-tables.table-columns.trailer"):
        return (
          <>
            {row.trailer.trailer_number}
            {row.trailer.requires_check_out && (
              <span>
                <WarningIcon
                  message={
                    t("warnings.check_out_required")
                  }
                />
              </span>
            )}
          </>
        );
      case t("yard-tables.table-columns.guard"):
        return row.user.userName;
      case t("yard-tables.table-columns.reservation"):
        return row.trailer.reservation
          ? row.trailer.reservation.userName
          : t("forms.type.vehicle.placeholders.none");
      case t("yard-tables.table-columns.content"):
        return row.trailer.loaded
      case t("yard-tables.table-columns.customer"):
        return row.trailer.customer
          ? row.trailer?.customer || row.customer
          : t("forms.type.vehicle.placeholders.none");
      case t("yard-tables.table-columns.seal"):
        return row.trailer.seal_number
          ? row.trailer.seal_number
          : t("forms.type.vehicle.placeholders.none");
      case t("yard-tables.table-columns.arrival-time"):
        return formatReadableDateTime(row.check_in_dt);
      case t("yard-tables.table-columns.duration"):
        const duration = calculateHoursDifference(row.check_in_dt);
        return (
          <span style={{ color: duration >= 34 ? "#fb6061" : "" }}>
            {getDuration(row.check_in_dt)}
          </span>
        );
      case t("buttons.check-out"):
        return formatReadableDateTime(row.check_out_dt);
      case t("yard-tables.table-columns.truck"):
        return (
          <>
            {row.truck.truckName}
            {row.truck.requires_check_out && (
              <span>
                <WarningIcon
                  message={
                    t("warnings.check_out_required")
                  }
                />
              </span>
            )}
          </>
        );
      case t("yard-tables.table-columns.driver"):
        return row.visitor.driverName;
      case t("buttons.visitor"):
        return row.visitor.driverName;
      case t("yard-tables.table-columns.condition"):
        if (row.entity_type === "Trailer") {
          return (
            <GetVehicleCondition condition={row.trailer.trailer_condition} />
          );
        } else if (row.entity_type === "Truck") {
          return <GetVehicleCondition condition={row.truck.truck_condition} />;
        }
        break;
      case t("forms.type.vehicle.input-headers.notes"):
        if (row.entity_type === "Trailer") {
          return row.trailer.trailer_condition_notes
            ? row.trailer.trailer_condition_notes
            : t("forms.type.vehicle.placeholders.none");
        } else if (row.entity_type === "Truck") {
          return row.truck.truck_condition_notes
            ? row.truck.truck_condition_notes
            : t("forms.type.vehicle.placeholders.none");
        }
        break;
      case t("yard-tables.table-columns.unit"):
        return row.unit;
      case t("yard-tables.table-columns.license_plate"):
        return row.license_plate;
      default:
        return null;
    }
  };

  const toggleRowExpand = (index) => {
    // Expand row on mobile
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <>
      <div className="table-cont">
        <table id="forwardTable" className="yard-table">
          <thead>
            <tr className="table-header">
              {headers?.map((header, index) => (
                <th scope="col" key={`${header}-${index}`}>
                  <button
                    className="col-header"
                    onClick={() =>
                      sortData(
                        filteredData,
                        header,
                        setFilteredData,
                        order,
                        setOrder,
                        t
                      )
                    }
                  >
                    {header}
                    <div className="order-filter">
                      <UpArrow />
                      <DownArrow />
                    </div>
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr
                key={index}
                className="data-rows row-hover"
                style={{ maxHeight: expandedRow === index ? "700px" : "180px" }}
                onClick={() =>
                  dispatch({
                    type: "SET_SELECTED_ROW",
                    selectedRow: row,
                  })
                }
              >
                {isMobile && (
                  <td>
                    <button
                      className="all-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleRowExpand(index);
                      }}
                    >
                      Toggle Expand
                    </button>
                  </td>
                )}
                {headers?.map((header, index) => (
                  <>
                    {isMobile && (
                      <td
                        key={`${header}-${index}`}
                        className="gray"
                        data-label={header}
                      ></td>
                    )}
                    <td key={index} className="gray cursor-pointer hover">
                      {getCellValue(header, row)}
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
