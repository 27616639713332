import { useState, useRef, useEffect } from "react";
import "../../CSS/driver.css";
import instance from "../../Interceptor/api_instance";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";
import { PhoneInput } from "../Inputs/PhoneInput";
import { IdTemplate } from "../Routes/ScannedId";
import { ProfilePhoto } from "../../Media/PlaceHolders";
import { convertHeicToJpeg } from "../Utility/HeicToJpg";
/**
 * The `DriverInfo` component in JavaScript fetches driver information, displays emergency contacts,
 * and allows for editing and generating an ID.
 * @returns The `DriverInfo` component is returning JSX elements that make up the UI for displaying
 * driver information. This includes a section for emergency contacts, medical notes, profile picture,
 * buttons for editing and generating ID, and error handling for fetching the profile picture. The
 * component also includes logic for formatting phone numbers and handling image loading states.
 */
export const DriverInfo = ({ close, openEdit, row, english }) => {
  const [url, setUrl] = useState("");
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [isIdOpen, setIsIdOpen] = useState(false);
  const [imgUrlError, setImgError] = useState(null);

  const handleClose = () => {
    close();
  };
  const handleEditOpen = () => {
    openEdit(row);
    close();
  };

  useEffect(() => {
    const fetchImage = async () => {
      setIsImgLoading(true);
      try {
        const response = await instance.post("safety/driver-image", {
          profile_pic: row.profile_pic,
        });
        setUrl(response.data.sas_url);
        setIsImgLoading(false);
      } catch (error) {
        setImgError(error);
        setIsImgLoading(false);
      }
    };
    fetchImage();
  }, []);

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return phoneNumber;

    switch (phoneNumber.length) {
      case 10: // US local format
        return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
          3,
          6
        )}-${phoneNumber.substring(6)}`;
      case 11: // US with country code
        return `+${phoneNumber.substring(0, 1)} (${phoneNumber.substring(
          1,
          4
        )}) ${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7)}`;
      case 12: // International format, e.g., Mexico
        return `+${phoneNumber.substring(0, 2)} (${phoneNumber.substring(
          2,
          5
        )}) ${phoneNumber.substring(5, 8)}-${phoneNumber.substring(8)}`;
      case 13: // International format, e.g., Mexico
        return `+${phoneNumber.substring(0, 2)} (${phoneNumber.substring(
          2,
          5
        )}) ${phoneNumber.substring(5, 8)}-${phoneNumber.substring(8)}`;
      default: // Default to unformatted if it doesn't match expected lengths
        return phoneNumber;
    }
  }

  return (
    <>
      {isIdOpen && (
        <>
          <div className="page-mask-2 page-mask-animation zindex-modal">
            <IdTemplate url={url} row={row} setIsIdOpen={setIsIdOpen} />
          </div>
        </>
      )}
      <div className={`driver-info-cont slide-in-animation`}>
        <div className="close-cont">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            onClick={handleClose}
          >
            <path
              d="M18.625 16.375C19.125 16.875 19.125 17.675 18.625 18.175C18.4249 18.375 18.025 18.575 17.725 18.575C17.425 18.575 17.125 18.475 16.825 18.175L9.72495 11.075L2.62495 18.175C2.42495 18.375 2.02495 18.575 1.72495 18.575C1.42495 18.575 1.12495 18.475 0.824951 18.175C0.324951 17.675 0.324951 16.875 0.824951 16.375L7.92495 9.275L0.824951 2.175C0.324951 1.675 0.324951 0.875 0.824951 0.375C1.32495 -0.125 2.12495 -0.125 2.62495 0.375L9.72495 7.475L16.825 0.375C17.325 -0.125 18.125 -0.125 18.625 0.375C19.125 0.875 19.125 1.675 18.625 2.175L11.525 9.275L18.625 16.375Z"
              fill="#393838"
            />
          </svg>
        </div>
        <div className="emergency-contact-cont">
          {isImgLoading ? (
            <div data-testid="loading" className="driver-photo">
              <Spinner />
            </div>
          ) : !url ? (
            <div className="driver-photo">
              <ProfilePhoto />
            </div>
          ) : (
            <div data-testid="profile-pic" className="driver-photo">
              <img src={url} alt="" />
            </div>
          )}
          <div className="e-contact-wrapper">
            <div className="emergency-contact">
              {imgUrlError && <p className="error">Failed to fetch image.</p>}
              <h4>
                {english("forms.type.newDriver.subheaders.emergencyContact")} 1
              </h4>
              <div className="contact-info">
                <p>{row.e_contact1_name}</p>
                <p className="gray">
                  {formatPhoneNumber(row.e_contact1_phone)}
                </p>
              </div>
            </div>
            <div className="emergency-contact">
              <h4>
                {english("forms.type.newDriver.subheaders.emergencyContact")} 2
              </h4>
              <div className="contact-info">
                <p>{row.e_contact2_name}</p>
                <p className="gray">
                  {formatPhoneNumber(row.e_contact2_phone)}
                </p>
              </div>
            </div>
            <div className="emergency-contact">
              <h4>
                {english("forms.type.newDriver.subheaders.emergencyContact")} 3
              </h4>
              <div className="contact-info">
                <p>{row.e_contact3_name}</p>
                <p className="gray">
                  {formatPhoneNumber(row.e_contact3_phone)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="e-contact-wrapper-bottom">
          <div className="emergency-contact">
            <h4>{english("forms.type.newDriver.input-headers.medical")}</h4>
            <div className="contact-info">
              {!row.medical_notes && <p>N/A</p>}
              <p>{row.medical_notes}</p>
            </div>
          </div>
          <div>
            <button onClick={handleEditOpen} className="profile-pic-btn m-2">
              {english("buttons.edit")}
            </button>
            <button onClick={() => setIsIdOpen(true)} className="other-btn">
              Generate ID
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const EditDriverInfo = ({ close, row, fetch, english }) => {
  const [url, setUrl] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      setIsImgLoading(true);
      const response = await instance.post("safety/driver-image", {
        profile_pic: row.profile_pic,
      });
      setUrl(response.data.sas_url);
      setIsImgLoading(false);
    };
    fetchImage();
  }, []);

  const [formData, setFormData] = useState({
    id: row.internal_id,
    first_name: row.first_name,
    last_name: row.last_name,
    carrier_name: row.carrier_name,
    licenseNumber: row.license_number,
    personalPhoneNumber: row.personal_phone,
    active: row.is_active,
    companyPhoneNumber: row.work_phone,
    emailAddress: row.email,
    medicalNotes: row.medical_notes,
    emergencyContact1Name: row.e_contact1_name?.split(" ")[0],
    emergencyContact1LastName: row.e_contact1_name?.split(" ")[1],
    emergencyContact1PhoneNumber: row.e_contact1_phone,
    emergencyContact2Name: row.e_contact2_name?.split(" ")[0],
    emergencyContact2LastName: row.e_contact2_name?.split(" ")[1],
    emergencyContact2PhoneNumber: row.e_contact2_phone,
    emergencyContact3Name: row.e_contact3_name?.split(" ")[0],
    emergencyContact3LastName: row.e_contact3_name?.split(" ")[1],
    emergencyContact3PhoneNumber: row.e_contact3_phone,
    photo: null,
    username: row.username,
    password: row.password,
    samsara_id: row.tracking_id,
    url: url,
  });

  const fileInputRef = useRef(null);
  const handleClose = () => close();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUploadClick = (e) => {
    e.preventDefault(); // Prevent form submission
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Ensure fileInputRef is not null before accessing click method
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
  
    if (file) {
      const convertedFile = await convertHeicToJpeg(file);
  
      // Read the uploaded file as a data URL
      const reader = new FileReader();
      reader.onload = function (e) {
        // Set the uploaded photo in the state and formData
        setFormData((prevFormData) => ({
          ...prevFormData,
          photo: [e.target.result, convertedFile],
        }));
      };
      reader.readAsDataURL(convertedFile);
      
      // Automatically upload the file
      // onUpload(convertedFile);
    }
  };
  

  // Select the correct format from the phone objects
  const handlePhoneUpdate = () => {
    const phoneKeys = [
      "personalPhoneNumber",
      "companyPhoneNumber",
      "emergencyContact1PhoneNumber",
      "emergencyContact2PhoneNumber",
      "emergencyContact3PhoneNumber",
    ];

    phoneKeys.forEach((key) => {
      if (formData[key] && formData[key].formattedValue !== undefined) {
        formData[key] = formData[key].formattedValue;
      }
    });
  };

  const handleSave = async () => {
    handlePhoneUpdate();
    setIsLoading(true);
    const newForm = new FormData();
    if (formData.photo) {
      newForm.append("photo", formData.photo[1]);
    } else {
      newForm.append("photo", null);
    }

    newForm.append("data", JSON.stringify(formData));
    try {
      const response = await instance.post("safety/update-driver", newForm, {
        headers: {
          "Content-Type": "multipart/data-form",
        },
      });

      setIsLoading(false);
      handleClose();
      fetch();
    } catch (error) {
      window.alert(error)
      console.error("An error occurred while updating driver:", error.message);
      setIsLoading(false);
    }
  };

  const handlePhoneChange = (name, value) => {
    // Update the corresponding field in formData using the name as the key
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="edit-driver-cont">
        <div className="close-cont">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            onClick={handleClose}
          >
            <path
              d="M18.625 16.375C19.125 16.875 19.125 17.675 18.625 18.175C18.4249 18.375 18.025 18.575 17.725 18.575C17.425 18.575 17.125 18.475 16.825 18.175L9.72495 11.075L2.62495 18.175C2.42495 18.375 2.02495 18.575 1.72495 18.575C1.42495 18.575 1.12495 18.475 0.824951 18.175C0.324951 17.675 0.324951 16.875 0.824951 16.375L7.92495 9.275L0.824951 2.175C0.324951 1.675 0.324951 0.875 0.824951 0.375C1.32495 -0.125 2.12495 -0.125 2.62495 0.375L9.72495 7.475L16.825 0.375C17.325 -0.125 18.125 -0.125 18.625 0.375C19.125 0.875 19.125 1.675 18.625 2.175L11.525 9.275L18.625 16.375Z"
              fill="#393838"
            />
          </svg>
        </div>
        <div className="driver-profile-cont">
          <h2 className="edit-header">
            {english("page-subheaders.driver-profile")}
          </h2>
          <form action="">
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.newDriver.input-headers.name")}
              </label>
              <div className="input-cont">
                <input
                  placeholder="John"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className="text-input"
                  type="text"
                />
                <input
                  placeholder="Doe"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  className="text-input"
                  type="text"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.newDriver.input-headers.license")}
              </label>
              <div className="input-cont">
                <input
                  placeholder="A0002144"
                  className="text-input"
                  type="text"
                  value={formData.licenseNumber}
                  name="licenseNumber"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.newDriver.input-headers.personalPhone")}
              </label>
              <PhoneInput
                name={"personalPhoneNumber"}
                value={formData.personalPhoneNumber}
                change={handlePhoneChange}
              />
            </div>
            <div className="form-group">
              <label className="input-label">
                {english("forms.type.newDriver.input-headers.companyPhone")}
              </label>
              <PhoneInput
                name={"companyPhoneNumber"}
                value={formData.companyPhoneNumber}
                change={handlePhoneChange}
              />
            </div>
            <div className="form-group">
              <label className="input-label required">
                {english("forms.type.newDriver.input-headers.email")}
              </label>
              <div className="input-cont">
                <input
                  placeholder="john@agramontworldwide.com"
                  className="text-input"
                  type="text"
                  value={formData.emailAddress}
                  name="emailAddress"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label">
                {english("forms.type.newDriver.input-headers.medical")}
              </label>
              <div className="input-cont">
                <textarea
                  className="text-input area"
                  value={formData.medicalNotes}
                  name="medicalNotes"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="input-label">
                {english("forms.type.newDriver.input-headers.username")}
              </label>
              <div className="input-cont">
                <input
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="text-input"
                  type="text"
                  placeholder="Username"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label">
                {english("forms.type.newDriver.input-headers.password")}
              </label>
              <div className="input-cont">
                <input
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="text-input"
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
            <div className="driver-photo-id">
              <h2 className="edit-header">
                {english("forms.type.newDriver.input-headers.id")}
              </h2>
              {isImgLoading ? (
                <div data-testid="photo-loading-state" className="driver-photo">
                  <Spinner />
                </div>
              ) : formData.photo ? (
                <div className="driver-photo">
                  <img src={formData.photo[0]} alt="" />
                </div>
              ) : !url ? (
                <div className="driver-photo">
                  <ProfilePhoto />
                </div>
              ) : (
                <div data-testid="photo-state" className="driver-photo">
                  <img src={url} alt="" />
                </div>
              )}
              <input
                className="file-input"
                data-testid="file-input"
                type="file"
                name="photo"
                id="fileInput"
                label="Upload Profile Picture"
                onChange={handleFileChange}
                accept="image/*, .heic"
                ref={fileInputRef} // Connect ref to the input element
              />

              <div>
                <button onClick={handleUploadClick} className="cancel-btn">
                  {english("buttons.edit-photo")}
                </button>
              </div>
            </div>
            <div className="emergancy-edit">
              <h2 className="edit-header">Emergency contact 1</h2>
              <div className="form-group">
                <label className="input-label required">Contact name</label>
                <div className="input-cont">
                  <input
                    placeholder="John"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact1Name}
                    name="emergencyContact1Name"
                    onChange={handleChange}
                  />
                  <input
                    placeholder="Doe"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact1LastName}
                    name="emergencyContact1LastName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="input-label required">
                  Cell phone number
                </label>
                <PhoneInput
                  name={"emergencyContact1PhoneNumber"}
                  value={formData.emergencyContact1PhoneNumber}
                  change={handlePhoneChange}
                />
              </div>
            </div>
            <div className="emergancy-edit">
              <h2 className="edit-header">Emergency contact 2</h2>
              <div className="form-group">
                <label className="input-label required">Contact name</label>
                <div className="input-cont">
                  <input
                    placeholder="John"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact2Name}
                    name="emergencyContact2Name"
                    onChange={handleChange}
                  />
                  <input
                    placeholder="Doe"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact2LastName}
                    name="emergencyContact2LastName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="input-label required">
                  Cell phone number
                </label>
                <PhoneInput
                  name={"emergencyContact2PhoneNumber"}
                  value={formData.emergencyContact2PhoneNumber}
                  change={handlePhoneChange}
                />
              </div>
            </div>
            <div className="emergancy-edit">
              <h2 className="edit-header">Emergency contact 3</h2>
              <div className="form-group">
                <label className="input-label required">Contact name</label>
                <div className="input-cont">
                  <input
                    placeholder="John"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact3Name}
                    name="emergencyContact3Name"
                    onChange={handleChange}
                  />
                  <input
                    placeholder="Doe"
                    className="text-input"
                    type="text"
                    value={formData.emergencyContact3LastName}
                    name="emergencyContact3LastName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="input-label required">
                  Cell phone number
                </label>
                <PhoneInput
                  name={"emergencyContact3PhoneNumber"}
                  value={formData.emergencyContact3PhoneNumber}
                  change={handlePhoneChange}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="btn-cont">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <button onClick={handleSave} className="profile-pic-btn">
                Save
              </button>
              <button className="other-btn gap" onClick={handleClose}>
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
