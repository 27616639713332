import { useEffect, useState } from "react";
import { Dots } from "../../LoadingAnimations/LoadingAnimations";
import instance from "../../../Interceptor/api_instance";

export const CheckOutVisitor = ({
  onClose,
  confirm,
  visitor,
  english,
  loading,
}) => {
  const [imgUrls, setImgUrls] = useState([]);
  const [loadingImg, setLoadingImg] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      const postData = {
        blob_names: visitor.image_urls.blob_names,
        image_urls: visitor.image_urls.urls,
        image_container: visitor.image_urls.container_name,
      };

      setLoadingImg(true);

      const data = await instance.post("/assets/get-entry-images/", postData);
      if (data.data !== "") {
        setImgUrls(data.data.img_urls);
      }
      setLoadingImg(false);
    };
    if (visitor.image_urls) {
      fetchImages();
    }
  }, [visitor]);

  const openModal = (image) => setSelectedImg(image);
  const closeModal = () => setSelectedImg(null);

  return (
    <div className="confirm-cont prompt-cont">
      <h2 className="global-h2 light-black">
        {english("forms.type.check-out-confirm")} {visitor.visitor.first_name}{" "}
        {visitor.visitor.last_name}?
      </h2>
      <div className="driver-photo-id">
        <h2 className="edit-header">
          {english("forms.type.newDriver.input-headers.photo")}
        </h2>
        <div data-testid="profile-photo" className="visitor-photo">
          {loadingImg ? (
            <Dots />
          ) : (
            <div className="visitor-photo-cont">
              {imgUrls.length > 0 ? (
                imgUrls.map((image, index) => (
                  <img
                    alt="visitor imgs"
                    src={image}
                    key={index}
                    onClick={() => openModal(image)}
                    style={{ cursor: "pointer" }}
                  />
                ))
              ) : (
                <p>No images found for this visitor</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="prompt-btns">
        {loading ? (
          <Dots />
        ) : (
          <div style={{display: "flex", gap: "24px"}}>
            <button className="change-pwd-btn" onClick={confirm}>
              {english("buttons.check-out")}
            </button>
            <button className="cancel-btn" onClick={onClose}>
              {english("buttons.cancel")}
            </button>
          </div>
        )}
      </div>

      {selectedImg && (
        <div style={{ zIndex: "7" }} className="enlarged-modal">
          <div className="img-cont">
            <img src={selectedImg} alt="Expanded view" />
          </div>
          <div className="btn-cont">
            <button className="close-btn" onClick={closeModal}>
              {english("buttons.back")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
