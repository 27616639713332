import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "../../Utility/useWindowWidth";
import { sortData } from "../Sorting/functions";
import { UpArrow, DownArrow, PrinterIcon } from "../../../Media/Icons";
import { generateQRCodeZip } from "../../Modals/Documents/AssetQrCode";

export const AssetsTable = ({ headers, data, dispatch, type }) => {
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState(data);
  const [order, setOrder] = useState(true);
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const isAllSelected = selectedRows.length === data.length && data.length > 0;

  // Handle individual row selection
  const handleRowSelection = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const printSelectedRows = async () => {
    const rows = data.filter((row) => selectedRows.includes(row.id));
    dispatch({ type: "IS_LOADING", payload: true });
    await generateQRCodeZip(rows, type);
    dispatch({ type: "IS_LOADING", payload: false });
  };

  // Handle "Select All" checkbox
  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((row) => row.id));
    }
  };

  // Check if user is on mobile (phone)
  const width = useWindowWidth();
  const isMobile = width <= 500;

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const getCellValue = (header, row) => {
    switch (
      header // Render cell value based on header name
    ) {
      case t("yard-tables.table-columns.location"):
        return row.location.loc_name;
      case t("yard-tables.table-columns.unit"):
        return row.unit;
      case t("yard-tables.table-columns.license_plate"):
        return row.license_plate;
      default:
        return null;
    }
  };

  const toggleRowExpand = (index) => {
    // Expand row on mobile
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <>
      <div className="table-cont">
        <table id="forwardTable" className="yard-table">
          <thead>
            <tr className="table-header">
              <th scope="col" style={{ display: "flex", gap: "12px" }}>
                <input
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                />
                <PrinterIcon func={printSelectedRows} />
              </th>
              {headers?.map((header, index) => (
                <th scope="col" key={`${header}-${index}`}>
                  <button
                    className="col-header"
                    onClick={() =>
                      sortData(
                        filteredData,
                        header,
                        setFilteredData,
                        order,
                        setOrder,
                        t
                      )
                    }
                  >
                    {header}
                    <div className="order-filter">
                      <UpArrow />
                      <DownArrow />
                    </div>
                  </button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr
                key={index}
                className="data-rows row-hover"
                style={{ maxHeight: expandedRow === index ? "700px" : "180px" }}
              >
                {isMobile && (
                  <td>
                    <button
                      className="all-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleRowExpand(index);
                      }}
                    >
                      Toggle Expand
                    </button>
                  </td>
                )}
                <td className="gray">
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(row.id)}
                    onChange={(e) => {
                      handleRowSelection(row.id);
                    }}
                  />
                </td>
                {headers?.map((header, index) => (
                  <>
                    {isMobile && (
                      <td
                        key={`${header}-${index}`}
                        className="gray"
                        data-label={header}
                      ></td>
                    )}
                    <td
                      key={index}
                      className="gray cursor-pointer hover"
                      onClick={() =>
                        dispatch({
                          type: "SET_SELECTED_ROW",
                          selectedRow: row,
                        })
                      }
                    >
                      {getCellValue(header, row)}
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
