import { useEffect, useState } from "react";
import instance from "../../../Interceptor/api_instance";
import Select from "react-select";
import { mexicanStates, usStates } from "../../../Helper/CountryStates";
import { useTranslation } from "react-i18next";
import { ScannerIcon } from "../../../Media/Icons";
import { useAuth } from "../../../Auth/AuthProvider";
import QRScanner from "../../Utility/QRScanner";

export const CheckOutForm = ({
  formState,
  dispatchForm,
  truckBody,
  setTruckBody,
  trailerBody,
  setTrailerBody,
  visitorBody,
  setVisitorBody,
  close,
  nextForm,
}) => {
  const { t } = useTranslation();
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const { user } = useAuth();
  const mechanicOptions =
    Array.isArray(user.groups) &&
    user.groups.some(
      (group) => group.name === "Admin" || group.name === "Safety"
    );
  // Updates state for any change on truck form groups
  const handleTruckUpdate = (key, value) => {
    setTruckBody((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleTrailerUpdate = (key, value) => {
    setTrailerBody((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const fetchCustomers = async () => {
    try {
      const response = await instance.get("assets/customers");
      dispatchForm({ type: "STORE_CUSTOMERS", payload: response.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    handleTrailerUpdate("customer", value);

    if (value) {
      const filteredSuggestions = formState.customerList.filter((customer) =>
        customer.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCustomers(filteredSuggestions);
    } else {
      setFilteredCustomers(null);
    }
  };

  const handleCustomerClick = (name) => {
    setFilteredCustomers(null);
    handleTrailerUpdate("customer", name);
  };

  useEffect(() => {
    if (trailerBody.is_loaded && formState.customerList.length === 0) {
      fetchCustomers();
    }
  }, [trailerBody.is_loaded]);

  const truckList = formState.truckList.map((truck) => ({
    // Format list for ATI trucks for Select React component
    ...truck.truck,
    value: truck.truck.id,
    label: `${truck.truck.carrier_name} - ${truck.truck.truck_number} ${
      truck.truck.requires_check_out ? "⚠️" : ""
    }`, // Label displays option in Select dropdown
  }));

  const trailerList = formState.trailerList.map((trailer) => ({
    ...trailer.trailer,
    value: trailer.trailer.id,
    label: `${trailer.trailer.trailer_number} ${
      trailer.trailer.requires_check_out ? "⚠️" : ""
    }`,
  }));

  const visitorList = formState.visitorList.map((event) => ({
    ...event.visitor,
    value: event.visitor.id,
    label: `${event.visitor.first_name} ${event.visitor.last_name}`,
  }));

  const validateUrl = (url) => {
    const apiBaseUrl = `${process.env.REACT_APP_API_URL}assets/`;
    if (url.startsWith(apiBaseUrl)) {
      return true;
    } else {
      console.error("Invalid QR Code. Not from allowed domain:", url);
      return false;
    }
  };

  const handleTrailerScan = async (scan) => {
    const url = scan;
    if (!url || !validateUrl(url)) return;

    try {
      const response = await instance.get(url);
      if (response.status === 200) {
        const data = response.data;
        const selectedTrailer = trailerList.find((trailer) => {
          const trailer_asset = trailer.trailer_asset;
          if (trailer_asset) {
            return trailer_asset === data.id;
          } else {
            return false;
          }
        });
        console.log(selectedTrailer);
        if (!selectedTrailer) {
          throw new Error(
            `${data.unit}: ${t("forms.responses.qr-scans.not-found")}`
          );
        }
        // Update trailer number
        handleTrailerSelect(selectedTrailer);
      }
    } catch (error) {
      console.error(error);

      // Ensure error is properly formatted as a string
      dispatchForm({
        type: "ERROR_MESSAGE",
        key: "trailerErrorMessage",
        payload: error.message || error,
      });
    }
  };

  const handleTrailerSelect = (option) => {
    if (option === null) {
      setTrailerBody({});
      dispatchForm({
        type: "LOCK_TRUCK_INPUT",
        message: null,
        value: false,
      });
      return;
    }
    const reservation = option.reservation; // Handle auto locking truck if trailer is reserved
    let message = "";
    let reserved_by = "";
    if (reservation) {
      reserved_by = `${t("forms.type.vehicle.check-out.reserved-by")} ${
        // Define user reservation
        reservation.user.first_name
      } ${reservation.user.last_name}.`;

      const truck_asset = reservation.truck_asset; // Get truck reserved to haul trailer if exists
      if (truck_asset) {
        const selectedTruck = truckList.find(
          // Find truck if checked in
          (truck) => truck.truck_asset === truck_asset.id
        );
        if (selectedTruck) {
          // If found update truck number input and lock it
          message = `${t("forms.type.vehicle.check-out.trailer-is-reserved")} ${
            selectedTruck.carrier_name
          }-${selectedTruck.truck_number}. ${reserved_by}`;
          setTruckBody(selectedTruck);
        } else {
          // Truck reserved was not found in list
          message = `${t("forms.type.vehicle.check-out.truck-not-in")} ${
            truck_asset.company
          }-${truck_asset.name}. ${reserved_by}`;
          setTruckBody({});
        }
        dispatchForm({
          type: "LOCK_TRUCK_INPUT",
          message: message,
          value: true,
        });
      } else {
        message = reserved_by;
        dispatchForm({
          type: "LOCK_TRUCK_INPUT",
          message: message,
          value: false,
        });
      }
    } else {
      dispatchForm({
        type: "LOCK_TRUCK_INPUT",
        message: message,
        value: false,
      });
    }

    setTrailerBody(option); // Update trailer number in the final step
  };

  const handleTruckScan = async (scan) => {
    const url = scan;
    if (!url || !validateUrl(url)) return;

    try {
      const response = await instance.get(url);
      if (response.status === 200) {
        const data = response.data;

        // Find scanned truck in list
        const selectedTruck = formState.truckList.find((event) => {
          const truck_asset = event.truck.truck_asset;
          if (truck_asset) {
            return truck_asset === data.id;
          } else {
            return false;
          }
        });
        // Error handle scanned truck
        if (!selectedTruck) {
          throw new Error(
            `${selectedTruck.label}: ${t("forms.responses.qr-scans.not-found")}`
          );
        }

        // Update all states if no errors
        setTruckBody(selectedTruck.truck);
        dispatchForm({
          type: "ERROR_MESSAGE",
          key: "truckErrorMessage",
          value: null,
        });
      }
    } catch (error) {
      console.error(error);

      // Ensure error is properly formatted as a string
      dispatchForm({
        type: "ERROR_MESSAGE",
        key: "truckErrorMessage",
        payload: error.message || error,
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%", // Make sure control is full width
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto", // Override to handle padding
    }),
    // You might want to adjust the width of the menu to match the control
    container: (provided) => ({
      ...provided,
      width: "100%", // Ensure the dropdown menu is also full width
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "gray" : provided.color,
      borderBottom: "0.5px solid gray",
      padding: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };

  return (
    <div className="questions-cont">
      <h2 className="global-h2 light-black">
        {t("forms.type.vehicle.check-out.header")}
      </h2>

      <form onSubmit={nextForm}>
        {/* Form group: Is truck hauling trailer */}
        <div className="form-group">
          <label className="input-label required">
            {t("forms.type.vehicle.input-headers.carrying-bool")}{" "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="group1"
                  className="radio-input"
                  checked={formState.isCarrying}
                  onChange={() =>
                    dispatchForm({
                      type: "IS_CARRYING",
                      payload: true,
                    })
                  }
                  required
                />
                <label>{t("general.yes")}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="group1"
                  className="radio-input"
                  checked={formState.isCarrying === false}
                  onChange={() => {
                    dispatchForm({ type: "IS_CARRYING", payload: false });
                    setTrailerBody({});
                  }}
                />
                <label>No</label>
              </div>
            </fieldset>
          </div>
        </div>

        {/* Form group: Truck number */}
        <div className="form-group">
          <label className="input-label required">
            {t("forms.type.vehicle.input-headers.truck-number")}{" "}
          </label>
          <div className="input-cont-block input-cont-message">
            <div className="input-cont input-cont-margin-bottom">
              <Select
                options={truckList}
                value={truckList.find((truck) => truck.id === truckBody.id)}
                styles={customStyles}
                isClearable={true}
                isSearchable={true}
                unstyled={true}
                isLoading={truckList.length === 0}
                loadingMessage={() => "Loading options, please wait..."}
                onChange={(option) => setTruckBody(option ? option : {})}
                required={true}
                isDisabled={formState.lockTruck}
              />
              <ScannerIcon
                setScanner={
                  formState.lockTruck
                    ? null
                    : () =>
                        dispatchForm({
                          type: "IS_TRUCK_SCANNING",
                          payload: !formState.truckCodeScanner,
                        })
                }
              />
            </div>
            {formState.truckCodeScanner && (
              <div className="input-cont-margin-bottom">
                <QRScanner
                  onScanSuccess={(scan) => {
                    if (scan) {
                      handleTruckScan(scan);
                      dispatchForm({
                        type: "IS_TRUCK_SCANNING",
                        payload: false,
                      });
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="input-label">
            {t("forms.type.vehicle.input-headers.truck-plate")}
          </label>
          <div className="input-cont">
            <label className="input-label">{truckBody.truck_plate}</label>
          </div>
        </div>
        {/* Form group: Odometer */}
        <div className="form-group">
          <label className="input-label required">
            {t("forms.type.vehicle.input-headers.odometer")}
          </label>
          <div className="input-cont">
            <input
              type="number"
              id="odometer"
              inputMode="numeric"
              min={0}
              className="text-input"
              placeholder={t("forms.type.vehicle.placeholders.enter-miles")}
              value={truckBody.odometer || 0}
              onChange={(e) => {
                handleTruckUpdate("odometer", e.target.value);
              }}
              required
            />
          </div>
        </div>

        {/* Form group: Truck condition */}
        <div className="form-group">
          <label className="input-label required">
            {t("forms.type.vehicle.input-headers.truck_condition")}
          </label>
          <div className="input-cont">
            <div>
              <select
                id="conditionSelect"
                onChange={(e) =>
                  handleTruckUpdate("truck_condition", e.target.value)
                }
                value={truckBody.truck_condition || ""}
                required
              >
                <option value="" selected disabled>
                  {t("forms.type.vehicle.placeholders.select-condition.header")}
                </option>
                <option value="Good">
                  {t("forms.type.vehicle.placeholders.select-condition.good")}
                </option>
                <option value="Okay">Okay</option>
                <option value="Poor">
                  {t("forms.type.vehicle.placeholders.select-condition.poor")}
                </option>
                <option value="Critical">
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.critical"
                  )}
                </option>
                <option value="Under Repair">
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.under-repair"
                  )}
                </option>
                <option value="Waiting parts" disabled={!mechanicOptions}>
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.spare-parts"
                  )}
                </option>
                <option
                  value="Waiting instructions"
                  disabled={!mechanicOptions}
                >
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.instructions"
                  )}
                </option>
                <option value="External repair" disabled={!mechanicOptions}>
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.external-repair"
                  )}
                </option>
              </select>
              <textarea
                type="text"
                className="text-input"
                placeholder={t("forms.type.vehicle.placeholders.notes")}
                value={truckBody.truck_condition_notes}
                onChange={(e) =>
                  handleTruckUpdate("truck_condition_notes", e.target.value)
                }
                required={
                  truckBody.truck_condition !== "Good" &&
                  truckBody.truck_condition !== "Okay"
                }
              ></textarea>
            </div>
          </div>
        </div>

        {/* Form group: Driver */}
        <div className="form-group">
          <label className="input-label required">
            {t("yard-tables.search-filters.dropdowns.driver")}{" "}
          </label>
          <div className="input-cont">
            <Select
              value={visitorList.find(
                (visitor) => visitor.id === visitorBody.id
              )}
              onChange={(option) => setVisitorBody(option ? option : {})}
              options={visitorList}
              isClearable={true}
              isSearchable={true}
              styles={customStyles}
              unstyled={true}
              isLoading={visitorList.length === 0}
              loadingMessage={() => t("general.loading")}
              required
            />
          </div>
        </div>

        {/* All trailer inputs */}
        {formState.isCarrying && (
          <>
            {/* // Form group: Trailer number */}
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.vehicle.input-headers.trailer-number")}{" "}
              </label>
              <div className="input-cont-block input-cont-message">
                <div className="input-cont input-cont-margin-bottom">
                  <Select
                    id="carrier"
                    value={trailerList.find(
                      (trailer) => trailer.id === trailerBody.id
                    )}
                    options={trailerList}
                    onChange={(option) => handleTrailerSelect(option)}
                    placeholder={t(
                      "forms.type.vehicle.placeholders.select-num"
                    )}
                    isClearable={true}
                    isSearchable={true}
                    styles={customStyles}
                    unstyled={true}
                    isLoading={trailerList.length === 0}
                    loadingMessage={() => "Loading options, please wait..."}
                    required={true}
                  />
                  <ScannerIcon
                    setScanner={() =>
                      dispatchForm({
                        type: "IS_TRAILER_SCANNING",
                        payload: !formState.trailerCodeScanner,
                      })
                    }
                  />
                </div>
                {formState.trailerCodeScanner && (
                  <div className="input-cont-margin-bottom">
                    <QRScanner
                      onScanSuccess={(scan) => {
                        if (scan) {
                          handleTrailerScan(scan);
                          dispatchForm({
                            type: "IS_TRAILER_SCANNING",
                            payload: false,
                          });
                        }
                      }}
                    />
                  </div>
                )}
                {formState.trailerErrorMessage && (
                  <p className="error">{formState.trailerErrorMessage}</p>
                )}
              </div>
            </div>

            {/* Trailer license plate */}
            <div className="form-group">
              <label className="input-label">
                {t("forms.type.vehicle.input-headers.trailer-plate")}
              </label>
              <div className="input-cont">
                <label className="input-label">
                  {trailerBody.license_plate}
                </label>
              </div>
            </div>

            {/* Form group: Trailer dimensions */}
            <div className="form-group">
              <label className="input-label">
                {t("forms.type.vehicle.input-headers.trailer-dim")}
              </label>
              <div className="input-cont">
                <label className="input-label">
                  {t("forms.type.vehicle.placeholders.length")}:{" "}
                  {trailerBody.trailer_dimensions?.length || 0}
                </label>
                <label className="input-label">
                  {t("forms.type.vehicle.placeholders.width")}:{" "}
                  {trailerBody.trailer_dimensions?.width || 0}
                </label>
                <label className="input-label">
                  {t("forms.type.vehicle.placeholders.height")}:{" "}
                  {trailerBody.trailer_dimensions?.height || 0}
                </label>
              </div>
            </div>

            {/* Form group: Is loaded */}
            <div className="form-group">
              <label className="input-label required">
                {t("yard-tables.search-filters.dropdowns.contents")}
              </label>
              <div className="input-cont">
                <select
                  id="contentSelect"
                  onChange={(e) =>
                    handleTrailerUpdate("is_loaded", e.target.value)
                  }
                  value={trailerBody.is_loaded}
                  required
                >
                  <option value="" disabled selected>
                    {t("forms.type.vehicle.placeholders.select-content")}
                  </option>
                  <option value={true}>
                    {t("forms.type.vehicle.placeholders.loaded")}
                  </option>
                  <option value={false}>
                    {t("forms.type.vehicle.placeholders.empty")}
                  </option>
                </select>
              </div>
            </div>
            {trailerBody.is_loaded === "true" && (
              // Form group: Customer
              <div className="form-group">
                <label className="input-label required">
                  {t("yard-tables.search-filters.dropdowns.customer")}
                </label>
                <div className="input-cont">
                  <div className="search-input">
                    <input
                      id="searchCustomer"
                      type="text"
                      className="text-input"
                      placeholder={t(
                        "forms.type.vehicle.placeholders.search-customer"
                      )}
                      value={trailerBody.customer}
                      onChange={handleInputChange}
                      required
                    />
                    {filteredCustomers?.length > 0 && (
                      <ul
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          width: "100%",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          maxHeight: "150px",
                          overflowY: "auto",
                          backgroundColor: "white",
                          listStyleType: "none",
                          padding: 0,
                          margin: 0,
                          zIndex: 1000,
                        }}
                      >
                        {filteredCustomers.map((customer, index) => (
                          <li
                            key={index}
                            onClick={() => handleCustomerClick(customer.name)}
                            style={{
                              padding: "8px",
                              cursor: "pointer",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            {customer.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Form group: Seal */}
            <div className="form-group">
              <label className="input-label">
                {t("forms.type.vehicle.placeholders.seal")}
              </label>
              <div className="input-cont">
                <input
                  id="sealNum"
                  onChange={(e) =>
                    handleTrailerUpdate("seal_number", e.target.value)
                  }
                  value={trailerBody.seal_number}
                  className="text-input"
                  placeholder={t("forms.type.vehicle.placeholders.num")}
                />
              </div>
            </div>

            {/* Form group: Trailer condition */}
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.vehicle.input-headers.trailer_condition")}
              </label>
              <div className="input-cont">
                <div>
                  <select
                    id="conditionSelect"
                    onChange={(e) =>
                      handleTrailerUpdate("trailer_condition", e.target.value)
                    }
                    value={trailerBody.trailer_condition}
                    required
                  >
                    <option value="" selected disabled>
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.header"
                      )}
                    </option>
                    <option value="Good">
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.good"
                      )}
                    </option>
                    <option value="Okay">Okay</option>
                    <option value="Poor">
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.poor"
                      )}
                    </option>
                    <option value="Critical">
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.critical"
                      )}
                    </option>
                    <option value="Under Repair">
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.under-repair"
                      )}
                    </option>
                    <option value="Waiting parts" disabled={!mechanicOptions}>
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.spare-parts"
                      )}
                    </option>
                    <option
                      value="Waiting instructions"
                      disabled={!mechanicOptions}
                    >
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.instructions"
                      )}
                    </option>
                    <option value="External repair" disabled={!mechanicOptions}>
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.external-repair"
                      )}
                    </option>
                  </select>
                  <textarea
                    type="text"
                    className="text-input"
                    placeholder={t("forms.type.vehicle.placeholders.notes")}
                    value={
                      trailerBody === "null"
                        ? ""
                        : trailerBody.trailer_condition_notes
                    }
                    onChange={(e) =>
                      handleTrailerUpdate(
                        "trailer_condition_notes",
                        e.target.value
                      )
                    }
                    required={
                      trailerBody.trailer_condition !== "Good" &&
                      trailerBody.trailer_condition !== "Okay"
                        ? true
                        : false
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Form group: Destination */}
        <>
          <div className="form-group">
            <label className="input-label required">
              {t("yard-tables.search-filters.dropdowns.destination")}
            </label>
            <div className="input-cont">
              <select
                id="country"
                onChange={(e) => handleTruckUpdate("country", e.target.value)}
                value={truckBody.country || ""}
                required
              >
                <option value="" selected disabled>
                  {t("forms.type.vehicle.placeholders.destination.country")}
                </option>
                <option value="USA">
                  {t("forms.type.vehicle.placeholders.usa")}
                </option>
                <option value="Mexico">Mexico</option>
              </select>
              <select
                id="state"
                value={truckBody.countryState || ""}
                onChange={(e) =>
                  handleTruckUpdate("countryState", e.target.value)
                }
                required
              >
                <option value="">
                  {t("forms.type.vehicle.placeholders.destination.state")}
                </option>
                {truckBody.country === "USA"
                  ? usStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))
                  : mexicanStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="input-label"></label>
            <div className="input-cont">
              <input
                required
                id="city"
                type="text"
                className="text-input"
                placeholder={t(
                  "forms.type.vehicle.placeholders.destination.City"
                )}
                value={truckBody.city || ""}
                onChange={(e) => handleTruckUpdate("city", e.target.value)}
              />
            </div>
          </div>
        </>

        {/* Form group: Notes */}
        <div className="form-group">
          <label className="input-label">
            {t("forms.type.vehicle.input-headers.notes")}
          </label>
          <div className="input-cont">
            <input
              className="text-input"
              placeholder={t("forms.type.vehicle.placeholders.notes")}
              type="text"
              value={truckBody.generalNotes}
              onChange={(e) =>
                handleTruckUpdate("generalNotes", e.target.value)
              }
            />
          </div>
        </div>

        {/* Buttons cont */}
        <div className="full-cont">
          <button className="change-pwd-btn" id="continue-btn" type="submit">
            {t("buttons.continue")}
          </button>
          <button className="cancel-btn" onClick={close}>
            {t("buttons.cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};
