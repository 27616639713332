export const VehicleTabs = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <ul className="yard-tabs">
      {tabs?.map((tab, index) => (
        <li key={`${tab}-${index}`}
          onClick={() => setSelectedTab(tab)}
          className={tab === selectedTab ? "selected" : "tab"}
        >
          {tab}
        </li>
      ))}
    </ul>
  );
};