import "./App.css";
import "./CSS/global.css";
import "./CSS/checkInTrailer.css";
import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./Components/Login";
import { Logout } from "./Components/Logout";
import {
  faChevronDown,
  faEnvelope,
  faLock,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { DispatchOverview } from "./Components/Routes/DispatchOverview";
import { InventoryView } from "./Components/Routes/InventoryView";
import { Reservations } from "./Components/Routes/Reservations";
import { Settings } from "./Components/Routes/Settings";
import { CheckOut } from "./Components/Routes/CheckOut";
import { ForgotPassword } from "./Components/Routes/ForgotPassword";
import { ErrorPage } from "./Components/Routes/ErrorPage";
import { LanguageProvider } from "./Media/LanguageContext";
import { Reports } from "./Components/Routes/Reports";
import DrivingTeam from "./Components/Routes/DrivingTeam";
import NewDriver from "./Components/Forms/Driver/NewDriver";
import { ScannedId, IdTemplate } from "./Components/Routes/ScannedId";
import Unauthorized from "./Components/Routes/Unauthorized";
import BackLog from "./Components/Routes/BackLog";
import DriverReports from "./Components/Routes/DriversReports";

import { AuthProvider } from "./Auth/AuthProvider";
import { ProtectedRoute } from "./Auth/RouteProtector";
import { Navigation } from "./Components/Navigation/Navigation";
import { CheckIn } from "./Components/Routes/CheckIn";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Suspense } from "react";
import { MechanicReportsView } from "./Components/Routes/MechanicReportsView";
import { BacklogCTPAT } from "./Components/Routes/BacklogCTPAT";
import { EmployeesView } from "./Components/Routes/Employees";
import { NewEmployee } from "./Components/Routes/NewEmployee";
import { MechanicBacklog } from "./Components/Routes/MechanicBacklog";
import { OverviewRefactor } from "./Components/Routes/OverviewRefactor";
import { NewSideBar2 } from "./Components/Navigation/NewSidebar";
import { Assets } from "./Components/Routes/Assets";

library.add(faChevronDown, faEnvelope, faLock, faMagnifyingGlass);

// Help page with gifs

// KNOWN ISSUES
// Check if truck is checked in
// filtering by carrier on driving team tabel => TypeError: Cannot read properties of undefined (reading 'carrier_name')

// add QR codes to trucks and trailers to auto fill the data in the check in and check out form

function App() {

  let inactivityTimer;
  let lastActivityTime = Date.now();
  
  const refreshPage = () => {
    window.location.reload();
  }

  // Function to reset the inactivity timer
  const resetTimer = () => {
    clearTimeout(inactivityTimer);
    lastActivityTime = Date.now();
    
    // Refresh the page after 5 minutes (300,000ms) of inactivity
    inactivityTimer = setTimeout(refreshPage, 300000); 
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      // Tab has become visible again, reset the inactivity timer
      const currentTime = Date.now();
      const timeElapsed = currentTime - lastActivityTime;

      // If 5 minutes have passed, refresh the page
      if (timeElapsed >= 600000) {
        refreshPage();
      } else {
        // If less than 5 minutes have passed, reset the timer
        resetTimer();
      }
    } else {
      // Tab is hidden, clear the inactivity timer
      clearTimeout(inactivityTimer);
    }
  }

  useEffect(() => {
    // Set up event listeners for user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);
    window.addEventListener("scroll", resetTimer);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Start the inactivity timer when the component mounts
    resetTimer();

    // Clean up the event listeners and timer on component unmount
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("scroll", resetTimer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }
  }, []);
  
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <LanguageProvider>
          <div className="App">
            {/* <HelpContainer /> */}
            <AuthProvider>
              <Navigation></Navigation>
              <NewSideBar2></NewSideBar2>
              <Routes>
                {/* unprotected routes */}
                <Route path="*" element={<ErrorPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/scanned-id/:carrier/:id"
                  element={<ScannedId />}
                />
                <Route path="/id-template" element={<IdTemplate />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                {/* Guard Routes */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={[
                        "Admin",
                        "Guard",
                        "Guard Supervisor",
                        "Safety",
                      ]}
                    />
                  }
                >
                  <Route path="/check-in-trailer" element={<CheckIn />} />
                  <Route path="/check-out" element={<CheckOut />} />
                </Route>

                {/* Shared inventory Guard and Dispatch */}
                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={[
                        "Admin",
                        "Guard",
                        "Guard Supervisor",
                        "Dispatch",
                        "Safety",
                        "Mechanic"
                      ]}
                    />
                  }
                >
                  <Route path="/" element={<OverviewRefactor/>} />
                  <Route path="/inventory-view" element={<InventoryView />} />
                </Route>
                <Route element={<ProtectedRoute allowedRoles={["Admin", "Guard", "Guard Supervisor", "Dispatch", "Night Dispatch", "Safety", "Mechanic"]}/>}>
                  <Route path="/settings" element={<Settings/>}/>
                </Route>

                {/* Shared inventory Dispatch and Safety */}

                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={[
                        "Admin",
                        "Dispatch",
                        "Guard Supervisor",
                        "Safety",
                      ]}
                    />
                  }
                >
                  <Route path="/backlog" element={<BackLog />} />
                </Route>

                {/* Shared inventory Guard and Safety */}

                <Route
                  element={
                    <ProtectedRoute
                      allowedRoles={[
                        "Admin",
                        "Guard",
                        "Guard Supervisor",
                        "Safety",
                      ]}
                    />
                  }
                >
                </Route>
                {/* Mechanic Routes */}
                <Route
                element={
                  <ProtectedRoute allowedRoles={["Mechanic", "Mechanic Supervisor", "Admin", "Safety"]}/>
                }>
                  <Route path="/repairs-backlog" element={<MechanicReportsView/>}/>
                  <Route path="/mechanic-reports" element={<MechanicBacklog/>}/>
                </Route>
                {/* Dispatch Routes */}
                <Route
                  element={
                    <ProtectedRoute allowedRoles={["Admin", "Dispatch"]} />
                  }
                >
                  <Route
                    element={<DispatchOverview />}
                  />
                  <Route path="/reservations" element={<Reservations />} />
                </Route>

                {/* Safety Routes */}
                <Route
                  element={
                    <ProtectedRoute allowedRoles={["Admin", "Safety"]} />
                  }
                >
                  <Route path="/ctpat-reports" element={<BacklogCTPAT/>}/>
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/new-driver" element={<NewDriver />} />
                  <Route path="/driving-team" element={<DrivingTeam />} />
                  <Route path="/staff" element={<EmployeesView/>}/>
                  <Route path="/new-employee" element={<NewEmployee/>}/>
                  <Route path="/assets" element={<Assets/>}/>
                 </Route>

                {/* Admin Routes */}
                <Route element={<ProtectedRoute allowedRoles={["Admin", "Guard", "Guard Supervisor", "Dispatch", "Safety"]} />}>
                  <Route path="/driver-reports" element={<DriverReports />} />
                </Route>
              </Routes>
            </AuthProvider>
          </div>
        </LanguageProvider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
