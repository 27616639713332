import React from "react";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";
import { useTranslation } from "react-i18next";

export default function CheckoutConfirm({
  truck,
  trailer,
  close,
  submit,
  type,
  requestLoading,
}) {
  const {t} = useTranslation();
  const renderSubmitButton = () => {
    if (type === "check out" && truck) {
      return (
        <button id="submit-btn" onClick={submit} className="change-pwd-btn">
          {t("buttons.submit")}
        </button>
      );
    } else if (type === "check in") {
      return (
        <button onClick={submit} id="submit-btn" className="change-pwd-btn">
          {t("buttons.submit")}
        </button>
      );
    } else if (type !== "check in") {
      return (
        <p className="error m-0">
          {t("forms.type.vehicle.check-in.truck-not")}
        </p>
      );
    }
    return null;
  };

  return (
    <div className="page-mask page-mask-animation">
      <div className="blank-card active-confirm">
        <h4>{t("buttons.confirm")}</h4>
        <p className="gray mb-4">
          {t("forms.type.vehicle.check-in.review")}{" "}
          {type === "check in"
            ? t("page-headers.check-in").toLowerCase()
            : t("page-headers.check-out").toLowerCase()}
        </p>
        <div>
          {truck && (
            <p>
              {t("yard-tables.table-columns.truck")}: {truck}
            </p>
          )}
          {trailer && (
            <p>
              {t("yard-tables.tabs.trailer")}: {trailer}
            </p>
          )}
        </div>
        <div className="d-flex gap-2 justify-content-center align-items-center full-cont">
          {requestLoading ? (
            <Spinner />
          ) : (
            <>
              {renderSubmitButton()}
              <button
                id="back-btn"
                onClick={close}
                className="cancel-btn"
              >
                {t("buttons.back")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
