import { useTranslation } from "react-i18next";

// Custom hook for transforming data from API with different formats
export function useTransformData() {
  const { t } = useTranslation();

  const TransformEventData = (rawData) => {
    const transformedData = rawData.map((item) => {
      const newItem = {
        ...item,
        trailer: item.trailer
          ? {
              ...item.trailer,
              loaded: item.trailer.is_loaded
                ? t("forms.type.vehicle.placeholders.loaded")
                : t("forms.type.vehicle.placeholders.empty"),
              // Join the reservation user's name
              reservation: item.trailer.reservation
                ? {
                    id: item.trailer.reservation.id,
                    userName: item.trailer.reservation
                      ? `${item.trailer.reservation.user.first_name} ${item.trailer.reservation.user.last_name}`
                      : t("forms.type.vehicle.placeholders.none"),
                  }
                : null,
            }
          : null,
        user: {
          id: item.user.id,
          userName: `${item.user.first_name} ${item.user.last_name}`,
        },
        truck: item.truck
          ? {
              ...item.truck,
              // Join truck name with carrier and truck number
              truckName: `${item.truck.carrier_name}-${item.truck.truck_number}`,
            }
          : null,
        visitor: {
          id: item.visitor.id,
          driverName: `${item.visitor.first_name} ${item.visitor.last_name}`,
        },
      };

      return newItem;
    });
    return transformedData;
  };

  const TransformAssetData = (rawData) => {
    const transformedData = rawData.map((item) => {
      const newItem = {
        ...item,
        id: item.uuid,
        location: {
          ...item.location,
          loc_name: item.location
            ? item.location.loc_name
            : t("forms.type.vehicle.placeholders.none"),
        },
        license_plate: item.license_plate,
      };
      return newItem;
    });
    return transformedData;
  };

  const TransformTruckAssetData = (rawData) => {
    const transformedData = rawData.map((item) => {
      const newItem = {
        ...item,
        id: item.uuid,
        location: {
          ...item.location,
          loc_name: item.location
            ? item.location.loc_name
            : t("forms.type.vehicle.placeholders.none"),
        },
        unit: `${item.company}-${item.name}`,
      };
      return newItem;
    });
    return transformedData;
  };
  return { TransformEventData, TransformAssetData, TransformTruckAssetData };
}
