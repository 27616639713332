import { useTranslation } from "react-i18next";

export const GetVehicleCondition = ({ condition }) => {
  const { t } = useTranslation();
  return condition === "Good" ? (
    <span className="success">
      {t("forms.type.vehicle.placeholders.select-condition.good")}
    </span>
  ) : condition === "Okay" ? (
    <span className="warning">Okay</span>
  ) : condition === "Poor" ? (
    <span className="poor">
      {t("forms.type.vehicle.placeholders.select-condition.poor")}
    </span>
  ) : condition === "Critical" ? (
    <span className="critical">
      {t("forms.type.vehicle.placeholders.select-condition.critical")}
    </span>
  ) : condition === "Under Repair" ? (
    <span className="warning">
      {t("forms.type.vehicle.placeholders.select-condition.under-repair")}
    </span>
  ) : condition === "Waiting parts" ? (
    <span className="warning">
      {t("forms.type.vehicle.placeholders.select-condition.spare-parts")}
    </span>
  ) : condition === "Waiting instructions" ? (
    <span className="warning">
      {t("forms.type.vehicle.placeholders.select-condition.instructions")}
    </span>
  ) : condition === "External repair" ? (
    <span className="Warning">
      {t("forms.type.vehicle.placeholders.select-condition.external-repair")}
    </span>
  ) : null;
};