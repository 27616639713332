import { useEffect, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";

export default function QRScanner({ onScanSuccess }) {
  const qrRegionId = "reader";
  const qrCodeScannerRef = useRef(null);
  const scannerRunning = useRef(false);

  useEffect(() => {
    const html5QrCode = new Html5Qrcode(qrRegionId);
    qrCodeScannerRef.current = html5QrCode;

    html5QrCode
      .start(
        { facingMode: "environment" },
        { fps: 10, qrbox: { width: 250, height: 250 } },
        (decodedText) => {
          onScanSuccess(decodedText);
          if (scannerRunning.current) {
            html5QrCode.stop().catch((err) => console.error("Stop failed", err));
            scannerRunning.current = false;
          }
        },
        (errorMessage) => {
          // Ignore scan errors or log them
        }
      )
      .then(() => {
        scannerRunning.current = true;
      })
      .catch((err) => {
        console.error("Scanner start failed:", err);
      });

    return () => {
      if (scannerRunning.current && qrCodeScannerRef.current) {
        qrCodeScannerRef.current
          .stop()
          .then(() => {
            scannerRunning.current = false;
          })
          .catch((err) => console.warn("Stop error on unmount:", err));
      }
    };
  }, [onScanSuccess]);

  return <div id={qrRegionId} style={{ width: "100%" }} />;
}
