import { useTranslation } from "react-i18next";
import { useAuth } from "../../Auth/AuthProvider";
import React, { Suspense, useMemo } from "react";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";
import VisitorsInYard from "./VisitorsInYard";
import ReservationsGraphic from "./ReservationsGraphic";
import Truck34Hour from "../Graphics/Truck34Hour";
import RecentActivityGraphic from "../Graphics/RecentActivityGraphic";
import TodayCapacityGraphic from "../Graphics/TodayCapacityGraphic";

const OverviewGraphics = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const graphics = [
    {
      roles: ["Admin", "Dispatch"],
      graphic: <TodayCapacityGraphic/>,
    },
    {
      roles: ["Admin", "Dispatch"],
      graphic: <ReservationsGraphic/>,
    },
    {
      roles: ["Admin", "Dispatch"],
      graphic: <Truck34Hour/>,
    },
    {
      roles: ["Guard", "Guard Supervisor", "Safety"],
      graphic: <RecentActivityGraphic/>,
    },
  ];

  return (
    <div className="graphics-cont">
      {graphics.map((graphic, index) => {
        if (user.groups.some((group) => graphic.roles.includes(group.name))) {
          const GraphicComponent = graphic.graphic;
          return (
            <Suspense key={index} fallback={<Spinner />}>
              {React.cloneElement(GraphicComponent)}
            </Suspense>
          );
        }
      })}
      <VisitorsInYard driverFilter={false} />
      <VisitorsInYard driverFilter={true} />
    </div>
  );
};

export default OverviewGraphics;
