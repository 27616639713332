import { useEffect, useState } from "react";
import instance from "../../../Interceptor/api_instance";
import Select from "react-select";
import { mexicanStates, usStates } from "../../../Helper/CountryStates";
import { useTranslation } from "react-i18next";
import { ScannerIcon } from "../../../Media/Icons";
import { useAuth } from "../../../Auth/AuthProvider";
import QRScanner from "../../Utility/QRScanner";

export const CheckInOutForm = ({
  formState,
  dispatchForm,
  truckBody,
  setTruckBody,
  trailerBody,
  setTrailerBody,
  close,
  nextForm,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const mechanicOptions =
    Array.isArray(user.groups) &&
    user.groups.some(
      (group) => group.name === "Admin" || group.name === "Safety"
    );
  const [filteredCustomers, setFilteredCustomers] = useState(null);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  // Updates state for any change on truck form groups
  const handleTruckUpdate = (key, value) => {
    setTruckBody((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleTrailerUpdate = (key, value) => {
    setTrailerBody((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const fetchCustomers = async () => {
    try {
      const response = await instance.get("assets/customers");
      dispatchForm({ type: "STORE_CUSTOMERS", payload: response.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDriverClick = (driver) => {
    setFilteredDrivers([]);
    handleTruckUpdate(
      "driver_name",
      `${driver.first_name} ${driver.last_name}`
    );
  };

  const handleDriverList = (e) => {
    const value = e.target.value;
    handleTruckUpdate("driver_name", value);
    if (value) {
      const filteredSuggestions = formState.driverList.filter((driver) => {
        const driverName = `${driver.first_name} ${driver.last_name}`;
        return driverName.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredDrivers(filteredSuggestions);
    } else {
      setFilteredDrivers([]);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    handleTrailerUpdate("customer", value);

    if (value) {
      const filteredSuggestions = formState.customerList.filter((customer) =>
        customer.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCustomers(filteredSuggestions);
    } else {
      setFilteredCustomers(null);
    }
  };

  const handleCustomerClick = (name) => {
    setFilteredCustomers(null);
    handleTrailerUpdate("customer", name);
  };

  useEffect(() => {
    if (trailerBody.is_loaded && formState.customerList.length === 0) {
      fetchCustomers();
    }
  }, [trailerBody.is_loaded]);

  const atiTruckList = formState.atiTruckList.map((truck) => ({
    // Format list for ATI trucks for Select React component
    ...truck,
    value: truck.id,
    label: `${truck.name} ${truck.location?.loc_name || ""}`, // Label displays option in Select dropdown
    isDisabled: truck.location ? true : false,
  }));

  const jfgTruckList = formState.jfgTruckList.map((truck) => ({
    // Format list for JFG trucks for Select React component
    ...truck,
    value: truck.id,
    label: `${truck.name} ${truck.location?.loc_name || ""}`, // Label displays option in Select dropdown
    isDisabled: truck.location ? true : false,
  }));

  const trailerList = formState.trailerList.map((trailer) => ({
    ...trailer,
    value: trailer.id,
    label: `${trailer.unit} ${trailer.location?.loc_name || ""}`,
    isDisabled: trailer.location ? true : false, // Label displays option in Select dropdown
  }));

  const truckList = // Assign the truck list to be rendered
    formState.selectedCarrier === "ATI"
      ? atiTruckList
      : formState.selectedCarrier === "JFG"
      ? jfgTruckList
      : [];

  const validateUrl = (url) => {
    const apiBaseUrl = `${process.env.REACT_APP_API_URL}assets/`;
    if (url.startsWith(apiBaseUrl)) {
      return true;
    } else {
      console.error("Invalid QR Code. Not from allowed domain:", url);
      return false;
    }
  };

  const handleTrailerScan = async (scan) => {
    const url = scan;
    if (!url || !validateUrl(url)) return;
    try {
      const response = await instance.get(url);
      if (response.status === 200) {
        const data = response.data;
        const selectedTrailer = trailerList.find(
          (trailer) => trailer.id === data.id
        );

        // Error-handle scanned trailer
        if (selectedTrailer.location) {
          throw new Error(
            `${selectedTrailer.unit}: ${t(
              "forms.responses.qr-scans.trailer-in"
            )}`
          );
        } else if (!selectedTrailer) {
          throw new Error(
            `${selectedTrailer.unit}: ${t(
              "forms.responses.qr-scans.not-found"
            )}`
          );
        }

        // Update trailer number
        handleTrailerUpdate("trailer_number", selectedTrailer.id);
        handleTrailerUpdate("license_plate", selectedTrailer.license_plate);
        dispatchForm({
          type: "ERROR_MESSAGE",
          key: "trailerErrorMessage",
          payload: null,
        });
      }
    } catch (error) {
      console.error(error);

      // Ensure error is properly formatted as a string
      dispatchForm({
        type: "ERROR_MESSAGE",
        key: "trailerErrorMessage",
        payload: error.message || error,
      });
    }
  };

  const handleTruckScan = async (scan) => {
    const url = scan;
    if (!url || !validateUrl(url)) return;

    try {
      const response = await instance.get(url);
      if (response.status === 200) {
        const data = response.data;
        let selectedTruck = {};

        // Find scanned truck in lists
        if (data.company === "ATI") {
          selectedTruck = atiTruckList.find((truck) => truck.id === data.id);
        } else if (data.company === "JFG") {
          selectedTruck = jfgTruckList.find((truck) => truck.id === data.id);
        }

        // Error handle scanned truck
        if (selectedTruck.location) {
          throw new Error(
            `${selectedTruck.company}-${selectedTruck.name}: ${t(
              "forms.responses.qr-scans.truck-in"
            )}`
          );
        } else if (!selectedTruck) {
          throw new Error(
            `${selectedTruck.label}: ${t("forms.responses.qr-scans.not-found")}`
          );
        }

        // Update all states if no errors
        dispatchForm({
          type: "SELECT_CARRIER",
          payload: selectedTruck.company,
        });
        handleTruckUpdate("carrier", selectedTruck.company);
        handleTruckUpdate("truck_number", selectedTruck.id);
        handleTruckUpdate("license_plate", selectedTruck.license_plate);
        dispatchForm({
          type: "ERROR_MESSAGE",
          key: "truckErrorMessage",
          value: null,
        });
      }
    } catch (error) {
      console.error(error);

      // Ensure error is properly formatted as a string
      dispatchForm({
        type: "ERROR_MESSAGE",
        key: "truckErrorMessage",
        payload: error.message || error,
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%", // Make sure control is full width
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto", // Override to handle padding
    }),
    // You might want to adjust the width of the menu to match the control
    container: (provided) => ({
      ...provided,
      width: "100%", // Ensure the dropdown menu is also full width
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "gray" : provided.color,
      borderBottom: "0.5px solid gray",
      padding: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };

  return (
    <div className="questions-cont">
      <h2 className="global-h2 light-black">
        {t("forms.type.vehicle.check-in.header")}
      </h2>

      <form onSubmit={nextForm}>
        {/* Form group: Is truck hauling trailer */}
        <div className="form-group">
          <label className="input-label required">
            {t("forms.type.vehicle.input-headers.carrying-bool")}{" "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  name="group1"
                  className="radio-input"
                  checked={formState.isCarrying}
                  onChange={() =>
                    dispatchForm({
                      type: "IS_CARRYING",
                      payload: true,
                    })
                  }
                  required
                />
                <label>{t("general.yes")}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="group1"
                  className="radio-input"
                  checked={formState.isCarrying === false}
                  onChange={() => {
                    dispatchForm({ type: "IS_CARRYING", payload: false });
                    dispatchForm({ type: "IS_TRAILER_ATI", payload: null });
                    handleTrailerUpdate("trailer_number", null);
                  }}
                />
                <label>No</label>
              </div>
            </fieldset>
          </div>
        </div>

        {/* Form group: Is this trailer ATI */}
        {formState.isCarrying && (
          <div className="form-group">
            <label className="input-label required">
              {t("forms.type.vehicle.input-headers.ati-bool")}{" "}
            </label>
            <div className="input-cont">
              <fieldset className="fieldsets">
                <div className="radio-options">
                  <input
                    type="radio"
                    name="group2"
                    className="radio-input"
                    checked={formState.isTrailerAti}
                    onChange={() => {
                      dispatchForm({ type: "IS_TRAILER_ATI", payload: true });
                      handleTrailerUpdate("license_plate", "");
                      handleTrailerUpdate("trailer_number", null);
                    }}
                    required
                  />
                  <label>{t("general.yes")}</label>
                </div>
                <div className="radio-options">
                  <input
                    type="radio"
                    id="no1"
                    name="group2"
                    className="radio-input"
                    checked={formState.isTrailerAti === false}
                    onChange={() => {
                      dispatchForm({ type: "IS_TRAILER_ATI", payload: false });
                      handleTrailerUpdate("license_plate", "");
                      handleTrailerUpdate("trailer_number", null);
                    }}
                  />
                  <label>No</label>
                </div>
              </fieldset>
            </div>
          </div>
        )}

        {/* Form group: Select carrier  */}
        <div className="form-check-in">
          <div className="form-group">
            <label className="input-label required">
              {t("yard-tables.table-columns.carrier")}
            </label>

            {/* First row for dropdown, other text input, and scanner icon */}
            <div className="input-cont-block input-cont-message">
              <div className="input-cont input-cont-margin-bottom">
                <select
                  id="carrier"
                  value={formState.selectedCarrier}
                  onChange={(e) => {
                    dispatchForm({
                      type: "SELECT_CARRIER",
                      payload: e.target.value,
                    });
                    if (e.target.value !== "Other") {
                      handleTruckUpdate("carrier", e.target.value);
                      handleTruckUpdate("odometer", null);
                    } else {
                      handleTruckUpdate("carrier", null);
                      handleTruckUpdate("odometer", 0);
                    }
                  }}
                  required
                >
                  <option value="" disabled>
                    {t("forms.type.vehicle.placeholders.select-carrier")}
                  </option>
                  <option value="JFG">JFG</option>
                  <option value="ATI">ATI</option>
                  <option value="Other">
                    {t("forms.type.vehicle.placeholders.other")}
                  </option>
                </select>

                {/* Conditionally render other carrier input */}
                {formState.selectedCarrier === "Other" && (
                  <input
                    id="other-carrier"
                    type="text"
                    className="text-input"
                    value={truckBody.carrier}
                    placeholder={t(
                      "forms.type.vehicle.placeholders.other-carrier"
                    )}
                    onChange={(e) =>
                      handleTruckUpdate("carrier", e.target.value)
                    }
                    required
                  />
                )}
                <ScannerIcon
                  setScanner={() =>
                    dispatchForm({
                      type: "IS_TRUCK_SCANNING",
                      payload: !formState.truckCodeScanner,
                    })
                  }
                />
              </div>

              {formState.truckCodeScanner && (
                <div className="input-cont-margin-bottom">
                  <QRScanner
                    onScanSuccess={(scan) => {
                      if (scan) {
                        handleTruckScan(scan);
                        dispatchForm({
                          type: "IS_TRUCK_SCANNING",
                          payload: false,
                        });
                      }
                    }}
                    constraints={{ facingMode: "environment" }}
                  />
                </div>
              )}
              {formState.truckErrorMessage && (
                <p className="error">{formState.truckErrorMessage}</p>
              )}
            </div>
          </div>

          {/* Form group: Truck number */}
          <div className="form-group">
            <label className="input-label required">
              {t("forms.type.vehicle.input-headers.truck-number")}{" "}
            </label>
            {formState.selectedCarrier && (
              <>
                {formState.selectedCarrier !== "Other" ? (
                  <div className="input-cont input-cont-message">
                    {/* Dropdown for truck list based on carrier */}
                    <div className="input-cont-margin-bottom">
                      <Select
                        value={truckList.find(
                          (option) => option.value === truckBody.truck_number
                        )}
                        onChange={(option) => {
                          handleTruckUpdate("truck_number", option?.id);
                          handleTruckUpdate("label", option?.label);
                          handleTruckUpdate(
                            "license_plate",
                            option?.license_plate
                          );
                          dispatchForm({
                            type: "ERROR_MESSAGE",
                            key: "truckErrorMessage",
                            value: null,
                          });
                        }}
                        options={truckList}
                        isClearable={true} // Allows users to clear the selected value
                        isSearchable={true} // Allows users to search through the dropdown
                        styles={customStyles}
                        unstyled={true}
                        isLoading={truckList.length === 0}
                        loadingMessage={() => t("general.loading")}
                        required
                      />
                    </div>
                  </div>
                ) : (
                  <div className="input-cont">
                    <input
                      className="text-input"
                      type="text"
                      id="truck-num"
                      onChange={(e) =>
                        handleTruckUpdate("truck_number", e.target.value)
                      }
                      value={truckBody.truck_number}
                      required
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* Form group: Truck license plate */}
        <div className="form-group">
          <label className="input-label required">
            {t("forms.type.vehicle.input-headers.truck-plate")}
          </label>{" "}
          <div className="input-cont">
            <input
              value={truckBody.license_plate}
              type="text"
              id="truckPlates"
              className="text-input"
              onChange={(e) =>
                handleTruckUpdate("license_plate", e.target.value)
              }
              required
            />
          </div>
        </div>

        {/* Form group: Odometer */}
        <div className="form-group">
          <label
            className={`input-label ${
              formState.selectedCarrier !== "Other" ? "required" : ""
            }`}
          >
            {t("forms.type.vehicle.input-headers.odometer")}
          </label>
          <div className="input-cont">
            <input
              type="number"
              id="odometer"
              inputMode="numeric"
              min={0}
              className="text-input"
              placeholder={t("forms.type.vehicle.placeholders.enter-miles")}
              value={
                formState.selectedCarrier !== "Other" ? truckBody.odometer : (truckBody.odometer || 0)
              }
              onChange={(e) => {
                handleTruckUpdate("odometer", e.target.value);
              }}
              required
            />
          </div>
        </div>

        {/* Form group: Truck condition */}
        <div className="form-group">
          <label className="input-label required">
            {t("forms.type.vehicle.input-headers.truck_condition")}
          </label>
          <div className="input-cont">
            <div>
              <select
                id="conditionSelect"
                onChange={(e) =>
                  handleTruckUpdate("truck_condition", e.target.value)
                }
                value={truckBody.truck_condition}
                required
              >
                <option value={""} selected disabled>
                  {t("forms.type.vehicle.placeholders.select-condition.header")}
                </option>
                <option value="Good">
                  {t("forms.type.vehicle.placeholders.select-condition.good")}
                </option>
                <option value="Okay">Okay</option>
                <option value="Poor">
                  {t("forms.type.vehicle.placeholders.select-condition.poor")}
                </option>
                <option value="Critical">
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.critical"
                  )}
                </option>
                <option value="Under Repair">
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.under-repair"
                  )}
                </option>
                <option value="Waiting parts" disabled={!mechanicOptions}>
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.spare-parts"
                  )}
                </option>
                <option
                  value="Waiting instructions"
                  disabled={!mechanicOptions}
                >
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.instructions"
                  )}
                </option>
                <option value="External repair" disabled={!mechanicOptions}>
                  {t(
                    "forms.type.vehicle.placeholders.select-condition.external-repair"
                  )}
                </option>
              </select>
              <textarea
                type="text"
                className="text-input"
                placeholder={t("forms.type.vehicle.placeholders.notes")}
                value={truckBody.truck_condition_notes}
                onChange={(e) =>
                  handleTruckUpdate("truck_condition_notes", e.target.value)
                }
                required={
                  truckBody.truck_condition !== "Good" &&
                  truckBody.truck_condition !== "Okay"
                }
              ></textarea>
            </div>
          </div>
        </div>

        {/* Form group: Driver */}
        <div className="form-group">
          <label className="input-label required">
            {t("yard-tables.search-filters.dropdowns.driver")}{" "}
          </label>
          <div className="input-cont-search">
            <input
              type="text"
              id="driver-first"
              className={`text-input ${
                filteredDrivers.length > 0 ? "text-input-search" : ""
              }`}
              placeholder={t("forms.type.newDriver.input-headers.name")}
              onChange={handleDriverList}
              value={truckBody.driver_name}
              required
            />
            {filteredDrivers.length > 0 && (
              <ul className="search-results">
                {filteredDrivers.map((driver, index) => (
                  <li
                    key={index}
                    onClick={() => handleDriverClick(driver)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <span>
                      {driver.first_name} {driver.last_name}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* All trailer inputs */}
        {formState.isCarrying && formState.isTrailerAti !== null && (
          <>
            {/* // Form group: Trailer number */}
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.vehicle.input-headers.trailer-number")}{" "}
              </label>
              {formState.isTrailerAti ? (
                <div className="input-cont-block input-cont-message">
                  <div className="input-cont input-cont-margin-bottom">
                    <Select
                      id="carrier"
                      value={trailerList.find(
                        (trailer) => trailer.id === trailerBody.trailer_number
                      )}
                      options={trailerList}
                      onChange={(option) => {
                        handleTrailerUpdate("trailer_number", option?.id);
                        handleTrailerUpdate(
                          "license_plate",
                          option?.license_plate
                        );
                        dispatchForm({
                          type: "ERROR_MESSAGE",
                          key: "trailerErrorMessage",
                          payload: null,
                        });
                      }}
                      placeholder={t(
                        "forms.type.vehicle.placeholders.select-num"
                      )}
                      isClearable={true}
                      isSearchable={true}
                      styles={customStyles}
                      unstyled={true}
                      isLoading={trailerList.length === 0}
                      loadingMessage={() => "Loading options, please wait..."}
                      required={true}
                    />
                    <ScannerIcon
                      setScanner={() =>
                        dispatchForm({
                          type: "IS_TRAILER_SCANNING",
                          payload: !formState.trailerCodeScanner,
                        })
                      }
                    />
                  </div>
                  {formState.trailerCodeScanner && (
                    <div className="input-cont-margin-bottom">
                      <QRScanner
                        onScanSuccess={(scan) => {
                          if (scan) {
                            handleTrailerScan(scan);
                            dispatchForm({
                              type: "IS_TRAILER_SCANNING",
                              payload: false,
                            });
                          }
                        }}
                      />
                    </div>
                  )}
                  {formState.trailerErrorMessage && (
                    <p className="error">{formState.trailerErrorMessage}</p>
                  )}
                </div>
              ) : (
                <div className="input-cont">
                  <input
                    type="text"
                    placeholder={t("forms.type.vehicle.placeholders.num")}
                    onChange={(e) =>
                      handleTrailerUpdate("trailer_number", e.target.value)
                    }
                    value={trailerBody.trailer_number}
                    className="text-input"
                    required
                  />
                </div>
              )}
            </div>

            {/* Form group: Trailer license plate */}
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.vehicle.input-headers.trailer-plate")}
              </label>
              <div className="input-cont">
                <input
                  id="trailerPlate"
                  type="text"
                  placeholder={t(
                    "forms.type.vehicle.placeholders.license-plate"
                  )}
                  className="text-input"
                  onChange={(e) =>
                    handleTrailerUpdate("license_plate", e.target.value)
                  }
                  value={trailerBody.license_plate}
                  required
                />
              </div>
            </div>

            {/* Form group: Trailer dimensions */}
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.vehicle.input-headers.trailer-dim")}
              </label>
              <div className="input-cont">
                <fieldset className="fieldsets">
                  <div className="radio-options">
                    <input
                      id="dimension1"
                      type="radio"
                      name="dimension"
                      className="radio-input"
                      checked={formState.trailerDimension === 1}
                      onChange={() => {
                        dispatchForm({
                          type: "SET_TRAILER_DIMENSION",
                          payload: 1,
                        });
                      }}
                      required
                    />
                    <label>53'</label>
                  </div>
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="dimension"
                      className="radio-input"
                      checked={formState.trailerDimension === 2}
                      onChange={() =>
                        dispatchForm({
                          type: "SET_TRAILER_DIMENSION",
                          payload: 2,
                        })
                      }
                    />
                    <label>48'</label>
                  </div>
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="dimension"
                      className="radio-input"
                      checked={formState.trailerDimension === 3}
                      onChange={() =>
                        dispatchForm({
                          type: "SET_TRAILER_DIMENSION",
                          payload: 3,
                        })
                      }
                    />
                    <label>40'</label>
                  </div>
                  <div className="radio-options">
                    <input
                      type="radio"
                      name="dimension"
                      className="radio-input"
                      checked={formState.trailerDimension === 4}
                      onChange={() =>
                        dispatchForm({
                          type: "SET_TRAILER_DIMENSION",
                          payload: 4,
                        })
                      }
                    />
                    <label>{t("forms.type.vehicle.placeholders.other")}</label>
                  </div>
                </fieldset>
              </div>
            </div>

            {/* Form group: Custom dimensions */}
            {formState.trailerDimension === 4 && (
              <div className="form-group">
                <label className="input-label required">
                  {t("forms.type.vehicle.input-headers.other-dimensions")}
                </label>
                <div className="input-cont">
                  <input
                    type="number"
                    step="0.01"
                    inputMode="numeric"
                    min={0}
                    className="text-input"
                    value={trailerBody.length || 0}
                    onChange={(e) =>
                      handleTrailerUpdate("length", e.target.value)
                    }
                    placeholder={t("forms.type.vehicle.placeholders.length")}
                  />
                  <input
                    type="number"
                    step="0.01"
                    inputMode="numeric"
                    min={0}
                    className="text-input"
                    value={trailerBody.width || 0}
                    onChange={(e) =>
                      handleTrailerUpdate("width", e.target.value)
                    }
                    placeholder={t("forms.type.vehicle.placeholders.width")}
                  />
                  <input
                    type="number"
                    inputMode="numeric"
                    step="0.01"
                    min={0}
                    className="text-input"
                    value={trailerBody.height || 0}
                    onChange={(e) =>
                      handleTrailerUpdate("height", e.target.value)
                    }
                    placeholder={t("forms.type.vehicle.placeholders.height")}
                  />
                </div>
              </div>
            )}

            {/* Form group: Is loaded */}
            <div className="form-group">
              <label className="input-label required">
                {t("yard-tables.search-filters.dropdowns.contents")}
              </label>
              <div className="input-cont">
                <select
                  id="contentSelect"
                  onChange={(e) =>
                    handleTrailerUpdate("is_loaded", e.target.value)
                  }
                  value={trailerBody.is_loaded}
                  required
                >
                  <option value="" disabled selected>
                    {t("forms.type.vehicle.placeholders.select-content")}
                  </option>
                  <option value={true}>
                    {t("forms.type.vehicle.placeholders.loaded")}
                  </option>
                  <option value={false}>
                    {t("forms.type.vehicle.placeholders.empty")}
                  </option>
                </select>
              </div>
            </div>
            {trailerBody.is_loaded === "true" && (
              // Form group: Customer
              <div className="form-group">
                <label className="input-label required">
                  {t("yard-tables.search-filters.dropdowns.customer")}
                </label>
                <div className="input-cont">
                  <div className="search-input">
                    <input
                      id="searchCustomer"
                      type="text"
                      className="text-input"
                      placeholder={t(
                        "forms.type.vehicle.placeholders.search-customer"
                      )}
                      value={trailerBody.customer}
                      onChange={handleInputChange}
                      required
                    />
                    {filteredCustomers?.length > 0 && (
                      <ul
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          width: "100%",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          maxHeight: "150px",
                          overflowY: "auto",
                          backgroundColor: "white",
                          listStyleType: "none",
                          padding: 0,
                          margin: 0,
                          zIndex: 1000,
                        }}
                      >
                        {filteredCustomers.map((customer, index) => (
                          <li
                            key={index}
                            onClick={() => handleCustomerClick(customer.name)}
                            style={{
                              padding: "8px",
                              cursor: "pointer",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            {customer.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Form group: Seal */}
            <div className="form-group">
              <label className="input-label">
                {t("forms.type.vehicle.placeholders.seal")}
              </label>
              <div className="input-cont">
                <input
                  id="sealNum"
                  onChange={(e) =>
                    handleTrailerUpdate("seal_number", e.target.value)
                  }
                  value={trailerBody.seal_number}
                  className="text-input"
                  placeholder={t("forms.type.vehicle.placeholders.num")}
                />
              </div>
            </div>

            {/* Form group: Trailer condition */}
            <div className="form-group">
              <label className="input-label required">
                {t("forms.type.vehicle.input-headers.trailer_condition")}
              </label>
              <div className="input-cont">
                <div>
                  <select
                    id="conditionSelect"
                    onChange={(e) =>
                      handleTrailerUpdate("trailer_condition", e.target.value)
                    }
                    value={trailerBody.trailer_condition}
                    required
                  >
                    <option value="" selected disabled>
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.header"
                      )}
                    </option>
                    <option value="Good">
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.good"
                      )}
                    </option>
                    <option value="Okay">Okay</option>
                    <option value="Poor">
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.poor"
                      )}
                    </option>
                    <option value="Critical">
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.critical"
                      )}
                    </option>
                    <option value="Under Repair">
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.under-repair"
                      )}
                    </option>
                    <option value="Waiting parts" disabled={!mechanicOptions}>
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.spare-parts"
                      )}
                    </option>
                    <option
                      value="Waiting instructions"
                      disabled={!mechanicOptions}
                    >
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.instructions"
                      )}
                    </option>
                    <option value="External repair" disabled={!mechanicOptions}>
                      {t(
                        "forms.type.vehicle.placeholders.select-condition.external-repair"
                      )}
                    </option>
                  </select>
                  <textarea
                    type="text"
                    className="text-input"
                    placeholder={t("forms.type.vehicle.placeholders.notes")}
                    value={
                      trailerBody === "null"
                        ? ""
                        : trailerBody.trailer_condition_notes
                    }
                    onChange={(e) =>
                      handleTrailerUpdate(
                        "trailer_condition_notes",
                        e.target.value
                      )
                    }
                    required={
                      trailerBody.trailer_condition !== "Good" &&
                      trailerBody.trailer_condition !== "Okay"
                        ? true
                        : false
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Form group: Origin */}
        <>
          <div className="form-group">
            <label className="input-label required">
              {t("forms.type.vehicle.input-headers.origin")}
            </label>
            <div className="input-cont">
              <select
                id="country"
                onChange={(e) => handleTruckUpdate("country", e.target.value)}
                value={truckBody.country}
                required
              >
                <option value="" selected disabled>
                  {t("forms.type.vehicle.placeholders.destination.country")}
                </option>
                <option value="USA">
                  {t("forms.type.vehicle.placeholders.usa")}
                </option>
                <option value="Mexico">Mexico</option>
              </select>
              <select
                id="state"
                value={truckBody.countryState}
                onChange={(e) =>
                  handleTruckUpdate("countryState", e.target.value)
                }
                required
              >
                <option value="">
                  {t("forms.type.vehicle.placeholders.destination.state")}
                </option>
                {truckBody.country === "USA"
                  ? usStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))
                  : mexicanStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="input-label"></label>
            <div className="input-cont">
              <input
                required
                id="city"
                type="text"
                className="text-input"
                placeholder={t(
                  "forms.type.vehicle.placeholders.destination.City"
                )}
                value={truckBody.city}
                onChange={(e) => handleTruckUpdate("city", e.target.value)}
              />
            </div>
          </div>
        </>

        {/* Form group: Notes */}
        <div className="form-group">
          <label className="input-label">
            {t("forms.type.vehicle.input-headers.notes")}
          </label>
          <div className="input-cont">
            <input
              className="text-input"
              placeholder={t("forms.type.vehicle.placeholders.notes")}
              type="text"
              value={truckBody.generalNotes}
              onChange={(e) =>
                handleTruckUpdate("generalNotes", e.target.value)
              }
            />
          </div>
        </div>

        {/* Buttons cont */}
        <div className="full-cont">
          <button className="change-pwd-btn" id="continue-btn" type="submit">
            {t("buttons.continue")}
          </button>
          <button className="cancel-btn" onClick={close}>
            {t("buttons.cancel")}
          </button>
        </div>
      </form>
    </div>
  );
};
