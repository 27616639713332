import { useTranslation } from "react-i18next";
import { useState, useEffect, useReducer } from "react";
import instance from "../../Interceptor/api_instance";
import { PageLayout } from "../PageLayout";
import { VehicleTabs } from "../Tables/Vehicles/Tabs";
import { TableNavigation } from "../Tables/Vehicles/TableNavigation";
import { useTransformData } from "../Tables/Transform/TransformData";
import {
  backlogTrailerHeaders,
  backlogTruckHeaders,
  backlogVisitorHeaders,
} from "../Tables/Headers";
import { TablePlaceHolder } from "../LoadingAnimations/LoadingAnimations";
import { useAuth } from "../../Auth/AuthProvider";
import { TableConst } from "../Tables/Vehicles/TableConst";
import { VehicleFilters } from "../Tables/Vehicles/Filters";

const initialState = {
  currentPage: null,
  prevPage: null,
  nextPage: null,
  currentPageNum: 1,
  isLoading: true,
  data: [],
  selectedRow: null,
  advancedSearch: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.page,
        currentPageNum: action.pageNum,
      };
    case "FETCH_DATA":
      return {
        ...state,
        isLoading: true,
        data: [],
      };
    case "STORE_DATA":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        nextPage: action.next,
        prevPage: action.previous,
      };
    case "SET_SELECTED_ROW":
      return {
        ...state,
        selectedRow: action.selectedRow,
      };
    case "CLOSE_SELECTED_ROW":
      return {
        ...state,
        selectedRow: null,
      };
    case "OPEN_ADVANCED_SEARCH":
      return {
        ...state,
        advancedSearch: true,
      };
    case "CLOSE_ADVANCED_SEARCH":
      return {
        ...state,
        advancedSearch: false,
      };
    default:
      return state;
  }
};

export default function BackLog() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const tabs = ["Trailers", "Trucks", "Visitors"];
  const [selectedTab, setSelectedTab] = useState("Trailers");

  const [trailerState, trailerDispatch] = useReducer(reducer, {
    ...initialState,
    currentPage: "assets/backlog/trailers",
  });

  const [truckState, truckDispatch] = useReducer(reducer, {
    ...initialState,
    currentPage: "assets/backlog/trucks",
  });
  const [visitorState, visitorDispatch] = useReducer(reducer, {
    ...initialState,
    currentPage: "assets/backlog/visitors",
  });
  const { TransformEventData } = useTransformData();

  useEffect(() => {
    if (selectedTab === "Trailers") {
      fetchData(trailerState, trailerDispatch);
    } else if (selectedTab === "Trucks") {
      fetchData(truckState, truckDispatch);
    } else if (selectedTab === "Visitors") {
      fetchData(visitorState, visitorDispatch);
    }
  }, [
    selectedTab,
    trailerState.currentPage,
    truckState.currentPage,
    visitorState.currentPage,
  ]); // Depend only on selectedTab

  const fetchData = async (state, dispatch) => {
    try {
      dispatch({ type: "FETCH_DATA" });
      const data = await instance.get(state.currentPage);
      if (data.status === 200) {
        dispatch({
          type: "STORE_DATA",
          payload: TransformEventData(data.data.results),
          next: data.data.next,
          previous: data.data.previous,
        });
      }
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const handleAdvancedSearch = (filters, type) => {
    const queryString = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null) {
        // Exclude null values
        queryString.append(key, value);
      }
    });
    if (type === "trailers") {
      const url = `assets/backlog/trailers?${queryString.toString()}`;
      trailerDispatch({ type: "SET_CURRENT_PAGE", page: url, pageNum: 1 });
      trailerDispatch({type: "CLOSE_ADVANCED_SEARCH"})
    } else if (type === "trucks") {
      const url = `assets/backlog/trucks?${queryString.toString()}`;
      truckDispatch({ type: "SET_CURRENT_PAGE", page: url, pageNum: 1 });
      truckDispatch({type: "CLOSE_ADVANCED_SEARCH"})
    } else if (type === "visitors") {
      const url = `assets/backlog/visitors?${queryString.toString()}`;
      visitorDispatch({ type: "SET_CURRENT_PAGE", page: url, pageNum: 1 });
      visitorDispatch({type: "CLOSE_ADVANCED_SEARCH"})
    }
  };

  return (
    <PageLayout title={"Backlog"}>
      <div className="inventory-header">
        <h2 className="global-h1 light-black">{t("page-headers.backlog")}</h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <VehicleTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            paddingRight: "16px",
            minHeight: "50px",
          }}
          className="page-select"
        >
          {selectedTab === "Trailers" ? (
            <TableNavigation
              pageNum={trailerState.currentPageNum}
              state={trailerState}
              dispatch={trailerDispatch}
            />
          ) : selectedTab === "Trucks" ? (
            <TableNavigation
              pageNum={truckState.currentPageNum}
              state={truckState}
              dispatch={truckDispatch}
            />
          ) : selectedTab === "Visitors" ? (
            <TableNavigation
              pageNum={visitorState.currentPageNum}
              state={visitorState}
              dispatch={visitorDispatch}
            />
          ) : null}
        </div>
      </div>
      {selectedTab === "Trailers" && !trailerState.isLoading ? (
        <TableConst
          key="trailers"
          headers={backlogTrailerHeaders(t)}
          state={trailerState}
          dispatch={trailerDispatch}
          type={"trailers"}
          advancedSearch={true}
          handleAdvancedSearch={handleAdvancedSearch}
          filters={VehicleFilters("trailers", trailerState.data, user, t)}
        />
      ) : selectedTab === "Trucks" && !truckState.isLoading ? (
        <TableConst
          key="trucks"
          headers={backlogTruckHeaders(t)}
          dispatch={truckDispatch}
          state={truckState}
          type={"trucks"}
          advancedSearch={true}
          handleAdvancedSearch={handleAdvancedSearch}
          filters={VehicleFilters("trucks", truckState.data, user, t)}
        />
      ) : selectedTab === "Visitors" && !visitorState.isLoading ? (
        <TableConst
          key="visitors"
          headers={backlogVisitorHeaders(t)}
          dispatch={visitorDispatch}
          state={visitorState}
          type={"visitors"}
          advancedSearch={true}
          handleAdvancedSearch={handleAdvancedSearch}
        />
      ) : (
        <TablePlaceHolder />
      )}
    </PageLayout>
  );
}
