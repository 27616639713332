import { LeftArrowNav, RightArrowNav } from "../../../Media/Icons";

export const TableNavigation = ({
  pageNum,
  state,
  dispatch,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "16px",
        paddingRight: "16px",
        minHeight: "50px",
      }}
      className="page-select"
    >
      {state.prevPage && (
        <LeftArrowNav
          clickFunction={() =>
            dispatch({
              type: "SET_CURRENT_PAGE",
              page: state.prevPage,
              pageNum: state.currentPageNum - 1,
            })
          }
        />
      )}
      <span>{pageNum}</span>
      {state.nextPage && (
        <RightArrowNav
          clickFunction={() =>
            dispatch({
              type: "SET_CURRENT_PAGE",
              page: state.nextPage,
              pageNum: state.currentPageNum + 1,
            })
          }
        />
      )}
    </div>
  );
};
