import { useState } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../Interceptor/api_instance";
import { ResponseModal } from "./EditCondition";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";

export const EditTrailerAsset = ({ trailer, close }) => {
  const { t } = useTranslation();
  const [editedTrailer, setEditedTrailer] = useState(trailer);
  const [responseModal, setResponseModal] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);

  const editTrailer = (key, value) => {
    setEditedTrailer((prevTrailer) => ({
      ...prevTrailer,
      [key]: value,
    }));
  };

  const submit = async (e) => {
    e.preventDefault();
    setRequestLoading(true);
    try {
      const response = await instance.patch(
        "assets/edit-trailer-asset",
        editedTrailer
      );
      if (response.status === 200) {
        setResponseModal({
          message: t("forms.responses.success"),
          status: t("forms.type.success"),
          close: () => window.location.reload(),
        });
      }
    } catch (error) {
      setResponseModal({
        message: error,
        status: "Error",
        close: () => setResponseModal(null),
      });
      console.log(error);
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <>
      {responseModal && (
        <ResponseModal
          status={responseModal.status}
          message={responseModal.message}
          close={responseModal.close}
        />
      )}{" "}
      <div className="page-mask page-mask-animation">
        <div className="edit-modal">
          <h4 data-testid="backlog-row" className="global-h2">
            {t("forms.type.edit-entry")}
          </h4>
          <p className="gray">{trailer.unit}</p>
          <form onSubmit={submit}>
            <div className="form-group">
              <label className="input-label" htmlFor="unit">
                {t("yard-tables.table-columns.unit")}
              </label>
              <div className="input-cont">
                <input
                  value={editedTrailer.unit}
                  name="unit"
                  className="text-input"
                  onChange={(e) => editTrailer("unit", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label" htmlFor="license_plate">
                {t("yard-tables.table-columns.license_plate")}
              </label>
              <input
                value={editedTrailer.license_plate}
                name="license_plate"
                className="text-input"
                onChange={(e) => editTrailer("license_plate", e.target.value)}
                required
              />
            </div>
            <div className="full-cont">
              {requestLoading ? (
                <Spinner />
              ) : (
                <>
                  <button className="cancel-btn" onClick={close}>
                    {t("buttons.close")}
                  </button>
                  <button className="change-pwd-btn" type="submit">
                    {t("buttons.submit")}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const EditTruckAsset = ({ truck, close }) => {
  const { t } = useTranslation();
  const [editedTruck, setEditedTruck] = useState(truck);
  const [responseModal, setResponseModal] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);

  const editTruck = (key, value) => {
    setEditedTruck((prevTruck) => ({
      ...prevTruck,
      [key]: value,
    }));
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.patch(
        "assets/edit-truck-asset",
        editedTruck
      );
      if (response.status === 200) {
        setResponseModal({
          message: t("forms.responses.success"),
          status: t("forms.type.success"),
          close: () => window.location.reload(),
        });
      }
    } catch (error) {
      setResponseModal({
        message: error,
        status: "Error",
        close: () => setResponseModal(null),
      });
      console.log(error);
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <>
      {responseModal && (
        <ResponseModal
          status={responseModal.status}
          message={responseModal.message}
          close={responseModal.close}
        />
      )}
      <div className="page-mask page-mask-animation">
        <div className="edit-modal">
          <h4 data-testid="backlog row" className="global-h2">
            {t("forms.type.edit-entry")}
          </h4>
          <p className="gray">
            {truck.company}-{truck.name}
          </p>
          <form onSubmit={submit}>
            <div className="form-group">
              <label className="input-label" htmlFor="company">
                {t("yard-tables.table-columns.carrier")}
              </label>
              <div className="form-group">
                <input
                  name="company"
                  type="text"
                  className="text-input"
                  value={editedTruck.company}
                  onChange={(e) => editTruck("company", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label" htmlFor="unit">
                {t("yard-tables.table-columns.unit")}
              </label>
              <div className="input-cont">
                <input
                  name="unit"
                  type="text"
                  className="text-input"
                  value={editedTruck.name}
                  onChange={(e) => editTruck("name", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label" htmlFor="license_plate">
                {t("yard-tables.table-columns.license_plate")}
              </label>
              <div className="input-cont">
                <input
                  type="text"
                  className="text-input"
                  value={editedTruck.license_plate}
                  onChange={(e) => editTruck("license_plate", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="full-cont">
              <button className="cancel-btn" onClick={close}>
                {t("buttons.close")}
              </button>
              <button className="change-pwd-btn" type="submit">
                {t("buttons.submit")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
