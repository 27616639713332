import { useEffect, useReducer, useState } from "react";
import Select from "react-select";
import instance from "../../Interceptor/api_instance";
import { Spinner } from "../LoadingAnimations/LoadingAnimations";
import { useAuth } from "../../Auth/AuthProvider";
import { formatReadableDate } from "./FormatFunctions";
import { useTranslation } from "react-i18next";
import { usStates, mexicanStates } from "../../Helper/CountryStates";
import { ResponseModal } from "./EditCondition";

/**
 * The RowClickModal component renders a modal with options to view documents, edit row, and close the
 * modal.
 * @returns The `RowClickModal` component is being returned. It is a functional component that renders
 * a modal with information about a row, including buttons to set documents, edit the row, and close
 * the modal.
 */
export const EditVehicleModal = ({ row, close, english, type }) => {
  return (
    <div className="edit-modal">
      <form>
        {type === "trailer" ? (
          <>
            {row.repair_trailer?.map((repair) => (
              <div className="form-group">
                <label className="input-label">
                  {formatReadableDate(repair.created_at)}{" "}
                  {repair.employee.first_name} {repair.employee.last_name}
                </label>
                <textarea
                  className="text-input"
                  value={repair.notes}
                ></textarea>
              </div>
            ))}
          </>
        ) : null}
      </form>
      <div className="full-cont">
        <button className="change-pwd-btn">{english("buttons.submit")}</button>
        <button className="cancel-btn" onClick={close}>
          {english("buttons.back")}
        </button>
      </div>
    </div>
  );
};
export const VehicleRowClickModal = ({
  setDocuments,
  setEditRow,
  row,
  close,
  english,
  type,
}) => {
  return (
    <>
      <div className="edit-modal">
        <div>
          <h4 data-testid={"documents"} className="global-h2">
            {type.includes("trailer")
              ? row.trailer_number
              : row.carrier_name + "-" + row.truck_number}
          </h4>
          <div style={{ flexGrow: "1" }}></div>
        </div>
        <div style={{ margin: "auto" }}>
          <button className="other-btn gap" onClick={setDocuments}>
            {english("page-headers.documents")}
          </button>
          <button className="other-btn" onClick={setEditRow}>
            {english("buttons.edit-row")}
          </button>
        </div>
        <div className="btn-cont">
          <button onClick={close} className="close-btn">
            {english("buttons.close")}
          </button>
        </div>
      </div>
    </>
  );
};

export const RowClickModal = ({
  setDocuments,
  setEditRow,
  row,
  close,
  type,
  english,
}) => {
  const getName = () => {
    switch (type) {
      case "trailers":
        return row.trailer.trailer_number;
      case "trucks":
        return row.truck.truckName;
      case "visitors":
        return row.visitor.driverName;
      case "truck-asset":
        return `${row.company}-${row.name}`;
      case "trailer-asset":
        return `${row.unit}`;
      default:
        break;
    }
  };
  return (
    <div className="page-mask page-mask-animation">
      <div className="edit-modal">
        <div>
          <h4 data-testid={"documents"} className="global-h2">
            {getName()}
          </h4>
          <div style={{ flexGrow: "1" }}></div>
        </div>
        <div style={{ margin: "auto" }}>
          <button className="other-btn gap" onClick={setDocuments}>
            {english("page-headers.documents")}
          </button>
          {row.entity_type !== "Visitor" && (
            <button className="other-btn" onClick={setEditRow}>
              {english("buttons.edit-row")}
            </button>
          )}
        </div>
        <div className="btn-cont">
          <button onClick={close} className="close-btn">
            {english("buttons.close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export const RowClickModalRefactor = ({
  setDocuments,
  setEditRow,
  entity,
  close,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-mask page-mask-animation">
      <div className="edit-modal">
        <div>
          <h4 data-testid={"documents"} className="global-h2">
            {entity}
          </h4>
          <div style={{ flexGrow: "1" }}></div>
        </div>
        <div style={{ margin: "auto" }}>
          <button className="other-btn gap" onClick={setDocuments}>
            {t("page-headers.documents")}
          </button>
          <button className="other-btn" onClick={setEditRow}>
            {t("buttons.edit-row")}
          </button>
        </div>
        <div className="btn-cont">
          <button onClick={close} className="close-btn">
            {t("buttons.close")}
          </button>
        </div>
      </div>
    </div>
  );
};

/**
 * The EditRowModal function in JavaScript handles editing and updating data for trailers and trucks,
 * including form inputs, dropdowns, and API calls.
 * @returns The `EditRowModal` component is being returned. It contains a form for editing either a
 * trailer or a truck based on the `type` prop passed to it. The form includes input fields for various
 * details such as customer, trailer/truck number, condition, origin, destination, and more. The form
 * also includes radio buttons, select dropdowns, and text inputs for user input.
 */

const reducer = (state, action) => {
  switch (action.type) {
    case "SUBMIT_REQUEST":
      return {
        ...state,
        requestLoading: true,
      };
    case "SUBMIT_RESPONSE":
      return {
        ...state,
        requestLoading: false,
        message: action.message,
        status: action.status,
      };
  }
};

const initialState = {
  state: null,
  message: null,
  requestLoading: false,
};
export const EditRowModal = ({ english, close, row, type }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const mechanicOptions =
    Array.isArray(user.groups) &&
    user.groups.some(
      (group) => group.name === "Admin" || group.name === "Safety"
    );
  const [ati, setATI] = useState(
    row.trailer?.trailer_asset ? true : false
  );
  const [trailerList, setTrailerList] = useState([]);
  const [trucksList, setTrucksList] = useState({
    jfg: [],
    ati: []
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [formState, dispatchForm] = useReducer(reducer, initialState);

  const getOriginData = (origin) => {
    if (!origin) {
      return {
        city: "",
        country: "",
        countryState: "",
      };
    }
    const [city, state, country] = origin.split(", ");
    return {
      city,
      country,
      countryState: state,
    };
  };

  const getDestinationData = (destination) => {
    if (!destination) {
      return {
        dCity: "",
        dCountry: "",
        dCountryState: "",
      };
    }
    const [dCity, dState, dCountry] = destination.split(", ");
    return {
      dCity,
      dCountry,
      dCountryState: dState,
    };
  };

  const [formDataTrailer, setFormDataTrailer] = useState(
    type.includes("trailer")
      ? {
          ...row.trailer,
          ...getOriginData(row.truck.origin),
          ...getDestinationData(row.truck.destination),
        }
      : ""
  );

  const [formDataTruck, setFormDataTruck] = useState(
    type.includes("truck")
      ? {
          ...row.truck,
          ...getOriginData(row.truck.origin),
          ...getDestinationData(row.truck.destination),
        }
      : ""
  );

  async function fetchData() {
    if (type.includes("trailer")) {
      setListLoading(true);
      try {
        const trailers = await instance.get("assets/out-trailers/");
        if (trailers.status === 200) {
          setTrailerList(trailers.data);
          setListLoading(false);
        }
      } catch (error) {
        setErrorMessage(error);
      }
    }
    if (type.includes("truck")) {
      setListLoading(true);
      try {
        const trucks = await instance.get("assets/get-trucks-out");
        if (trucks.status === 200) {
          setTrucksList(trucks.data);
          setListLoading(false);
        }
      } catch (error) {
        setErrorMessage(error);
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, [formDataTrailer.carrier_name, type]);

  const postEditChanges = async (e) => {
    e.preventDefault();
    if (type.includes("trailer")) {
      formDataTrailer.origin = `${formDataTrailer.city}, ${formDataTrailer.countryState}, ${formDataTrailer.country}`;
      formDataTrailer.destination = `${formDataTrailer.dCity}, ${formDataTrailer.dCountryState}, ${formDataTrailer.dCountry}`;
      formDataTrailer.is_seal =
        formDataTrailer.seal_number || formDataTrailer.check_out_seal_number;
      try {
        dispatchForm({ type: "SUBMIT_REQUEST" });
        const response = await instance.patch(
          "assets/edit-trailer",
          formDataTrailer
        );

        if (response.status === 200)
          dispatchForm({
            type: "SUBMIT_RESPONSE",
            status: t("forms.type.success"),
            message: t("forms.responses.success"),
          });
      } catch (error) {
        dispatchForm({
          type: "SUBMIT_RESPONSE",
          status: "Error",
          message: error,
        });
        setErrorMessage(error);
      }
    } else {
      formDataTruck.origin = `${formDataTruck.city}, ${formDataTruck.countryState}, ${formDataTruck.country}`;
      formDataTruck.destination = `${formDataTruck.dCity}, ${formDataTruck.dCountryState}, ${formDataTruck.dCountry}`;

      try {
        dispatchForm({ type: "SUBMIT_REQUEST" });
        const response = await instance.patch(
          "assets/edit-truck",
          formDataTruck
        );
        if (response.status === 200)
          dispatchForm({
            type: "SUBMIT_RESPONSE",
            status: t("forms.type.success"),
            message: t("forms.responses.success"),
          });
      } catch (error) {
        dispatchForm({
          type: "SUBMIT_RESPONSE",
          status: "Error",
          message: error,
        });
        setErrorMessage(error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Convert string "false" to boolean false
    const newValue =
      value === "false" ? false : value === "true" ? true : value;

    if (type.includes("trailer")) {
      setFormDataTrailer((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      setFormDataTruck((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }
  };

  // Function to handle changes in the react-select dropdown
  const handleTrailerNumberChange = (selectedOption) => {
    setFormDataTrailer((prevState) => ({
      ...prevState,
      trailer_asset: selectedOption?.value,
    }));
  };

  // Function to handle changes in the react-select dropdown
  const handleTruckNumberChange = (selectedOption) => {
    setFormDataTruck((prevState) => ({
      ...prevState,
      truck_asset: selectedOption?.value,
    }));
  };

  const trailerCheckoutOptions = trailerList?.map((trailer) => ({
    value: trailer.id,
    label: `${trailer.unit} ${
      trailer.location ? trailer.location.loc_name : ""
    }`,
    isDisabled: trailer.location ? true : false,
  }));

  const atiTruckCheckoutOptions = trucksList.ati?.map((truck) => ({
    value: listLoading ? "" : truck.id,
    label: listLoading
      ? ""
      : `${truck.name} ${truck.location ? truck.location.loc_name : ""}`,
    isDisabled: truck.location ? true : false,
  }));

  const jfgTruckCheckoutOptions = trucksList.jfg?.map((truck) => ({
    value: listLoading ? "" : truck.id,
    label: listLoading
      ? ""
      : `${truck.name} ${truck.location ? truck.location.loc_name : ""}`,
    isDisabled: truck.location ? true : false,
  }));

  // Styles for the searchable select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%", // Make sure control is full width
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto", // Override to handle padding
    }),

    container: (provided) => ({
      ...provided,
      width: "100%", // Ensure the dropdown menu is also full width
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "gray" : provided.color,
      borderBottom: "0.5px solid gray",
      padding: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };
  return (
    <div className="page-mask page-mask-animation">
      {formState.message && (
        <ResponseModal
          message={formState.message}
          status={formState.status}
          close={
            formState.status === t("forms.type.success")
              ? () => window.location.reload()
              : () =>
                  dispatchForm({
                    type: "SUBMIT_RESPONSE",
                    message: null,
                    status: null,
                  })
          }
        />
      )}
      <div className="edit-modal ">
        <div>
          <h4 data-testid={"documents"} className="global-h2">
            {english("forms.type.edit-entry")}
          </h4>
          <p className="gray">{row.trailer_number}</p>
          <form onSubmit={(e) => postEditChanges(e)}>
            {/* Customer input */}
            {type.includes("trailer") && (
              <div className="form-group">
                <label htmlFor="" className="input-label">
                  {english("yard-tables.search-filters.dropdowns.customer")}
                </label>
                <div className="input-cont">
                  <input
                    type="text"
                    value={formDataTrailer.customer}
                    className="text-input"
                    name="customer"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            {/* Is the trailer ATI? */}
            {type.includes("trailer") ? (
              <div className="form-group">
                <label className="input-label">
                  {english("forms.type.vehicle.input-headers.ati-bool")}{" "}
                </label>
                <div className="input-cont">
                  <fieldset className="fieldsets">
                    <div className="radio-options">
                      <input
                        type="radio"
                        name="group2"
                        className="radio-input"
                        checked={ati}
                        onChange={(e) => {
                          setATI(true);
                          setFormDataTrailer((prevState) => ({
                            ...prevState,
                            trailer_number: null,
                          }));
                        }}
                        required
                      />
                      <label>{english("general.yes")}</label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        id="no1"
                        name="group2"
                        className="radio-input"
                        checked={ati === false}
                        onChange={() => {
                          setATI(false);
                          handleTrailerNumberChange(null);
                          setFormDataTrailer((prevState) => ({
                            ...prevState,
                            trailer_number: null,
                          }));
                        }}
                      />
                      <label>No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
            ) : (
              // Carrier name truck
              <div className="form-group">
                <label className="input-label">
                  {english("yard-tables.table-columns.carrier")}
                </label>
                <div className="input-cont">
                  <select
                    value={formDataTruck.carrier_name}
                    onChange={(e) => {
                      handleChange(e);
                      setFormDataTruck((prev) => ({
                        ...prev,
                        truck_asset: null
                      }))
                    }}
                    id="carrier"
                    name={"carrier_name"}
                    required
                  >
                    <option
                      value={english("forms.type.vehicle.placeholders.other")}
                    >
                      {english("forms.type.vehicle.placeholders.other")}
                    </option>
                    <option value="JFG">JFG</option>
                    <option value="ATI">ATI</option>
                  </select>
                  {formDataTruck.carrier_name !== "ATI" &&
                    formDataTruck.carrier_name !== "JFG" && (
                      <input
                        id="other-carrier"
                        type="text"
                        value={formDataTruck.carrier_name}
                        name="carrier_name"
                        className="text-input"
                        placeholder={english(
                          "forms.type.vehicle.placeholders.other-carrier"
                        )}
                        onChange={handleChange}
                        required
                      />
                    )}
                </div>
              </div>
            )}
            {/* Trailer number select internal */}
            {type.includes("trailer")
              ? ati && (
                  <div className="form-group">
                    <label className="input-label">
                      {english(
                        "forms.type.vehicle.placeholders.select-trailer"
                      )}{" "}
                    </label>
                    <div className="input-cont">
                      <Select
                        onChange={handleTrailerNumberChange}
                        options={trailerCheckoutOptions}
                        value={trailerCheckoutOptions.find(
                          (trailer) =>
                            trailer.value === formDataTrailer.trailer_asset
                        )}
                        isClearable={true}
                        isSearchable={true}
                        styles={customStyles}
                        unstyled={true}
                        required={true}
                      />
                    </div>
                  </div>
                )
              : (formDataTruck.carrier_name === "ATI" ||
                  formDataTruck.carrier_name === "JFG") && (
                  // truck number select internal
                  <div className="form-group">
                    <label className="input-label">
                      {english("forms.type.vehicle.placeholders.select-truck")}{" "}
                    </label>
                    <div className="input-cont">
                      <Select
                        onChange={handleTruckNumberChange}
                        options={
                          formDataTruck.carrier_name === "ATI"
                            ? atiTruckCheckoutOptions
                            : jfgTruckCheckoutOptions
                        }
                        value={formDataTruck.carrier_name === "ATI" ? 
                          atiTruckCheckoutOptions.find(truck => truck.value === formDataTruck.truck_asset)
                          : jfgTruckCheckoutOptions.find(truck => truck.value === formDataTruck.truck_asset)
                        }
                        isClearable={true}
                        isSearchable={true}
                        styles={customStyles}
                        unstyled={true}
                        required={true}
                      />
                    </div>
                  </div>
                )}

            {/* Trailer number text input external */}
            {type.includes("trailer")
              ? !ati && (
                  <div className="form-group">
                    <label htmlFor="" className="input-label">
                      {english(
                        "forms.type.vehicle.input-headers.trailer-number"
                      )}
                    </label>
                    <div className="input-cont">
                      <input
                        type="text"
                        value={formDataTrailer.trailer_number}
                        className="text-input"
                        name="trailer_number"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                )
              : formDataTruck.carrier_name !== "ATI" &&
                formDataTruck.carrier_name !== "JFG" && (
                  // truck number text input external
                  <div className="form-group">
                    <label htmlFor="" className="input-label">
                      Enter truck number
                    </label>
                    <div className="input-cont">
                      <input
                        type="text"
                        value={formDataTruck.truck_number}
                        className="text-input"
                        name="truck_number"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
            
            {type.includes("truck") && (formDataTruck.carrier_name !== "JFG" && formDataTruck.carrier_name !== "ATI") && (
              <div className="form-group">
                <label htmlFor="" className="input-label">
                  {english("forms.type.vehicle.input-headers.truck-plate")}
                </label>
                <div className="input-cont">
                  <input type="text" className="text-input" value={formDataTruck.truck_plate} onChange={handleChange} name="truck_plate"/>
                </div>
              </div>
            )}
            {type.includes("trailer") && (
              // is trailer loaded select
              <div className="form-group">
                <label className="input-label">
                  {english("yard-tables.search-filters.dropdowns.contents")}{" "}
                </label>
                <div className="input-cont">
                  <select
                    id="contentSelect"
                    onChange={handleChange}
                    value={formDataTrailer.is_loaded}
                    name="is_loaded"
                  >
                    <option value="" disabled>
                      {english(
                        "forms.type.vehicle.placeholders.select-content"
                      )}
                    </option>
                    <option
                      value={true}
                      selected={formDataTrailer.is_loaded === true}
                    >
                      {english("forms.type.vehicle.placeholders.loaded")}
                    </option>
                    <option
                      value={false}
                      selected={formDataTrailer.is_loaded === false}
                    >
                      {english("forms.type.vehicle.placeholders.empty")}
                    </option>
                  </select>
                </div>
              </div>
            )}

            {type.includes("trailer") && (
              // Trailer seal number input
              <div className="form-group">
                <label className="input-label ">
                  {english("forms.type.vehicle.input-headers.sealNum")}{" "}
                </label>
                <div className="input-cont">
                  <input
                    id="sealNum"
                    onChange={handleChange}
                    value={formDataTrailer.seal_number}
                    name="seal_number"
                    className="text-input"
                    placeholder={english("forms.type.vehicle.placeholders.num")}
                  />
                </div>
              </div>
            )}

            {type.includes("trailer") && row.check_in_dt === null && (
              // Trailer seal number input
              <div className="form-group">
                <label className="input-label ">
                  {english("forms.type.vehicle.input-headers.exitSealNum")}{" "}
                </label>
                <div className="input-cont">
                  <input
                    id="exitSealNum"
                    onChange={handleChange}
                    value={formDataTrailer.check_out_seal_number}
                    name="check_out_seal_number"
                    className="text-input"
                    placeholder={english("forms.type.vehicle.placeholders.num")}
                  />
                </div>
              </div>
            )}
            {type.includes("trailer") ? (
              // Trailer condition select
              <div className="form-group">
                <label className="input-label">
                  {english("yard-tables.table-columns.condition")}
                </label>
                <div className="input-cont">
                  <div>
                    <select
                      id="conditionSelect"
                      onChange={handleChange}
                      value={formDataTrailer.trailer_condition}
                      name="trailer_condition"
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.header"
                        )}
                      </option>
                      <option value="Good">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.good"
                        )}
                      </option>
                      <option value="Okay">Okay</option>
                      <option value="Poor">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.poor"
                        )}
                      </option>
                      <option value="Critical">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.critical"
                        )}
                      </option>
                      <option value="Under Repair">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.under-repair"
                        )}
                      </option>
                      <option value="Waiting parts" disabled={!mechanicOptions}>
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.spare-parts"
                        )}
                      </option>
                      <option
                        value="Waiting instructions"
                        disabled={!mechanicOptions}
                      >
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.instructions"
                        )}
                      </option>
                      <option
                        value="External repair"
                        disabled={!mechanicOptions}
                      >
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.external-repair"
                        )}
                      </option>
                    </select>
                    <textarea
                      type="text"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.notes"
                      )}
                      value={formDataTrailer.trailer_condition_notes}
                      maxLength={100}
                      name="trailer_condition_notes"
                      onChange={handleChange}
                      required={
                        formDataTrailer.trailer_condition !== "Good" &&
                        formDataTrailer.trailer_condition !== "Okay"
                          ? true
                          : false
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              // truck condition select
              <div className="form-group">
                <label className="input-label">
                  {english("yard-tables.table-columns.condition")}
                </label>
                <div className="input-cont">
                  <div>
                    <select
                      id="conditionSelect"
                      onChange={handleChange}
                      value={formDataTruck.truck_condition}
                      name="truck_condition"
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.header"
                        )}
                      </option>
                      <option value="Good">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.good"
                        )}
                      </option>
                      <option value="Okay">Okay</option>
                      <option value="Poor">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.poor"
                        )}
                      </option>
                      <option value="Critical">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.critical"
                        )}
                      </option>
                      <option value="Under Repair">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.under-repair"
                        )}
                      </option>
                      <option value="Waiting parts" disabled={!mechanicOptions}>
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.spare-parts"
                        )}
                      </option>
                      <option
                        value="Waiting instructions"
                        disabled={!mechanicOptions}
                      >
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.instructions"
                        )}
                      </option>
                      <option
                        value="External repair"
                        disabled={!mechanicOptions}
                      >
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.external-repair"
                        )}
                      </option>
                    </select>
                    <textarea
                      type="text"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.notes"
                      )}
                      value={formDataTruck.truck_condition_notes}
                      name="truck_condition_notes"
                      onChange={handleChange}
                      required={
                        formDataTruck.truck_condition !== "Good" &&
                        formDataTruck.truck_condition !== "Okay"
                          ? true
                          : false
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            )}

            {type.includes("trailer") ? (
              // Trailer origin input
              <>
                <div className="form-group">
                  <label className="input-label">
                    {english("forms.type.vehicle.input-headers.origin")}
                  </label>
                  <div className="input-cont">
                    <select
                      id="country"
                      name="country"
                      onChange={handleChange}
                      value={formDataTrailer.country}
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.destination.country"
                        )}
                      </option>
                      <option value="USA">
                        {english("forms.type.vehicle.placeholders.usa")}
                      </option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    <select
                      id="state"
                      name="countryState"
                      value={formDataTrailer.countryState}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        {english(
                          "forms.type.vehicle.placeholders.destination.state"
                        )}
                      </option>
                      {formDataTrailer.country === "USA"
                        ? usStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : mexicanStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label"></label>
                  <div className="input-cont">
                    <input
                      id="city"
                      type="text"
                      name="city"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.destination.City"
                      )}
                      value={formDataTrailer.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Trailer destination input */}
                <div className="form-group">
                  <label className="input-label">
                    {english(
                      "yard-tables.search-filters.dropdowns.destination"
                    )}
                  </label>
                  <div className="input-cont">
                    <select
                      id="country"
                      name="dCountry"
                      onChange={handleChange}
                      value={formDataTrailer.dCountry}
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.destination.country"
                        )}
                      </option>
                      <option value="USA">
                        {english("forms.type.vehicle.placeholders.usa")}
                      </option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    <select
                      id="state"
                      name="dCountryState"
                      value={formDataTrailer.dCountryState}
                      onChange={handleChange}
                    >
                      <option value="">
                        {english(
                          "forms.type.vehicle.placeholders.destination.state"
                        )}
                      </option>
                      {formDataTrailer.dCountry === "USA"
                        ? usStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : mexicanStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label"></label>
                  <div className="input-cont">
                    <input
                      id="city"
                      type="text"
                      name="dCity"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.destination.City"
                      )}
                      value={formDataTrailer.dCity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Truck origin */}
                <div className="form-group">
                  <label className="input-label">
                    {english("forms.type.vehicle.input-headers.origin")}
                  </label>
                  <div className="input-cont">
                    <select
                      id="country"
                      name="country"
                      onChange={handleChange}
                      value={formDataTruck.country}
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.destination.country"
                        )}
                      </option>
                      <option value="USA">
                        {english("forms.type.vehicle.placeholders.usa")}
                      </option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    <select
                      id="state"
                      name="countryState"
                      value={formDataTruck.countryState}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        {english(
                          "forms.type.vehicle.placeholders.destination.state"
                        )}
                      </option>
                      {formDataTruck.country === "USA"
                        ? usStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : mexicanStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label"></label>
                  <div className="input-cont">
                    <input
                      id="city"
                      type="text"
                      name="city"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.destination.City"
                      )}
                      value={formDataTruck.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Truck destination */}
                <div className="form-group">
                  <label className="input-label">
                    {english(
                      "yard-tables.search-filters.dropdowns.destination"
                    )}
                  </label>
                  <div className="input-cont">
                    <select
                      id="country"
                      name="dCountry"
                      onChange={handleChange}
                      value={formDataTruck.dCountry}
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.destination.country"
                        )}
                      </option>
                      <option value="USA">
                        {english("forms.type.vehicle.placeholders.usa")}
                      </option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    <select
                      id="state"
                      name="dCountryState"
                      value={formDataTruck.dCountryState}
                      onChange={handleChange}
                    >
                      <option value="">
                        {english(
                          "forms.type.vehicle.placeholders.destination.state"
                        )}
                      </option>
                      {formDataTruck.dCountry === "USA"
                        ? usStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : mexicanStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label"></label>
                  <div className="input-cont">
                    <input
                      id="city"
                      type="text"
                      name="dCity"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.destination.City"
                      )}
                      value={formDataTruck.dCity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="btn-cont">
              {errorMessage && <p className="error">{errorMessage}</p>}
              {!formState.requestLoading ? (
                <div>
                  <button type="submit" className="other-btn">
                    {english("buttons.update")}
                  </button>
                  <button onClick={close} className="close-btn">
                    {english("buttons.close")}
                  </button>
                </div>
              ) : (
                <Spinner />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
