import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usStates, mexicanStates } from "../../Helper/CountryStates";
import { TrashIcon } from "../../Media/Icons";
import instance from "../../Interceptor/api_instance";
import { ConfirmationModal, ResponseModal } from "./EditCondition";

export const EditBacklogRow = ({ row, close }) => {
  const { t } = useTranslation();
  const [city, state, country] = row.truck.origin.split(", ");
  const [dcity, dstate, dcountry] = row.truck.destination.split(", ");
  const [requestLoading, setRequestLoading] = useState(false);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);

  const trailer = row.trailer
    ? {
        trailer_number: row.trailer ? row.trailer.trailer_number : null,
        customer: row.trailer ? row.trailer.customer : null,
        is_loaded: row.trailer ? row.trailer.is_loaded : null,
        trailer_plate: row.trailer ? row.trailer.license_plate : null,
        seal_number: row.trailer ? row.trailer.seal_number : null,
        check_out_seal_number: row.trailer
          ? row.trailer.check_out_seal_number
          : null,
      }
    : null;
  const [newRow, setNewRow] = useState({
    ...trailer,
    id: row.id,
    truckNum: row.truck.truckNum,
    driverName: row.visitor.driverName,
    truck_plate: row.truck.truck_plate,
    city: city,
    state: state,
    country: country,
    dcity: dcity,
    dstate: dstate,
    dcountry: dcountry,
    check_in_dt: row.check_in_dt,
    check_out_dt: row.check_out_dt,
  });

  const deleteEvent = async () => {
    try {
      setRequestLoading(true);
      const response = await instance.patch(`assets/reactivate?id=${row.id}`);
      if (response.status === 200) {
        setStatus(t("forms.type.success"));
        setMessage(t("forms.type.responses.success"));
      }
    } catch (error) {
      console.log(error);
      setStatus("Error");
      setMessage(error);
    } finally {
      setRequestLoading(false);
    }
  };

  const handleInputChange = (key, value) => {
    setNewRow((prevRow) => ({
      ...prevRow,
      [key]: value,
    }));
  };

  return (
    <>
      {deletePrompt && (
        <ConfirmationModal
          title={t("graphics-cont.confirm-modal.delete-event")}
          message={t("graphics-cont.confirm-modal.confirm-delete")}
          warning={t("warnings.delete_check_out")}
          confirm={() => deleteEvent()}
          close={() => setDeletePrompt(false)}
          requestLoading={requestLoading}
        />
      )}
      <div className="page-mask page-mask-animation">
        <div className="edit-modal">
          <div style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4 data-testid="backlog-row" className="global-h2">
                {t("forms.type.edit-entry")}
              </h4>
              {row.check_out_dt &&
                new Date() - new Date(row.check_out_dt) <
                  24 * 60 * 60 * 1000 && (
                  <div
                    style={{
                      width: "28px",
                      height: "28px",
                      background: "lightgray",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <div onClick={() => setDeletePrompt(true)}>
                      <TrashIcon />
                    </div>
                  </div>
                )}
            </div>
            <p className="gray">
              {row.entity_type === "Trailer"
                ? row.trailer.trailer_number
                : row.truck.truckName}
            </p>
          </div>
          <form>
            <div className="form-group">
              <label className="input-label">
                {t("yard-tables.table-columns.truck")}
              </label>
              <div className="input-cont">
                <label className="input-label">{row.truck.truckName}</label>
              </div>
            </div>
            {row.trailer && (
              <div className="form-group">
                <label className="input-label">
                  {t("yard-tables.table-columns.trailer")}
                </label>
                <div className="input-cont">
                  <label className="input-label">{row.trailer.trailer_number}</label>
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="input-label" htmlFor="driver">
                {t("yard-tables.table-columns.driver")}
              </label>
              <div className="input-cont">
                <input
                  type="text"
                  className="text-input"
                  value={row.visitor.driverName}
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label" htmlFor="truck-plates">
                {t("yard-tables.table-columns.truck")}{" "}
                {t("yard-tables.table-columns.license_plate")}
              </label>
              <div className="input-cont">
                <input
                  type="text"
                  className="text-input"
                  name="truck-plates"
                  id="truck-plates"
                  value={newRow.truck_plate}
                  onClick={(e) =>
                    handleInputChange("truck_plate", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label" htmlFor="origin">
                {t("forms.type.vehicle.input-headers.origin")}
              </label>
              <div className="input-cont">
                <select
                  value={newRow.country}
                  onChange={(e) => handleInputChange("country", e.target.value)}
                >
                  <option value="USA">
                    {t("forms.type.vehicle.placeholders.usa")}
                  </option>
                  <option value="Mexico">Mexico</option>
                </select>
                <select
                  value={newRow.state}
                  onChange={(e) => handleInputChange("state", e.target.value)}
                >
                  {newRow.country === "USA"
                    ? usStates.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))
                    : mexicanStates.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="input-label"></label>
              <div className="input-cont">
                <input
                  id="city"
                  type="text"
                  name="city"
                  className="text-input"
                  value={newRow.city}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="input-label" htmlFor="destination">
                {t("yard-tables.search-filters.dropdowns.destination")}
              </label>
              <div className="input-cont">
                <select
                  value={newRow.dcountry}
                  onChange={(e) =>
                    handleInputChange("dcountry", e.target.value)
                  }
                >
                  <option value="USA">
                    {t("forms.type.vehicle.placeholders.usa")}
                  </option>
                  <option value="Mexico">Mexico</option>
                </select>
                <select
                  value={newRow.state}
                  onChange={(e) => handleInputChange("dstate", e.target.value)}
                >
                  {newRow.dcountry === "USA"
                    ? usStates.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))
                    : mexicanStates.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="input-label"></label>
              <div className="input-cont">
                <input
                  id="city"
                  type="text"
                  name="city"
                  className="text-input"
                  value={newRow.dcity}
                  onChange={(e) => handleInputChange("dcity", e.target.value)}
                  required
                />
              </div>
            </div>
            {row.trailer ? (
              <>
                <div className="form-group">
                  <label className="input-label">
                    {t("yard-tables.table-columns.customer")}
                  </label>
                  <div className="input-cont">
                    <input
                      type="text"
                      className="text-input"
                      value={newRow.customer}
                      onChange={(e) =>
                        handleInputChange("customer", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label">
                    {t("yard-tables.table-columns.content")}
                  </label>
                  <div className="input-cont">
                    <select
                      value={newRow.is_loaded}
                      onChange={(e) =>
                        handleInputChange("is_loaded", e.target.value)
                      }
                    >
                      <option value={true}>
                        {t("forms.type.vehicle.placeholders.loaded")}
                      </option>
                      <option value={false}>
                        {t("forms.type.vehicle.placeholders.empty")}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label">
                    {t("yard-tables.table-columns.seal")}
                  </label>
                  <div className="input-cont">
                    <input
                      type="text"
                      className="text-input"
                      value={newRow.seal_number}
                      onChange={(e) =>
                        handleInputChange("seal_number", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label">
                    {t("buttons.check-out")}{" "}
                    {t("yard-tables.table-columns.seal")}
                  </label>
                  <div className="input-cont">
                    <input
                      type="text"
                      className="text-input"
                      value={newRow.check_out_seal_number}
                      onChange={(e) =>
                        handleInputChange(
                          "check_out_seal_number",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label">
                    {t("yard-tables.table-columns.trailer")}{" "}
                    {t("yard-tables.table-columns.license_plate")}
                  </label>
                  <div className="input-cont">
                    <input
                      type="text"
                      onChange={(e) =>
                        handleInputChange("trailer_plate", e.target.value)
                      }
                      className="text-input"
                      value={newRow.trailer_plate}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className="btn-cont">
              <button type="submit" className="other-btn">
                {t("buttons.update")}
              </button>
              <button onClick={close} className="close-btn">
                {t("buttons.close")}
              </button>
            </div>
          </form>
        </div>
      </div>
      {message && (
        <ResponseModal
          message={message}
          status={status}
          close={
            status === t("forms.type.success")
              ? () => window.location.reload()
              : () => {
                  setMessage(null);
                  setStatus(null);
                }
          }
        />
      )}
    </>
  );
};
