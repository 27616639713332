import { useEffect, useReducer } from "react";
import { useAuth } from "../../Auth/AuthProvider";
import instance from "../../Interceptor/api_instance";
import { useTranslation } from "react-i18next";
import { Dots } from "../LoadingAnimations/LoadingAnimations";
import { CheckOutIcon } from "../../Media/Icons";
import { CheckOutVisitor } from "../Forms/CheckOut/CheckOutVisitor";
import { ResponseModal } from "../Modals/EditCondition";

const reducer = (state, action) => {
  switch (action.type) {
    case "STORE_DATA":
      return {
        ...state,
        isLoading: false,
        data: action.filter
          ? action.data.filter((item) => item.visitor.category === "Driver")
          : action.data.filter((item) => item.visitor.category !== "Driver"),
      };
    case "SELECT_ROW":
      return { ...state, selectedRow: action.visitor };
    case "DESELECT_ROW":
      return { ...state, selectedRow: null, isLoading: false };
    case "SET_GET_ERROR":
      return { ...state, error: action.error, isLoading: false };
    case "CHECK_OUT_REQUEST":
      return { ...state, isLoadingRequest: true };
    case "CHECK_OUT_SUCCESS":
      return {
        ...state,
        isLoadingRequest: false,
        requestResponse: action.response,
        responseStatus: true,
      };
    case "CHECK_OUT_ERROR":
      return {
        ...state,
        isLoadingRequest: false,
        requestResponse: action.response,
        responseStatus: false,
      };
    case "CLOSE_RESPONSE":
        return {
            ...state,
            requestResponse: null,
            responseStatus: null
        }
    case "GET_LOADING":
      return { ...state, isLoading: true };
    case "GET_LOADING_DONE":
      return { ...state, isLoading: false };
  }
};
const VisitorsInYard = ({ driverFilter }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const checkOutRoles = ["Safety", "Guard", "Guard Supervisor", "Admin"];

  const initialState = {
    data: [],
    isLoading: true,
    selectedRow: null,
    error: null,
    isLoadingRequest: false,
    requestResponse: null,
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const confirm = async () => {
    dispatch({ type: "CHECK_OUT_REQUEST" });
    try {
      const data = {
        first_name: state.selectedRow.visitor.first_name,
        last_name: state.selectedRow.visitor.last_name,
        id: state.selectedRow.visitor.id,
      };
      const response = await instance.post("assets/check-out-visitor/", data);

      if (response.status === 200) {
        dispatch({
          type: "CHECK_OUT_SUCCESS",
          response: t("forms.type.visitor-check-out"),
        });
      }
    } catch (error) {
      dispatch({ type: "CHECK_OUT_ERROR", response: error });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(`assets/get-visitors/`);
        if (response.status === 200) {
          dispatch({
            type: "STORE_DATA",
            data: response.data,
            filter: driverFilter,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "GET_LOADING_DONE" });
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="graphics-card">
        <h4 className="global-h4 gray">
          {t(`graphics-cont.current-${driverFilter ? "drivers" : "visitors"}`)}
        </h4>
        {state.isLoading ? (
          <Dots />
        ) : (
          <div className="recent-activity-cont">
            <div className="recent-activity">
              {state.data.map((visitor, index) => (
                <div key={index} className="activity-item">
                  <span className="global-h5 gray">
                    {visitor.visitor.first_name} {visitor.visitor.last_name}{" "}
                    {visitor.visitor.category &&
                      `(${visitor.visitor.category})`}
                  </span>
                  {user.groups.some((group) =>
                    checkOutRoles.includes(group.name)
                  ) && (
                    <div
                      onClick={() =>
                        dispatch({ type: "SELECT_ROW", visitor: visitor })
                      }
                    >
                      <CheckOutIcon color="#FF6363" />
                    </div>
                  )}
                </div>
              ))}
              {state.error && !state.isLoadingRequest && (
                <p className="error">{state.error}</p>
              )}
            </div>
          </div>
        )}
      </div>
      {state.selectedRow && (
        <div>
          <div className="page-mask page-mask-animation">
            <CheckOutVisitor
              onClose={() => dispatch({ type: "DESELECT_ROW" })}
              visitor={state.selectedRow}
              confirm={confirm}
              english={t}
              loading={state.isLoadingRequest}
            />
          </div>
        </div>
      )}
      {state.requestResponse && <ResponseModal 
      message={state.requestResponse} 
      status={state.responseStatus ? t("forms.responses.success") : t("forms.responses.failure")}
      close={state.responseStatus ? () => window.location.reload() : () => dispatch({type: "CLOSE_RESPONSE"})}/>}
    </>
  );
};

export default VisitorsInYard;