import { useEffect, useReducer, useState } from "react";
import instance from "../../Interceptor/api_instance";
import { PageLayout } from "../PageLayout";
import { VehicleTabs } from "../Tables/Vehicles/Tabs";
import { useTranslation } from "react-i18next";
import { trailerAssetHeaders, truckAssetHeaders } from "../Tables/Headers";
import { useTransformData } from "../Tables/Transform/TransformData";
import { AssetsTableConst } from "../Tables/Vehicles/AssetTableConst";
import { SpinnerOverlay } from "../LoadingAnimations/SpinnerOverlay";

const reducer = (state, action) => {
  switch (action.type) {
    case "STORE_DATA":
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case "FETCH_DATA":
      return {
        ...state,
        isLoading: true,
        data: [],
      };
    case "SET_SELECTED_ROW":
      return {
        ...state,
        selectedRow: action.selectedRow,
      };
    case "CLOSE_SELECTED_ROW":
      return {
        ...state,
        selectedRow: null,
      };
    case "IS_LOADING":
      return {
        ...state,
        requestLoading: action.payload
      }
    default:
      return state;
  }
};

const initialState = {
  isLoading: false,
  requestLoading: false,
  data: [],
};
export const Assets = () => {
  const { t } = useTranslation();
  const trailerTab = t("yard-tables.tabs.trailers");
  const truckTab = t("yard-tables.tabs.trucks");
  const { TransformAssetData, TransformTruckAssetData } = useTransformData();
  const tabs = [trailerTab, truckTab];
  const [selectedTab, setSelectedTab] = useState(
    t("yard-tables.tabs.trailers")
  );

  const [trailerState, trailerDispatch] = useReducer(reducer, {
    ...initialState,
    url: "assets/out-trailers",
  });
  const [truckState, truckDispatch] = useReducer(reducer, {
    ...initialState,
    url: "get-trucks-out",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        trailerDispatch({ type: "FETCH_DATA" });
        truckDispatch({ type: "FETCH_DATA" });
        const [trailerResponse, truckResponse] = await Promise.all([
          instance.get("assets/out-trailers"),
          instance.get("assets/get-trucks-out"),
        ]);
        if (trailerResponse.status === 200) {
          trailerDispatch({
            type: "STORE_DATA",
            payload: TransformAssetData(trailerResponse.data),
          });
        }
        if (truckResponse.status === 200) {
          const merged = [...truckResponse.data.jfg, ...truckResponse.data.ati];
          truckDispatch({
            type: "STORE_DATA",
            payload: TransformTruckAssetData(merged),
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <PageLayout title={"Assets"}>
      {(trailerState.requestLoading || truckState.requestLoading) && <SpinnerOverlay/>}
      <div className="inventory-header justify-content-between width-full">
        <h2 className="global-h1 light-black">{t("page-headers.assets")}</h2>
        {/* {selectedTab === trailerTab ? <button className="check-in-btn all-btn success-btn global-h5 gray">Add new trailer</button> : <button className="check-in-btn all-btn success-btn global-h5 gray">Add new truck</button>} */}
      </div>
      <VehicleTabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === trailerTab && !trailerState.isLoading ? (
        <AssetsTableConst
          key="trailer_asset"
          headers={trailerAssetHeaders(t)}
          dispatch={trailerDispatch}
          type={"trailer-asset"}
          state={trailerState}
        />
      ) : selectedTab === truckTab && !truckState.isLoading ? (
        <AssetsTableConst
          key="truck_asset"
          headers={truckAssetHeaders(t)}
          dispatch={truckDispatch}
          type={"truck-asset"}
          state={truckState}
        />
      ) : (
        <SpinnerOverlay/>
      )}
    </PageLayout>
  );
};
