import { useTranslation } from "react-i18next";

export const CheckInTrailerF15 = ({
  backForm,
  nextForm,
  trailerForm,
  setTrailerForm,
  sealStatus,
  inOrOut,
}) => {
  const { t } = useTranslation();

  const handleTrailerChange = (key, field, value) => {
    if (key === "seal_tests") {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        seal_tests: {
          ...prevForm.seal_tests,
          [field]: value,
        },
      }));
    } else if (key === "anomalies") {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        anomalies: value,
      }));
    } else {
      setTrailerForm((prevForm) => ({
        ...prevForm,
        trailer_inspection: {
          ...prevForm.trailer_inspection,
          [key]: {
            ...prevForm.trailer_inspection[key],
            [field]: value,
          },
        },
      }));
    }
  };
  const isAnyFieldPoor = () => {
    return Object.values(trailerForm).some((value) => value === false);
  };

  return (
    <div className="questions-cont">
      <h2 className="global-h2 light-black">
        {inOrOut
          ? t("forms.type.vehicle.check-in.header")
          : t("forms.type.vehicle.check-out.header")}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {t("forms.type.f15.trailer-subheader")}
      </h4>
      <form onSubmit={nextForm} id="form-trailerF15">
        {Object.keys(trailerForm.trailer_inspection).map(
          (key) =>
            key != "seal_tests" &&
            key != "trailer_anomalies" && (
              <div className="form-group">
                <label className="input-label required">
                  {t(`forms.type.f15.trailer.labels.${key}`)}
                </label>
                <div className="input-cont">
                  <fieldset className="fieldsets">
                    <div className="radio-options">
                      <input
                        type="radio"
                        name={key}
                        checked={trailerForm.trailer_inspection[key].value}
                        onClick={() => handleTrailerChange(key, "value", true)}
                        className="radio-input"
                        required
                      />{" "}
                      <label>
                        {t(
                          "forms.type.vehicle.placeholders.select-condition.good"
                        )}
                      </label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        name={key}
                        checked={
                          trailerForm.trailer_inspection[key].value === false
                        }
                        onClick={() => handleTrailerChange(key, "value", false)}
                        className="radio-input"
                      />
                      <label>
                        {t(
                          "forms.type.vehicle.placeholders.select-condition.poor"
                        )}
                      </label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        name={key}
                        checked={
                          trailerForm.trailer_inspection[key].value === null
                        }
                        onClick={() => handleTrailerChange(key, "value", null)}
                        className="radio-input"
                      />
                      <label>N/A</label>
                    </div>
                  </fieldset>
                  <input
                    className="text-input"
                    type="text"
                    placeholder={t("forms.type.vehicle.input-headers.notes")}
                    value={trailerForm.trailer_inspection[key].notes}
                    onChange={(e) =>
                      handleTrailerChange(key, "notes", e.target.value)
                    }
                    required={
                      trailerForm.trailer_inspection[key].value === false
                    }
                  />
                </div>
              </div>
            )
        )}
        {sealStatus ? (
          <div className="form-group">
            <label className="input-label required">
              {t("forms.type.f15.trailer.labels.seal_tests")}
            </label>
            <div className="input-cont">
              <div className="checkboxes-cont">
                <div className="check-cont">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleTrailerChange(
                        "seal_tests",
                        "view_seal",
                        e.target.checked
                      )
                    }
                    checked={trailerForm.seal_tests.view_seal}
                    required
                  />
                  <label>{t("forms.type.f15.trailer.labels.view_seal")}</label>
                </div>
                <div className="check-cont">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleTrailerChange(
                        "seal_tests",
                        "verify_seal",
                        e.target.checked
                      )
                    }
                    checked={trailerForm.seal_tests.verify_seal}
                    required
                  />
                  <label>
                    {t("forms.type.f15.trailer.labels.verify_seal")}
                  </label>
                </div>
                <div className="check-cont">
                  <input
                    type="checkbox"
                    required
                    onChange={(e) =>
                      handleTrailerChange(
                        "seal_tests",
                        "tug_on_seal",
                        e.target.checked
                      )
                    }
                    checked={trailerForm.seal_tests.tug_on_seal}
                  />
                  <label>
                    {t("forms.type.f15.trailer.labels.tug_on_seal")}
                  </label>
                </div>
                <div className="check-cont">
                  <input
                    type="checkbox"
                    required
                    onChange={(e) =>
                      handleTrailerChange(
                        "seal_tests",
                        "twistTurn_seal",
                        e.target.checked
                      )
                    }
                    checked={trailerForm.seal_tests.twistTurn_seal}
                  />
                  <label>
                    {t("forms.type.f15.trailer.labels.twistTurn_seal")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-group">
          <label className="input-label">
            {t("forms.type.f15.truck.labels.anomalies")}
          </label>
          <div className="input-cont">
            <input
              className="text-input"
              placeholder={t("forms.type.f15.truck.labels.anomalies")}
              type="text"
              onChange={(e) =>
                handleTrailerChange("anomalies", "anomalies", e.target.value)
              }
              value={trailerForm.anomalies}
              required={isAnyFieldPoor()}
            />
          </div>
        </div>
        <div className="full-cont">
          <button className="change-pwd-btn" type="submit">
            Continue
          </button>
          <button className="cancel-btn" onClick={backForm}>
            Back
          </button>
        </div>
      </form>
    </div>
  );
};
