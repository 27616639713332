import { calculateHoursDifference } from "../../Utility/CalculateHoursDifference";
export const sortTrailers = (trailers, setTrailers, order, setOrder) => {
  const newList = [...trailers];
  newList.sort(
    (a, b) =>
      a.trailer.trailer_number.localeCompare(b.trailer.trailer_number) *
      (order ? 1 : -1)
  );
  setTrailers(newList);
  setOrder(order ? false : true);
};

export const sortCustomers = (trailers, setTrailers, order, setOrder) => {
  const newList = [...trailers];
  newList.sort((a, b) => {
    const customerA = a.trailer.customer ? a.trailer.customer : "None";
    const customerB = b.trailer.customer ? b.trailer.customer : "None";
    return customerA.localeCompare(customerB) * (order ? 1 : -1);
  });
  setTrailers(newList);
  setOrder(order ? false : true);
};

export const sortReservations = (trailers, setTrailers, order, setOrder) => {
  const newList = [...trailers];
  newList.sort((a, b) => {
    const first = `${a.reservation.user.first_name} ${a.reservation.user.last_name}`;
    const second = `${b.reservation.user.first_name} ${b.reservation.user.last_name}`;
    return first.localeCompare(second) * (order ? 1 : -1);
  });
  setTrailers(newList);
  setOrder(order ? false : true);
};

export const sortItems = (
  vehicles,
  setVehicles,
  keyString,
  order,
  setOrder
) => {
  const newList = [...vehicles];
  newList.sort((a, b) => {
    const first = getNestedValue(a, keyString);
    const second = getNestedValue(b, keyString);
    return first.localeCompare(second) * (order ? 1 : -1);
  });
  setVehicles(newList);
  setOrder(order ? false : true);
};
function getNestedValue(obj, keyString) {
  const keys = keyString.split(".");
  let value = obj;

  for (const key of keys) {
    if (value && typeof value === "object") {
      value = value[key]; // Navigate to the next level
    } else {
      return undefined; // Return undefined if a key doesn't exist
    }
  }
  return value;
}

export const sortContents = (trailers, setTrailers, order, setOrder) => {
  const newList = [...trailers];
  newList.sort((a, b) => {
    if (order) {
      return a.is_loaded - b.is_loaded; //true (1) comes after false (0)
    } else {
      return b.is_loaded - a.is_loaded; //false (0) comes after true (1)
    }
  });
  setTrailers(newList);
  setOrder(!order);
};

export const sortSeal = (trailers, setTrailers, order, setOrder) => {
  const newList = [...trailers];
  newList.sort((a, b) => {
    const sealA = a.seal_number ? a.seal_number : "";
    const sealB = b.seal_number ? b.seal_number : "";
    return sealA.localeCompare(sealB) * (order ? 1 : -1);
  });
  setTrailers(newList);
  setOrder(!order);
};

export const sortCondition = (trailers, setTrailers, order, setOrder) => {
  const newList = [...trailers];
  newList.sort(
    (a, b) =>
      a.trailer_condition.localeCompare(b.trailer_condition) * (order ? 1 : -1)
  );
  setTrailers(newList);
  setOrder(!order);
};

export const sortArrivalTimes = (trailers, setTrailers, order, setOrder) => {
  const newList = [...trailers];
  newList.sort((a, b) => {
    const dateA = new Date(a.check_in_dt);
    const dateB = new Date(b.check_in_dt);
    return (dateA - dateB) * (order ? -1 : 1);
  });
  setTrailers(newList);
  setOrder(order ? false : true);
};

export const sortLocation = (assets, setAssets, order, setOrder) => {
  const newList = [...assets];
  newList.sort(
    (a, b) => a.loc_name.localeCompare(b.loc_name) * (order ? 1 : -1)
  );
  setAssets(newList);
  setOrder(!order);
};

export const sortTruck = (trucks, setTrucks, order, setOrder) => {
  const newList = [...trucks];
  newList.sort(
    (a, b) => a.truck_number.localeCompare(b.truck_number) * (order ? 1 : -1)
  );
  setTrucks(newList);
  setOrder(order ? false : true);
};

export const sortTruckList = (trucks, setTrucks) => {
  const newList = [...trucks];
  newList.sort((a, b) => {
    const truckA = a.carrier_name + "-" + a.truck_number;
    const truckB = b.carrier_name + "-" + b.truck_number;
    return truckA.localeCompare(truckB);
  });
  setTrucks(newList);
};

export function sortByTruckNumber(trucks) {
  trucks.sort((a, b) => {
    const truckA = a.truck_number.toLowerCase();
    const truckB = b.truck_number.toLowerCase();

    if (truckA < truckB) {
      return -1;
    } else if (truckA > truckB) {
      return 1;
    } else {
      return 0;
    }
  });

  return trucks;
}

export const sortCarrier = (trucks, setTrucks, order, setOrder) => {
  const newList = [...trucks];
  newList.sort(
    (a, b) => a.carrier_name.localeCompare(b.carrier_name) * (order ? 1 : -1)
  );
  setTrucks(newList);
  setOrder(order ? false : true);
};

export const sortDestination = (trailers, setTrailers, order, setOrder) => {
  const newList = [...trailers];
  newList.sort(
    (a, b) => a.destination.localeCompare(b.destination) * (order ? 1 : -1)
  );
  setTrailers(newList);
  setOrder(order ? false : true);
};

export const isGuard = () => {
  if (localStorage.getItem("user") === null) {
    return false;
  }
  const user = JSON.parse(localStorage.getItem("user"));
  if (["Admin", "Guard"].includes(user.role)) {
    return true;
  }
  return false;
};

export const isSafety = (user) => {
  if (localStorage.getItem("user") === null) {
    return false;
  }
  if (["Admin", "Safety"].includes(user.role)) {
    return true;
  }
  return false;
};

export const isMechanic = (user) => {
  if (localStorage.getItem("user") === null) {
    return false;
  }
  if (["Mechanic"].includes(user.role)) {
    return true;
  }
  return false;
};

export const isDispatch = (user) => {
  if (user === null) {
    return false;
  }

  if (user.groups.some((group) => ["Admin", "Dispatch"].includes(group.name))) {
    return true;
  }
  return false;
};

export const isIT = (user) => {
  if (user.role === "IT") {
    return true;
  }
  return false;
};

export const sortEmployees = (
  employees,
  key,
  setEmployees,
  order,
  setOrder
) => {
  const newList = [
    ...employees.sort((a, b) => {
      if (a[key] > b[key]) {
        return -1 * order;
      } else if (a[key] > b[key]) {
        return 1 * order;
      } else {
        return 0;
      }
    }),
  ];

  setEmployees(newList);
  setOrder(order * -1);
  console.log(order);
  console.log(newList);
};

export const sortData = (data, key, setData, order, setOrder, t) => {
  let newList = [...data];
  switch (key) {
    case t("yard-tables.table-columns.location"):
      newList = newList.sort(
        (a, b) =>
          a.location.loc_name.localeCompare(b.location.loc_name) *
          (order ? 1 : -1)
      );
      break;
    case t("yard-tables.table-columns.trailer"):
      newList = newList.sort(
        (a, b) =>
          a.trailer.trailer_number.localeCompare(b.trailer.trailer_number) *
          (order ? 1 : -1)
      );
      break;

    case t("yard-tables.table-columns.guard"):
      newList = newList.sort(
        (a, b) =>
          a.user.userName.localeCompare(b.user.userName) * (order ? 1 : -1)
      );
      break;

    case t("yard-tables.table-columns.reservation"):
      newList = newList.sort((a, b) => {
        const nameA = a.trailer.reservation
          ? a.trailer.reservation.userName
          : t("forms.type.vehicle.placeholders.none");
        const nameB = b.trailer.reservation
          ? b.trailer.reservation.userName
          : t("forms.type.vehicle.placeholders.none");

        return nameA.localeCompare(nameB) * (order ? 1 : -1);
      });
      break;

    case t("yard-tables.table-columns.customer"):
      newList = newList.sort((a, b) => {
        const customerA = a.trailer.customer
          ? a.trailer.customer
          : t("forms.type.vehicle.placeholders.none");
        const customerB = b.trailer.customer
          ? b.trailer.customer
          : t("forms.type.vehicle.placeholders.none");
        return customerA.localeCompare(customerB) * (order ? 1 : -1);
      });
      break;

    case t("yard-tables.table-columns.condition"):
      newList = newList.sort((a, b) => {
        if (a.entity_type === "Trailer") {
          return (
            a.trailer.trailer_condition.localeCompare(
              b.trailer.trailer_condition
            ) * (order ? 1 : -1)
          );
        } else if (a.entity_type === "Truck") {
          return (
            a.truck.truck_condition.localeCompare(b.truck.truck_condition) *
            (order ? 1 : -1)
          );
        }
      });
      break;

    case t("yard-tables.table-columns.seal"):
      newList = newList.sort((a, b) => {
        const sealA = a.trailer.seal_number
          ? a.trailer.seal_number
          : t("forms.type.vehicle.placeholders.none");
        const sealB = b.trailer.seal_number
          ? b.trailer.seal_number
          : t("forms.type.vehicle.placeholders.none");

        return sealA.localeCompare(sealB) * (order ? 1 : -1);
      });
      break;
    case t("yard-tables.table-columns.truck"):
      newList = newList.sort(
        (a, b) =>
          a.truck.truckName.localeCompare(b.truck.truckName) * (order ? 1 : -1)
      );
      break;
    case t("yard-tables.table-columns.driver"):
      newList = newList.sort(
        (a, b) =>
          a.visitor.driverName.localeCompare(b.visitor.driverName) *
          (order ? 1 : -1)
      );
      break;
    case t("yard-tables.table-columns.content"):
      newList = newList.sort((a, b) => {
        return (a.trailer.is_loaded - b.trailer.is_loaded) * (order ? 1 : -1);
      });
      break;
    case t("yard-tables.table-columns.arrival-time"):
      newList = newList.sort((a, b) => {
        const dateA = calculateHoursDifference(a.check_in_dt);
        const dateB = calculateHoursDifference(b.check_in_dt);
        return (dateA - dateB) * (order ? 1 : -1);
      });
      break;
    default:
      break;
  }
  setData(newList);
  setOrder(!order);
};
