/**
 * The TableImgModal component in React displays images and related information with the ability to
 * enlarge images in a modal.
 * @returns The `TableImgModal` component is being returned. It contains conditional rendering based on
 * the `responseStatus` state. If the response status is 200, it renders a modal displaying
 * information, images, and buttons. If the response status is not 200, it renders a loading spinner.
 */
import React, { useState, useEffect } from "react";
import instance from "../../Interceptor/api_instance";

export const TableImgModal = ({ info, close, trailer, english }) => {
  const [isEnlargeModalOpen, setIsEnlargeModalOpen] = useState(false);
  const [selectedItemImageSrc, setSelectedItemImageSrc] = useState("");
  const [imgUrls, setImgUrls] = useState({});
  const [responseStatus, setResponseStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        image_urls: trailer.image_urls?.urls,
        blob_names: trailer.image_urls?.blob_names,
        image_container: trailer.image_urls?.container_name,
      };

      const response = await instance.post("assets/get-entry-images/", data);
      setResponseStatus(response.status);

      if (response.data !== "") {
        setImgUrls(response.data.img_urls);
      }
    };
    fetchData();
  }, []);

  const handleOpenEnlargeModal = (imgSrc) => {
    setSelectedItemImageSrc(imgSrc);
    setIsEnlargeModalOpen(true);
  };

  const handleCloseEnlargeModal = () => {
    setIsEnlargeModalOpen(false);
  };

  const handleClose = () => {
    close();
  };

  // Inline EnlargedItemModal component
  const EnlargedItemModal = () => (
    <>
      <div style={{ zIndex: "7" }} className="enlarged-modal">
        <div className="img-cont">
          <img src={selectedItemImageSrc} alt="Enlarged" />
        </div>

        <div className="btn-cont">
          <button className="close-btn" onClick={handleCloseEnlargeModal}>
            {english("buttons.back")}
          </button>
        </div>
      </div>
    </>
  );
  return (
    <div className="page-mask page-mask-animation">
      {responseStatus === 200 ? (
        <div className="image-modal">
          <div>
            <h4 data-testid={'documents'} className="global-h2">{english("page-headers.documents")}</h4>
            <p className="gray">{info}</p>
            <div style={{ flexGrow: "1" }}></div>
          </div>
          {imgUrls.length > 0 ? (
            <div className="photo-grid">
              {imgUrls.map((image, num) => (
                <div
                  key={num}
                  className="placeholder2"
                  onClick={() => handleOpenEnlargeModal(image)}
                >
                  <img src={image} alt={`Item ${num + 1}`} />
                </div>
              ))}
            </div>
          ) : (
            <div className="no-img-found">
              <p>{english("general.no-images")}</p>
            </div>
          )}
          {trailer.entity_type === "Truck" ? trailer.truck.truck_condition_notes && (
            <div className="table-img-notes">
              <h4>{english("forms.type.vehicle.input-headers.notes")}</h4>
              <p className="gray">{trailer.truck.truck_condition_notes}</p>
            </div>
          ) : <></>}
          {trailer.entity_type === "Trailer" ? trailer.trailer.trailer_condition_notes && (
            <div className="table-img-notes">
              <h4>{english("forms.type.vehicle.input-headers.notes")}</h4>
              <p className="gray">{trailer.trailer.trailer_condition_notes}</p>
            </div>
          ) : <></>}
          {isEnlargeModalOpen && <EnlargedItemModal />}
          <div className="btn-cont">
            <button onClick={handleClose} className="close-btn">
              {english("buttons.close")}
            </button>
          </div>
        </div>
      ) : (
        <div data-testid="img-modal-loader" className="loading-cont img-modal-loader">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableImgModal;
