import "../../CSS/global.css";
import "../../CSS/guardOverview.css";
import "../../CSS/fancyDropDown.css";
import { useReducer, useEffect, useState } from "react";
import instance from "../../Interceptor/api_instance";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Auth/AuthProvider";
import CheckInBtn from "../Buttons/check-in-btn";
import { getTruckHeaders, getTrailerHeaders } from "../Tables/Headers";
import { VehicleTabs } from "../Tables/Vehicles/Tabs";
import { useTransformData } from "../Tables/Transform/TransformData";
import { PageLayout } from "../PageLayout";
import OverviewGraphics from "../Graphics/OverviewGraphics";
import { TableConst } from "../Tables/Vehicles/TableConst";
import { VehicleFilters } from "../Tables/Vehicles/Filters";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_DATA":
      return { ...state, isFetchingData: true };
    case "STORE_DATA":
      return { ...state, data: action.data, isFetchingData: false };
    case "SET_SELECTED_ROW":
      return {
        ...state,
        selectedRow: action.selectedRow,
      };
    case "CLOSE_SELECTED_ROW":
      return {
        ...state,
        selectedRow: null,
      };
    default:
      return state;
  }
};

const initialState = {
  data: null,
  isFetchingData: true,
  selectedRow: null,
  error: null,
};

export const OverviewRefactor = ({}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const tabs = ["Trailers", "Trucks"];
  const [selectedTab, setSelectedTab] = useState("Trailers");

  const [trailerState, trailerDispatch] = useReducer(reducer, {
    ...initialState,
    url: "assets/view-trailers",
  });
  const [truckState, truckDispatch] = useReducer(reducer, {
    ...initialState,
    url: "assets/view-trucks",
  });

  const { TransformEventData } = useTransformData();

  const fetchData = async (state, dispatch, type) => {
    dispatch({ type: "FETCH_DATA" });
    const response = await instance.get(state.url);
    dispatch({
      type: "STORE_DATA",
      data:
        type === "trailer"
          ? TransformEventData(response.data)
          : TransformEventData(response.data),
    });
  };

  useEffect(() => {
    fetchData(trailerState, trailerDispatch, "trailer");
    fetchData(truckState, truckDispatch, "truck");
  }, []);

  return (
    <PageLayout title={"Overview"}>
      <div className="overview-header">
        <h1 className="global-h1 black">{t("page-headers.overview")}</h1>
        <h4 className="global-h4 gray">
          {t("page-subheaders.welcome")}, {user.first_name}
        </h4>
      </div>
      <OverviewGraphics />
      <div className="inventory-header">
        <h2 className="light-black global-h2">{t("page-headers.inventory")}</h2>
        {user.role !== "Safety" && <CheckInBtn english={t} />}
      </div>
      <VehicleTabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === "Trailers" && !trailerState.isFetchingData && (
        <TableConst
          key={"trailers"}
          headers={getTrailerHeaders(t, user)}
          state={trailerState}
          dispatch={trailerDispatch}
          type={"trailers"}
          filters={VehicleFilters("trailers", trailerState.data, user, t)}
        />
      )}
      {selectedTab === "Trucks" && !truckState.isFetchingData && (
        <TableConst
          key={"trucks"}
          headers={getTruckHeaders(t, user)}
          state={truckState}
          dispatch={truckDispatch}
          type={"trucks"}
          filters={VehicleFilters("trucks", truckState.data, user, t)}
        />
      )}
    </PageLayout>
  );
};
