import { useEffect, useState } from "react";
import { Dots } from "../LoadingAnimations/LoadingAnimations";
import instance from "../../Interceptor/api_instance";
import { getDuration } from "../../Interceptor/csrftoken";
import { useTranslation } from "react-i18next";

const Truck34Hour = () => {
  const { t } = useTranslation();
  const [trucks, setTrucks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const calculateHoursDifference = (timestampString) => {
    const timestamp = new Date(timestampString);
    const currentTime = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentTime - timestamp;

    // Convert milliseconds to hours
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    return differenceInHours;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get("assets/view-trucks");
        if (response.status === 200) {
          setTrucks(response.data);
        }
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="graphics-card-but-small">
      <h4 className="global-h4 gray">
        {t("graphics-cont.recent-activity.trailer-34hr")}
      </h4>
      {error && <p className="error">{error}</p>}
      <div className="recent-activity">
        {isLoading ? (
          <Dots />
        ) : trucks !== "" ? (
          trucks
            .filter(
              (item) => calculateHoursDifference(item.truck.check_in_dt) >= 34
            )
            .map((item, index) => (
              <div key={index} className="activity-item">
                <span className="global-h5 gray">
                  {item.truck.carrier_name}-{item.truck.truck_number}
                </span>
                <span style={{ color: "#FB6060" }}>
                  {getDuration(item.truck.check_in_dt)}
                </span>
              </div>
            ))
        ) : null}
      </div>
    </div>
  );
};

export default Truck34Hour;
