import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import JSZip from "jszip";

export const generateQRCode = async (entity, type) => {
  try {
    const qrImage = await QRCode.toDataURL(
      `${process.env.REACT_APP_API_URL}assets/${type}/${entity.id}`
    );
    return qrImage; // Store Base64 image in state
  } catch (error) {
    console.error("Error generating QR code:", error);
  }
};

export const generateQRCodePDF = async (qrCode, entity) => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([400, 300]); // Set page size
  const { width, height } = page.getSize();

  // Embed the QR code as an image
  const qrImage = await fetch(qrCode).then((res) => res.arrayBuffer());
  const qrImageEmbed = await pdfDoc.embedPng(qrImage);
  const qrDims = qrImageEmbed.scale(0.5); // Adjust size

  // Draw QR Code on PDF
  page.drawImage(qrImageEmbed, {
    x: (width - qrDims.width) / 2,
    y: height - qrDims.height - 50,
    width: qrDims.width,
    height: qrDims.height,
  });

  // Get text width for dynamic centering
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

  // Measure text width dynamically
  const textSize = 20;
  const textWidth = font.widthOfTextAtSize(entity.unit, textSize);

  // Draw entity name centered
  page.drawText(entity.unit, {
    x: (width - textWidth) / 2, // Centered text
    y: 130,
    size: textSize,
    font: font, // Use embedded font
    color: rgb(0, 0, 0),
  });

  // Save the PDF and trigger download
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const generateQRCodeZip = async (entities, type) => {
  try {
    if (entities.length === 0)
      throw new Error("Selecciona minimo una unidad para imprimir.");
    const zip = new JSZip();

    for (const [index, entity] of entities.entries()) {
      const qrCode = await generateQRCode(entity, type);
      const pdfBytes = await generateQRCodePDF(qrCode, entity);
      zip.file(`${entity.unit}_QR.pdf`, pdfBytes);
    }
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);

    const entity_type = type === "truck-asset" ? "Camiones" : "Cajas";
    link.download = `CodigosQR_${entity_type}`; // Set ZIP file name
    link.click();
  } catch (e) {
    window.alert(e);
  }
};

export const AssetQrCode = ({ entity, type, close }) => {
  const [qrCode, setQRCode] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchQRCode = async () => {
      const qrImage = await generateQRCode(entity, type);
      setQRCode(qrImage);
    };
    fetchQRCode();
  }, []);

  return (
    <div className="page-mask page-mask-animation">
      <div className="edit-modal">
        {qrCode && <img src={qrCode} alt="QR Code" />}
        <label>{entity.unit || `${entity.company}-${entity.name}`}</label>
        <div className="full-cont">
          <button
            className="change-pwd-btn"
            onClick={() => generateQRCodeZip([entity], type)}
          >
            {t("buttons.print")}
          </button>
          <button className="cancel-btn" onClick={close}>
            {t("buttons.close")}
          </button>
        </div>
      </div>
    </div>
  );
};
